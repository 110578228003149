import axios from 'axios'

const apiConfig = {
  baseURL: process.env.API_BASE,
}

export const request = axios.create(apiConfig)

export type Parameter = string | number

const { head, get, post, put, patch, delete: del } = request

export { head, get, post, put, patch, del }
