import { ratio, round } from 'src/client/util'
import { findShortestPath } from './'
import { GalleryItem } from './types'

// compute sizes by creating a graph with rows as edges and photo to break on as
// nodes to calculate the single best layout using Dijkstra's findShortestPath

// get the height for a set of photos in a potential row
const getCommonHeight = <T = any>(
  row: Array<GalleryItem<T>>,
  containerWidth: number,
  margin: number,
  maxHeight?: number
) => {
  const rowWidth = containerWidth - (row.length - 1) * margin + 1
  const totalAspectRatio = row.reduce((acc, photo) => acc + ratio(photo), 0)
  const height = rowWidth / totalAspectRatio
  return maxHeight && height > maxHeight ? maxHeight : height
}

// calculate the cost of breaking at this node (edge weight)
const cost = <T = any>(
  photos: Array<GalleryItem<T>>,
  i: number,
  j: number,
  width: number,
  targetHeight: number,
  margin: number,
  maxHeight?: number
) => {
  const row = photos.slice(i, j)
  const commonHeight = getCommonHeight(row, width, margin, maxHeight)
  return Math.pow(Math.abs(commonHeight - targetHeight), 2)
}

// return function that gets the neighboring nodes of node and returns costs
const makeGetNeighbors = <T = any>(
  targetHeight: number,
  containerWidth: number,
  photos: Array<GalleryItem<T>>,
  limitNodeSearch: number,
  margin: number,
  maxHeight?: number
) => (startStr: string) => {
  const results: { [t: string]: number } = {}
  const start = +startStr // convert to number
  results[start] = 0
  for (let i = start + 1; i < photos.length + 1; ++i) {
    if (i - start > limitNodeSearch) break
    results[i.toString()] = cost(photos, start, i, containerWidth, targetHeight, margin, maxHeight)
  }
  return results
}

export const computeRowLayout = <T = any>({
  containerWidth,
  limitNodeSearch,
  targetRowHeight,
  margin,
  offset = 0,
  maxHeight,
  photos,
}: {
  containerWidth: number
  limitNodeSearch: number
  targetRowHeight: number
  margin: number
  offset?: number
  maxHeight?: number
  photos: Array<GalleryItem<T>>
}) => {
  // const t = +new Date();
  const getNeighbors = makeGetNeighbors(
    targetRowHeight,
    containerWidth,
    photos,
    limitNodeSearch,
    margin,
    maxHeight
  )
  const strPath = findShortestPath(getNeighbors, '0', photos.length)
  // Convert to numbers
  const path = strPath.map((node) => +node)
  // console.log(`time to find the shortest path: ${(+new Date() - t)} ms`);
  let translateY = offset
  for (let i = 1; i < path.length; ++i) {
    const row = photos.slice(path[i - 1], path[i])
    const height = getCommonHeight(row, containerWidth, margin, maxHeight)
    let translateX = 0
    for (let j = path[i - 1]; j < path[i]; ++j) {
      const width = round(height * ratio(photos[j]), 1)
      photos[j].width = width
      photos[j].height = height
      photos[j].left = translateX
      photos[j].top = translateY
      translateX += width + margin
    }
    translateY += height + margin
  }

  return { photos, containerHeight: round(translateY - margin) }
}
