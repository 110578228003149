import { format, parseISO } from 'date-fns'
import { useEffect, useState } from 'react'
import {
  CalendarEvent,
  ImageAlt,
  Map,
  Pencil,
  PhotoAlbum,
} from 'src/client/assets/images'
import { useAuth } from 'src/client/components/hooks/useAuth'
import { Link } from 'src/client/components/primitives'
import { albumUrl } from 'src/client/routes'
import { staticMapUrl } from 'src/client/services/google'
import { formatLocation, formatTime } from 'src/client/util'
import { formatBytes, formatMegapixel } from 'src/client/util/format'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { UpdateMediaDto } from 'src/server/media/dto/update-media.dto'
import { SerializedMedia } from 'src/server/media/media.entity'
import { VariationName } from 'src/server/media/types'
import { mediaStreamUrl } from 'src/server/routes'
import { Box, Flex, Heading, Input, Text } from 'theme-ui'
import { PlaceDialog } from '../PlaceForm'
import { TimeDialog } from '../TimeForm'

interface ItemProps {
  icon?: React.ElementType
  imageUrl?: string
  children: React.ReactElement | React.ReactElement[]
  onClick?: () => void
  editable?: boolean
}

const Item = (props: ItemProps) => {
  const { icon: Icon, imageUrl, children, editable = false, onClick } = props
  const imgDim = 24
  const iconProps = {
    onClick: onClick,
    sx: {
      fill: 'secondary',
      cursor: editable ? 'pointer' : 'normal',
      position: 'absolute',
      right: 2,
      width: 6,
      height: 6,
      opacity: 0.5,
    },
  }
  const sx = {
    cursor: editable ? 'pointer' : 'normal',
    minHeight: 16,
    p: 3,
    alignItems: 'center',
  }
  const sxHover = {
    '&:hover': {
      bg: 'gray.1',
    },
  }
  return (
    <Flex sx={{ ...sx, ...(editable ? sxHover : {}) }}>
      <Box pr={3}>
        {Icon && <Icon sx={{ fill: 'secondary' }} />}
        {imageUrl && <img width={imgDim} height={imgDim} src={imageUrl} />}
      </Box>
      <Box sx={{ wordBreak: 'break-word' }}>{children}</Box>
      {editable && <Pencil {...iconProps} />}
    </Flex>
  )
}

interface MediaInfoProps {
  sidebarOpen: boolean
  album?: SerializedAlbum
  media: SerializedMedia | SerializedMedia | null
  onUpdate: (data: UpdateMediaDto) => Promise<SerializedMedia>
}

export const MediaInfo = ({ album, media, sidebarOpen, onUpdate }: MediaInfoProps) => {
  const [editDate, setEditDate] = useState(false)
  const [editLocation, setEditLocation] = useState(false)
  const [description, setDescription] = useState('')
  const loc = formatLocation(media)
  const date = media?.takenAt ? parseISO(media.takenAt) : new Date()
  const { canEdit } = useAuth()
  const onSubmit = async (data: UpdateMediaDto) => {
    const updated = await onUpdate(data)
    setEditDate(false)
    setEditLocation(false)
    return updated
  }
  const editable = canEdit(album)
  const saveDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate({ description: e.target.value })
  }
  const openEditDate = () => setEditDate(true)
  const closeEditDate = () => setEditDate(false)
  const openEditLocation = () => setEditLocation(true)
  const onDescChange = (e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)

  useEffect(() => setDescription(media?.description || ''), [media?.id, media?.description])

  return (
    <Box>
      <Box px={3} mb={4}>
        {editable ? (
          <Input
            type="text"
            placeholder="Add a description"
            value={description}
            onChange={onDescChange}
            onBlur={saveDescription}
          />
        ) : (
          <Text>{media?.description}</Text>
        )}
      </Box>
      <Box>
        {(media?.albums?.length || 0) > 0 && (
          <Box>
            <Heading as="h5" px={3}>
              Albums
            </Heading>
            {media?.albums?.map((a) => {
              const props = a.cover
                ? {
                    imageUrl: mediaStreamUrl({ id: a.cover.id, variation: VariationName.Album }),
                  }
                : { icon: PhotoAlbum }
              return (
                <Item key={a.id} {...props}>
                  <Box>
                    <Link to={albumUrl({ id: a.id })}>{a.name}</Link>
                  </Box>
                </Item>
              )
            })}
          </Box>
        )}
        <Heading as="h5" px={3}>
          Details
        </Heading>
        <Item icon={CalendarEvent} editable={editable} onClick={openEditDate}>
          <Box>{format(date, 'LLL d, yyyy')}</Box>
          <Box>{formatTime(date, media?.takenAtOffset?.offset)}</Box>
        </Item>
        <Item icon={ImageAlt} editable={false}>
          <Box>{media?.filename}</Box>
          <Flex sx={{ justifyContent: 'space-between' }}>
            <Box mr={3}>{formatMegapixel(media?.width, media?.height)}</Box>
            <Box mr={3}>{`${media?.width} × ${media?.height}`}</Box>
            <Box>{formatBytes(media?.bytes)}</Box>
          </Flex>
        </Item>
        <Item icon={Map} editable={editable} onClick={openEditLocation}>
          <Box>{loc ? loc.label : editable ? 'Add Location' : 'Location Unknown'}</Box>
        </Item>
        {sidebarOpen && loc?.lat && loc?.lng && <img src={staticMapUrl(loc.lat, loc.lng)} />}
        <PlaceDialog
          media={media}
          isOpen={editLocation}
          onSubmit={onSubmit}
          onClose={closeEditDate}
        />
        <TimeDialog
          takenAt={date}
          takenAtOffset={media?.takenAtOffset?.id}
          isOpen={editDate}
          onSubmit={onSubmit}
          onClose={closeEditDate}
        />
      </Box>
    </Box>
  )
}
