import pick from 'lodash-es/pick'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import * as api from 'src/client/api'
import { get } from 'src/client/api/api'
import { Carousel } from 'src/client/components/blocks/carousel'
import { GalleryItem } from 'src/client/components/blocks/gallery'
import { isTouch } from 'src/client/util'
import { isInteracting } from 'src/client/util/info'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { UpdateMediaDto } from 'src/server/media/dto/update-media.dto'
import { SerializedMedia } from 'src/server/media/media.entity'
import { VariationName } from 'src/server/media/types'
import { Message } from 'src/server/message/message.entity'
import { ReactionType } from 'src/server/message/types'
import { mediaStreamUrl } from 'src/server/routes'
import { Footer, Header, Sidebar, sidebarWidth } from './internal/MediaDetail'
import { isMediaDetailUrl, shareKey, urlFor } from './internal/util'
import { MediaContext } from './MediaContext'

type RouteProps = RouteComponentProps<{ id?: string; shareKey?: string; mediaId?: string }>

interface Props extends RouteProps {
  /** Current album being viewed */
  album: SerializedAlbum | null
  /** Show the component; triggers the enter or exit states - provided by ModalGateway */
  in?: boolean
  /** Enable/disable the ability to "fullscreen" the dialog */
  allowFullScreen?: boolean
  /** Enable/disable calling `onClose` when the backdrop is clicked */
  closeOnBackdropClick?: boolean
  /** Enable/disable calling `onClose` when the `esc` key is pressed */
  closeOnEsc?: boolean
  /** Prevent scroll */
  preventScroll?: boolean
  /** The items to render in the carousel */
  views: Array<GalleryItem<SerializedMedia>>
  /** Take control of the component's view index state */
  startIndex: number
  /** Function called to request close of the modal */
  onClose?: () => void
}

interface State {
  activityOpen: boolean
  index: number
  isFullscreen: boolean
  loading: boolean
  mediaDetail: SerializedMedia | null
  media: SerializedMedia | SerializedMedia | null
  messages: Message[]
  reaction: Message | null
  sidebarOpen: boolean
}

export class MediaDetailComponent extends React.Component<Props, State> {
  static contextType = MediaContext
  static defaultProps = {
    allowFullScreen: !isTouch(),
    closeOnBackdropClick: true,
    closeOnEsc: true,
    preventScroll: true,
  }

  declare context: React.ContextType<typeof MediaContext>

  state: State = {
    activityOpen: false,
    index: -1,
    isFullscreen: false,
    loading: false,
    mediaDetail: null,
    media: null,
    messages: [],
    reaction: null,
    sidebarOpen: false,
  }

  static getDerivedStateFromProps(props: Props, state: State) {
    if (state.index === -1) return { ...state, index: props.startIndex }
    return null
  }

  modalDidMount = () => document.addEventListener('keyup', this.onKeyUp)

  modalWillUnmount = () => document.removeEventListener('keyup', this.onKeyUp)

  onFullscreenChange = (isFullscreen: boolean) => this.setState({ isFullscreen })

  onKeyUp = (event: KeyboardEvent) => {
    const { allowFullScreen, closeOnEsc } = this.props
    const { isFullscreen } = this.state

    // user is working with an input, don't do anything
    if (isInteracting()) return

    // toggle fullscreen
    if (allowFullScreen && event.key === 'f') this.toggleFullscreen()

    // download
    if (event.key === 'D') this.onDownload()

    // close on escape when not fullscreen
    if (event.key === 'Escape' && closeOnEsc && !isFullscreen) this.onClose()
  }

  toggleFullscreen = () => this.setState({ isFullscreen: !this.state.isFullscreen })

  toggleSidebar = () => {
    const { activityOpen } = this.state
    const sidebarOpen = activityOpen ? true : !this.state.sidebarOpen
    this.setState({ sidebarOpen, activityOpen: false })
  }

  toggleActivity = () => {
    const { activityOpen } = this.state
    const sidebarOpen = !activityOpen ? true : !this.state.sidebarOpen
    this.setState({ sidebarOpen, activityOpen: true })
  }

  loadDetail = async () => {
    const {
      match: {
        params: { id },
      },
    } = this.props
    const { media } = this.state
    let reaction: Message | null = null

    if (!media) return

    this.setState({ loading: true })

    const { data: detail } = await api.media.detail({ id: media.id, albumId: id, key: shareKey() })
    if (id) {
      const { data } = await api.messages.reaction({ mediaId: media.id, albumId: id })
      reaction = data
    }

    this.setState({
      mediaDetail: { ...media, ...detail },
      reaction: reaction ?? null,
      loading: false,
    })
  }

  onViewChange = (media: SerializedMedia, index: number) => {
    this.setState({ media, index }, () => {
      this.loadDetail()
    })
    if (media && isMediaDetailUrl(this.props.match.url)) {
      // console.log('onViewChange', urlFor(this.props.match, { id: media.id }))
      // using react-router history will cause micro stutters
      window.history.replaceState({}, media.filename, urlFor(this.props.match, { id: media.id }))
      // this.props.history.replace(urlFor(this.props.match, { id: media.id }))
    }
  }

  onClose = () => {
    console.log('on close')
    // force exit fullscreen mode on close
    if (this.state.isFullscreen) this.toggleFullscreen()
    this.props.onClose?.()
  }

  onDownload = async () => {
    const { media } = this.state
    if (!media) return
    const key = shareKey()
    try {
      // https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
      const { data } = await get(
        mediaStreamUrl(
          { id: media.id, variation: VariationName.Original },
          key ? { key: key } : undefined
        ),
        {
          responseType: 'blob',
        }
      )
      const url = URL.createObjectURL(data)
      const link = document.createElement('a')
      link.href = url
      link.download = media.filename
      link.click()
      link.remove()
      URL.revokeObjectURL(url)
    } catch (e) {
      console.log('unable to download', e.message)
    }
    console.log('download', media)
  }

  onUpdate = async (data: UpdateMediaDto) => {
    const { media, mediaDetail } = this.state
    if (!media) return
    const updatedMedia = await this.context?.updateMedia(media, data)
    // TODO Shouldn't need to splat this into the state
    const updateProps: any = pick(updatedMedia, [
      'description',
      'takenAt',
      'takenAtOffset',
      'location',
      'place',
      'placeId',
    ])
    this.setState({ media: updatedMedia ?? null, mediaDetail: { ...mediaDetail, ...updateProps } })
  }

  loadMessages = async (albumId: string, mediaId: string) => {
    this.setState({ loading: true })
    const key = shareKey()
    const { data: messages } = await api.messages.list({ album: albumId, media: mediaId, key })
    this.setState({ messages, loading: false })
  }

  createMessage = async (albumId: string, mediaId: string, content: string) => {
    const { data: message } = await api.messages.create({ album: albumId, media: mediaId, content })
    const { messages } = this.state
    this.setState({ messages: [...messages, message] })
  }

  destroyMessage = async (messageId: number, messageIdx: number) => {
    const { messages, reaction } = this.state
    await api.messages.destroy({ id: messageId })
    const dupe = messages.slice()
    dupe.splice(messageIdx, 1)
    if (reaction && messageId === reaction.id) this.setState({ reaction: null })
    this.setState({ messages: dupe })
  }

  // Likes are just messages with reactions
  // TODO This can probably reuse the create/destroy message functionality
  toggleLike = async () => {
    const { album } = this.props
    const { media, reaction, messages } = this.state
    if (reaction) {
      await api.messages.destroy({ id: reaction.id })
      const removed = messages.slice()
      const idx = messages.findIndex((m) => m.id === reaction?.id)
      if (idx > -1) removed.splice(idx, 1)
      this.setState({ reaction: null, messages: removed })
    } else {
      const { data } = await api.messages.create({
        media: media!.id,
        reaction: ReactionType.Like,
        album: album!.id,
        content: '',
      })
      this.setState({ reaction: data, messages: [...messages, data] })
    }
  }

  renderSidebar = (props: any) => {
    const { album } = this.props
    const { sidebarOpen, loading, activityOpen, mediaDetail, messages } = this.state

    return (
      <Sidebar
        {...props}
        album={album}
        loading={loading}
        sidebarOpen={sidebarOpen}
        activityOpen={activityOpen}
        media={mediaDetail}
        messages={messages}
        updateMedia={this.onUpdate}
        createMessage={this.createMessage}
        loadMessages={this.loadMessages}
        destroyMessage={this.destroyMessage}
      />
    )
  }

  renderHeader = ({ interactionIsIdle }: { interactionIsIdle: boolean }) => {
    const { album, allowFullScreen } = this.props
    const { isFullscreen, reaction, media } = this.state
    const isLiked = !!reaction

    return (
      <Header
        media={media!}
        album={album}
        interactionIsIdle={interactionIsIdle}
        allowFullscreen={!!allowFullScreen}
        isFullscreen={isFullscreen}
        isLiked={!!isLiked}
        onClose={this.onClose}
        onDownload={this.onDownload}
        toggleActivity={this.toggleActivity}
        toggleLike={this.toggleLike}
        toggleSidebar={this.toggleSidebar}
        toggleFullscreen={this.toggleFullscreen}
      />
    )
  }

  renderFooter = ({ interactionIsIdle }: { interactionIsIdle: boolean }) => {
    const { views } = this.props
    const { index } = this.state
    return (
      <Footer total={views.length} currentIndex={index} interactionIsIdle={interactionIsIdle} />
    )
  }

  render() {
    const { preventScroll, in: transitionIn, views, startIndex, closeOnBackdropClick } = this.props
    const { isFullscreen, sidebarOpen } = this.state
    const mappedViews = views.map((v) => v.media)

    return (
      <Carousel
        startIndex={startIndex}
        views={mappedViews}
        currentView={this.onViewChange}
        onMount={this.modalDidMount}
        onUnMount={this.modalWillUnmount}
        onClose={this.onClose}
        closeOnBackdropClick={closeOnBackdropClick}
        in={transitionIn}
        onFullscreenChange={this.onFullscreenChange}
        sidebar={this.renderSidebar}
        header={this.renderHeader}
        footer={this.renderFooter}
        sidebarOpen={sidebarOpen}
        sidebarWidth={sidebarWidth}
        isFullscreen={isFullscreen}
        preventScroll={!!preventScroll}
      />
    )
  }
}

export const MediaDetail = withRouter(MediaDetailComponent)

export type MediaDetailType = typeof MediaDetail
