export const album = {
  position: 'relative',
  backgroundSize: 'cover',
  backgroundColor: 'transparent',
  borderRadius: '8px',
  border: '1px solid #dadce0',
  transition: 'all 0.135s cubic-bezier(0, 0, 0.2, 1)',
  height: '200px',
  width: '200px',
  cursor: 'pointer',
  '> div': {
    bottom: '0',
    left: '0',
    right: '0',
    top: '0',
    display: 'flex',
    position: 'absolute',
    boxAlign: 'center',
    alignItems: 'center',
    boxPack: 'center',
    justifyContent: 'center',
  },
}

export const variants = {
  album,
}
