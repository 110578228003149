import { Form, Formik, FormikHelpers } from 'formik'
import pick from 'lodash-es/pick'
import { useToasts } from 'react-toast-notifications'
import * as api from 'src/client/api'
import { Dialog, DialogProps } from 'src/client/components/blocks/Dialog'
import { Error, Field } from 'src/client/components/primitives'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { Box, Button, Text } from 'theme-ui'

const initialValues = {
  name: '',
}

type FormValues = typeof initialValues

interface AlbumFormProps {
  album?: SerializedAlbum | null
  onSuccess?: (album: SerializedAlbum) => void
}

export const AlbumForm = ({ album, onSuccess }: AlbumFormProps) => {
  const { addToast } = useToasts()
  const onSubmit = async ({ name }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    setSubmitting(true)
    const { data } = await (album
      ? api.albums.update({ id: album.id }, { name })
      : api.albums.create({ name }))
    addToast(`Album ${album ? 'updated' : 'created'}`, { appearance: 'success' })
    setSubmitting(false)
    onSuccess?.(data)
  }
  const init = { ...initialValues, ...(album ? pick(album, Object.keys(initialValues)) : {}) }
  return (
    <Formik initialValues={init} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Box sx={{ border: '1px solid', borderColor: 'muted', borderRadius: 4, p: 4 }}>
          <Form>
            <Field mb={3} type="text" name="name" placeholder="Name" />
            <Error name="name" />
            <Button type="submit" disabled={isSubmitting}>
              {album ? 'Update' : 'Create'}
            </Button>
          </Form>
        </Box>
      )}
    </Formik>
  )
}

type AlbumDialogProps = Omit<DialogProps, 'children'> & AlbumFormProps

export const AlbumDialog = ({ isOpen, onClose, ...rest }: AlbumDialogProps) => (
  <Dialog isOpen={isOpen} onClose={onClose} title={`${rest.album ? 'Update' : 'Create'} Album`}>
    <AlbumForm {...rest} />
  </Dialog>
)

type DeleteAlbumDialogProps = Omit<DialogProps, 'children'> & {
  album: SerializedAlbum
  onSuccess?: () => void
}

export const DeleteAlbumDialog = ({
  isOpen,
  album,
  onClose,
  onSuccess,
}: DeleteAlbumDialogProps) => {
  const { addToast } = useToasts()
  const onSubmit = async (_: {}, { setSubmitting }: FormikHelpers<FormValues>) => {
    setSubmitting(true)
    await api.albums.delete({ id: album.id })
    setSubmitting(false)
    addToast('Album deleted', { appearance: 'info' })
    onSuccess?.()
  }
  return (
    <Dialog isOpen={isOpen} onClose={onClose} title={'Delete Album?'}>
      <Formik initialValues={{}} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Box sx={{ border: '1px solid', borderColor: 'muted', borderRadius: 4, p: 4 }}>
            <Form>
              <Text mb={4}>
                Deleting an album is permanent. Photos and videos that were in a deleted album will
                remain.
              </Text>
              <Button type="submit" disabled={isSubmitting}>
                Delete
              </Button>
            </Form>
          </Box>
        )}
      </Formik>
    </Dialog>
  )
}
