/*
Copyright 2007-2013 Marijn Haverbeke from "Eloquent Javascript, 1st Edition"

Permission is hereby granted, free of charge, to any person obtaining a copy
of software and associated documentation files (the "Software"), to deal
in the Software without restriction, including without limitation the rights
to use, copy, modify, merge, publish, distribute, sublicense, and/or sell
copies of the Software, and to permit persons to whom the Software is
furnished to do so, subject to the following conditions:

The above copyright notice and permission notice shall be included in
all copies or substantial portions of the Software.

THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR
IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY,
FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE
AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER
LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM,
OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE
SOFTWARE.
*/

export interface BinaryHeap<T> {
  push: (e: T) => void
  pop: () => T
  remove: (node: T) => void
  size: () => number
  bubbleUp: (n: number) => void
  sinkDown: (n: number) => void
}

export function BinaryHeap<T>(scoreFunction: (element: T) => number) {
  const content: T[] = []

  function push(element: T) {
    // Add the new element to the end of the array.
    content.push(element)
    // Allow it to bubble up.
    bubbleUp(content.length - 1)
  }

  function pop() {
    // Store the first element so we can return it later.
    const result = content[0]
    // Get the element at the end of the array.
    const end = content.pop()
    // If there are any elements left, put the end element at the
    // start, and let it sink down.
    if (content.length > 0 && end) {
      content[0] = end
      sinkDown(0)
    }
    return result
  }

  function remove(node: T) {
    const length = content.length
    // To remove a value, we must search through the array to find it.
    for (let i = 0; i < length; i++) {
      if (content[i] !== node) continue
      // When it is found, the process seen in 'pop' is repeated to fill up the
      // hole.
      const end = content.pop()
      // If the element we popped was the one we needed to remove, we're done.
      if (i === length - 1) break
      // Otherwise, we replace the removed element with the popped
      // one, and allow it to float up or sink down as appropriate.
      if (end) content[i] = end
      bubbleUp(i)
      sinkDown(i)
      break
    }
  }

  const size = () => content.length

  function bubbleUp(n: number) {
    // Fetch the element that has to be moved.
    const element = content[n]
    const score = scoreFunction(element)
    // When at 0, an element can not go up any further.
    while (n > 0) {
      // Compute the parent element's index, and fetch it.
      const parentN = Math.floor((n + 1) / 2) - 1
      const parent = content[parentN]
      // If the parent has a lesser score, things are in order and we are done.
      if (score >= scoreFunction(parent)) break
      // Otherwise, swap the parent with the current element and continue.
      content[parentN] = element
      content[n] = parent
      n = parentN
    }
  }

  function sinkDown(n: number) {
    // Look up the target element and its score.
    const length = content.length
    const element = content[n]
    const elemScore = scoreFunction(element)

    while (true) {
      // Compute the indices of the child elements.
      const child2N = (n + 1) * 2
      const child1N = child2N - 1
      let child1Score = Number.MIN_SAFE_INTEGER
      // This is used to store the new position of the element, if any.
      let swap = null
      // If the first child exists (is inside the array)...
      if (child1N < length) {
        // Look it up and compute its score.
        const child1 = content[child1N]
        child1Score = scoreFunction(child1)
        // If the score is less than our element's, we need to swap.
        if (child1Score < elemScore) swap = child1N
      }
      // Do the same checks for the other child.
      if (child2N < length) {
        const child2 = content[child2N]
        const child2Score = scoreFunction(child2)
        if (child2Score < (swap == null ? elemScore : child1Score)) swap = child2N
      }

      // No need to swap further, we are done.
      if (swap == null) break

      // Otherwise, swap and continue.
      content[n] = content[swap]
      content[swap] = element
      n = swap
    }
  }

  const self: BinaryHeap<T> = {
    push,
    pop,
    remove,
    size,
    bubbleUp,
    sinkDown,
  }
  return Object.freeze(self)
}
