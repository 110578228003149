import { SetStateAction, useCallback, useMemo, useState } from 'react'

// https://github.com/kitze/react-hanger/blob/master/src/array/useArray.ts

export interface UseStateful<T> {
  value: T
  setValue: React.Dispatch<SetStateAction<T>>
}

export type UseArrayActions<T> = {
  setValue: UseStateful<T[]>['setValue']
  add: (value: T | T[], prepend?: boolean) => void
  push: (value: T | T[]) => void
  pop: () => void
  shift: () => void
  unshift: (value: T | T[]) => void
  clear: () => void
  move: (from: number, to: number) => void
  removeById: (id: valueof<T>) => void
  modifyById: (id: valueof<T>, newValue: Partial<T>) => void
  removeIndex: (index: number) => void
}
export type UseArray<T> = [T[], UseArrayActions<T>]

type valueof<T> = T[keyof T]

export function useArray<T>(initial: T[], idKey: keyof T): UseArray<T> {
  const [value, setValue] = useState(initial)
  const add = useCallback((a, prepend) => {
    const toAdd = Array.isArray(a) ? a : [a]
    setValue((arr) => {
      const copy = arr.slice()
      toAdd.forEach((a) => {
        const idx = arr.findIndex((v) => v[idKey] == a[idKey])
        if (idx > -1) {
          copy[idx] = { ...copy[idx], ...a }
        } else {
          copy[prepend ? 'unshift' : 'push'](a)
        }
      })
      return copy
    })
  }, [])
  const push = useCallback((a) => {
    setValue((arr) => [...arr, ...(Array.isArray(a) ? a : [a])])
  }, [])
  const unshift = useCallback(
    (a) => setValue((arr) => [...(Array.isArray(a) ? a : [a]), ...arr]),
    []
  )
  const pop = useCallback(() => setValue((arr) => arr.slice(0, -1)), [])
  const shift = useCallback(() => setValue((arr) => arr.slice(1)), [])
  const move = useCallback(
    (from: number, to: number) =>
      setValue((it) => {
        const copy = it.slice()
        copy.splice(to < 0 ? copy.length + to : to, 0, copy.splice(from, 1)[0])
        return copy
      }),
    []
  )
  const clear = useCallback(() => setValue(() => []), [])
  const removeById = useCallback(
    (id) => setValue((arr) => arr.filter((v) => v && v[idKey] !== id)),
    []
  )
  const removeIndex = useCallback(
    (index) =>
      setValue((v) => {
        const copy = v.slice()
        copy.splice(index, 1)
        return copy
      }),
    []
  )
  const modifyById = useCallback(
    (id, newValue) =>
      setValue((arr) => arr.map((v) => (v[idKey] === id ? { ...v, ...newValue } : v))),
    []
  )
  const actions = useMemo(
    () => ({
      setValue,
      add,
      unshift,
      push,
      move,
      clear,
      removeById,
      removeIndex,
      pop,
      shift,
      modifyById,
    }),
    [modifyById, push, unshift, move, clear, removeById, removeIndex, pop, shift]
  )
  return [value, actions]
}
