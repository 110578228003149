import { Form, Formik } from 'formik'
import { useToasts } from 'react-toast-notifications'
import * as api from 'src/client/api'
import { useAuth } from 'src/client/components/hooks/useAuth'
import { Error, Field } from 'src/client/components/primitives'
import { accountSchema } from 'src/client/components/primitives/validators'
import { Button } from 'theme-ui'

const initialValues = {
  name: '',
  password: '',
  passwordConfirmation: '',
}

type FormValues = typeof initialValues

export const AccountForm = () => {
  const { user, update } = useAuth()
  const { addToast } = useToasts()
  const onSubmit = async (params: FormValues) => {
    const { data } = await api.users.me(params)
    update(data)
    addToast('User updated', { appearance: 'success' })
  }

  return (
    <Formik
      initialValues={{ ...initialValues, name: user?.name }}
      validationSchema={accountSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form noValidate>
          <Field mb={3} type="name" name="name" placeholder={user?.name || 'Username'} />
          <Error name="name" />
          <Field mb={3} type="password" name="password" placeholder="Password" />
          <Error name="password" />
          <Field
            mb={3}
            type="password"
            name="passwordConfirmation"
            placeholder="Password (Confirmation)"
          />
          <Error name="passwordConfirmation" />
          <Button type="submit" disabled={isSubmitting}>
            Update
          </Button>
        </Form>
      )}
    </Formik>
  )
}
