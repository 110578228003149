import { Form, Formik, FormikHelpers } from 'formik'
import pick from 'lodash-es/pick'
import * as api from 'src/client/api'
import { Dialog, DialogProps } from 'src/client/components/blocks/Dialog'
import {
  AddressAutoComplete,
  Error,
  Field,
} from 'src/client/components/primitives'
import {
  ResizableTextarea,
} from 'src/client/components/primitives/ResizableTextarea'
import { normalizeAddress } from 'src/client/services/google'
import { Place } from 'src/server/place/place.entity'
import { Box, Button, Checkbox, Grid, Label } from 'theme-ui'

const initialValues = {
  label: '',
  address1: '',
  city: '',
  state: '',
  zipcode: '',
  country: '',
  description: '',
  latitude: '',
  longitude: '',
  autoAssociate: false,
}

type FormValues = typeof initialValues

interface PlaceFormProps {
  place?: Place | null
  onSuccess?: (p: Place) => void
}

export const PlaceForm = ({ onSuccess, place }: PlaceFormProps) => {
  const handleSubmit = async (values: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
    try {
      const { data } = await (place
        ? api.places.update({ id: place.id }, values)
        : api.places.create(values))
      if (onSuccess) onSuccess(data)
    } catch (e) {
      const {
        response: { data },
      } = e
      setErrors(data)
    }
  }
  const [longitude, latitude] = place?.location?.coordinates ?? []
  const init = {
    ...initialValues,
    latitude: latitude?.toString(),
    longitude: longitude?.toString(),
    ...(place ? pick(place, Object.keys(initialValues)) : {}),
  }

  return (
    <Formik initialValues={init} onSubmit={handleSubmit}>
      {({ values, handleChange, handleBlur, isSubmitting, setFieldValue }) => {
        const onAddressSelected = (result: google.maps.places.PlaceResult, id: string) => {
          const address = normalizeAddress(result, id)
          Object.keys(address).forEach((key) => setFieldValue(key, address[key], false))
        }
        return (
          <Box sx={{ border: '1px solid', borderColor: 'muted', borderRadius: 4, p: 4 }}>
            <Form>
              <Box mb={3}>
                <Field type="text" name="label" placeholder="Label" required />
                <Error name="label" />
              </Box>
              <Box mb={3}>
                <AddressAutoComplete
                  id="address1"
                  name="address1"
                  placeholder="Address Line 1"
                  autoComplete="new-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onSelect={onAddressSelected}
                  value={values.address1}
                />
                <Error name="address1" />
              </Box>
              <Grid gap={3} columns={[1, null, 3]}>
                <Box mb={3}>
                  <Field type="text" name="city" placeholder="City" />
                  <Error name="city" />
                </Box>
                <Box mb={3}>
                  <Field type="text" name="zipcode" placeholder="Zipcode" />
                  <Error name="zipcode" />
                </Box>
                <Box mb={3}>
                  <Field type="text" name="state" placeholder="State" />
                  <Error name="state" />
                </Box>
              </Grid>
              <Box mb={3}>
                <Field type="text" name="country" placeholder="Country" />
                <Error name="country" />
              </Box>
              <Box mb={3}>
                <ResizableTextarea
                  mb={3}
                  rows={6}
                  name="description"
                  placeholder="Description"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.description}
                />
                <Error name="description" />
              </Box>
              <Box mb={3}>
                <Label>
                  <Checkbox
                    value="true"
                    name="autoAssociate"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.autoAssociate}
                  />
                  Automatically Associate Media
                </Label>
              </Box>
              <Grid gap={3} columns={[1, null, 2]}>
                <Box mb={3}>
                  <Field type="text" name="latitude" placeholder="Latitude" />
                  <Error name="latitude" />
                </Box>
                <Box mb={3}>
                  <Field type="text" name="longitude" placeholder="Longitude" />
                  <Error name="longitude" />
                </Box>
              </Grid>
              <Button type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </Form>
          </Box>
        )
      }}
    </Formik>
  )
}

export const PlaceDialog = ({
  isOpen,
  onClose,
  onSuccess,
  place,
  backdropCloses = true,
}: Omit<DialogProps, 'children'> & PlaceFormProps) => (
  <Dialog
    isOpen={isOpen}
    onClose={onClose}
    backdropCloses={backdropCloses}
    title={`${place ? 'Update' : 'Add'} Place`}
  >
    <PlaceForm onSuccess={onSuccess} place={place} />
  </Dialog>
)
