import { SavePlaceDto } from 'src/server/place/dto/save-place.dto'
import { Place } from 'src/server/place/place.entity'
import { Id, placeListUrl, placeUrl } from 'src/server/routes'
import { del, get, post, put } from './api'

export const places = {
  list: async () => await get<Place[]>(placeListUrl()),
  create: async (data: SavePlaceDto) => await post<Place>(placeListUrl(), data),
  update: async (params: Id, data: SavePlaceDto) => await put<Place>(placeUrl(params), data),
  destroy: async (params: Id) => await del(placeUrl(params)),
}
