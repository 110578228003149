import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'
import { easeOutQuint } from './easing'
import { Props } from './props'

const verticalOffset = 40

export const SlideUp = ({
  component: Component,
  onEntered,
  onExited,
  in: inProp,
  innerProps: originalProps,
  ...props
}: Props) => {
  const enter = 300
  const exit = 500
  const restingTransform = 'translate3d(0, 0, 0)'
  const slideStyle = {
    transition: `all ${enter}ms ${easeOutQuint}`,
    transform: restingTransform,
  }
  const slideTransition = {
    entering: {
      opacity: 0,
      transform: `translate3d(0, ${verticalOffset}px, 0) scale(0.9)`,
    },
    entered: {
      opacity: 1,
      transform: restingTransform,
    },
    exiting: {
      opacity: 0,
      transform: `translate3d(0, ${verticalOffset}px, 0) scale(0.9)`,
      transition: `transform ${exit}ms ${easeOutQuint}, opacity ${exit}ms ${easeOutQuint}`,
    },
  }

  return (
    <Transition
      appear
      in={inProp}
      mountOnEnter
      onEntered={onEntered}
      onExited={onExited}
      timeout={{ enter, exit }}
      unmountOnExit
    >
      {(status: TransitionStatus) => {
        if (status === 'exited') return null

        const innerProps = {
          ...originalProps,
          style: {
            ...slideStyle,
            ...slideTransition[status],
          },
        }

        return <Component {...innerProps} {...props} />
      }}
    </Transition>
  )
}
