import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import * as api from 'src/client/api'
import { TimeOffset } from 'src/server/media/time-offset.entity'
import { Box, Grid, Select } from 'theme-ui'
import { ChangeEvent, Cleave } from './Cleave'

interface DateTimeInputProps {
  onChange?(dt: { date?: Date; offset?: number }): void
  initialDate?: Date
  initialOffset?: number
}

export const DateTimeInput = (props: DateTimeInputProps) => {
  const { initialDate = new Date(), initialOffset, onChange } = props
  const [date, setDate] = useState<Date>(initialDate)
  const [offsetId, setOffsetId] = useState<number>(initialOffset)
  const [offsets, setOffsets] = useState<TimeOffset[]>([])

  // TODO This should probably be in the TimeForm and provided to this component
  useEffect(() => {
    const fetchOffsets = async () => {
      try {
        const { data } = await api.settings.offsets()
        setOffsets(data)
        if (!offsetId) {
          const defaultOffset = data.find((o) => o.offset === 0) || data[0]
          if (defaultOffset) setOffsetId(defaultOffset.id)
        }
      } catch (e) {
        console.log(e)
      }
    }
    fetchOffsets()
  }, [])

  useEffect(() => {
    if (onChange) onChange({ date, offset: offsetId })
  }, [date, offsetId])

  const onChangeDate = (event: ChangeEvent<HTMLInputElement>) => {
    if (/(\d{4})-(\d{2})-(\d{2})/.test(event.target.value)) {
      const { $1: year, $2: month, $3: day } = RegExp
      setDate((oldDate) => {
        const newDate = new Date(oldDate)
        newDate.setFullYear(Number(year), Math.max(Number(month) - 1, 0), Number(day))
        return newDate
      })
    }
  }

  const onChangeTime = (event: ChangeEvent<HTMLInputElement>) => {
    if (/(\d{2}):(\d{2})/.test(event.target.value)) {
      const { $1: hours, $2: minutes } = RegExp
      setDate((oldDate) => {
        const newDate = new Date(oldDate)
        newDate.setHours(Number(hours))
        newDate.setMinutes(Number(minutes))
        newDate.setSeconds(0)
        return newDate
      })
    }
  }

  const onChangeOffset = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const id = Number(event.target.value)
    const oldId = Number(offsetId)
    const oldOffset = offsets.find((o) => o.id == oldId)
    const newOffset = offsets.find((o) => o.id == id)
    if (newOffset) {
      const diff = newOffset.offset - oldOffset.offset
      setDate((oldDate) => new Date(oldDate.getTime() + diff))
      setOffsetId(id)
    }
  }

  return (
    <Box>
      <Grid gap={3} columns={[1, null, 2]}>
        <Box mb={3}>
          <Cleave
            placeholder="YYYY-MM-DD"
            value={format(date, 'yyyy-MM-dd')}
            options={{
              date: true,
              delimiter: '-',
              datePattern: ['Y', 'm', 'd'],
            }}
            required
            onChange={onChangeDate}
          />
        </Box>
        <Box mb={3}>
          <Cleave
            placeholder="hh:mm"
            value={format(date, 'HH:mm')}
            options={{
              time: true,
              timePattern: ['h', 'm'],
            }}
            required
            onChange={onChangeTime}
          />
        </Box>
      </Grid>
      <Box mb={3}>
        <Select required onChange={onChangeOffset} value={offsetId}>
          {offsets.map((o) => (
            <option key={o.id} value={o.id}>
              {o.name}
            </option>
          ))}
        </Select>
      </Box>
    </Box>
  )
}
