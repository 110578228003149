import { CancelToken } from 'axios'
import { UpdateMediaDto } from 'src/server/media/dto/update-media.dto'
import { SerializedMedia } from 'src/server/media/media.entity'
import {
  mediaListUrl,
  mediaRestoreUr,
  mediaTrashUrl,
  mediaUrl,
} from 'src/server/routes'
import { del, get, Parameter, patch, post, put } from './api'

export const media = {
  list: async () => await get<SerializedMedia[]>(mediaListUrl()),
  trashed: async () => await get<SerializedMedia[]>(mediaListUrl(), { params: { trashed: true } }),
  detail: async (params: { id: Parameter; albumId?: Parameter; key?: string | null }) => {
    const { key, ...rest } = params
    return await get<SerializedMedia>(mediaUrl(rest, key ? { key } : undefined))
  },
  create: async (
    params: { albumId?: Parameter },
    data: {
      formData: FormData
      cancelToken?: CancelToken
      onProgress?: (e: ProgressEvent) => void
    }
  ) => {
    const { cancelToken, formData, onProgress } = data
    const config = {
      headers: { 'Content-Type': 'multipart/form-data;boundary="boundary"' },
      ...(onProgress ? { onUploadProgress: onProgress } : {}),
      ...(cancelToken ? { cancelToken } : {}),
    }
    // TODO It would be nice to put it into the formData but fastify-multipart API is difficult
    // to  work with
    const album = params?.albumId
    return await post(mediaListUrl({}, album ? { album } : undefined), formData, config)
  },
  update: async (params: { id: Parameter }, data: UpdateMediaDto) =>
    await put<SerializedMedia>(mediaUrl(params), data),
  trash: async (data: { mediaId: string[] }) => await patch(mediaTrashUrl(), data),
  restore: async (data: { mediaId: string[] }) => await patch(mediaRestoreUr(), data),
  delete: async (data: { mediaId: string[] }) => await del(mediaListUrl(), { data }),
}
