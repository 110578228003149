import { compile } from 'path-to-regexp'
import { ParsedQuery, stringify } from 'query-string'
import { VariationName } from 'src/server/media/types'
import { GLOBAL_PREFIX } from 'src/shared/constants'

export interface RouteMaker {
  (route: string): SimpleRoute
  <P>(route: string): Route<P>
}

export interface SimpleRoute {
  (params?: any, queryString?: any): string
  route: string
}

export interface Route<P, Q = any> {
  (params: P, queryString?: Q): string
  route: string
}

export interface Id {
  id: string | number
}

export interface QueryParams {
  [key: string]: string | number
}

const escapedSlash = /%2F/g
export const r: RouteMaker = (route: string) => {
  const routeToPath = compile(route)
  return Object.assign(
    (params?: ParsedQuery, queryString?: ParsedQuery) =>
      routeToPath(params).replace(escapedSlash, '/') +
      (queryString && Object.keys(queryString).length ? `?${stringify(queryString)}` : ''),
    {
      route,
    }
  )
}

export const apiRoot = `/${GLOBAL_PREFIX}`
export const authUrl = r(`${apiRoot}/auth`)
export const signupUrl = r(`${authUrl.route}/signup`)
export const loginUrl = r(`${authUrl.route}/login`)
export const logoutUrl = r(`${authUrl.route}/logout`)
export const resetPasswordUrl = r(`${authUrl.route}/reset-password`)
export const forgotPasswordUrl = r(`${authUrl.route}/forgot-password`)
export const heartbeatUrl = r(`${authUrl.route}/heartbeat`)
export const eventsUrl = r(`${apiRoot}/events`)
export const albumListUrl = r(`${apiRoot}/albums`)
export const albumUrl = r<Id>(`${albumListUrl.route}/:id`)
export const albumByKeyUrl = r<{ key: string }>(`${albumListUrl.route}/share/:key`)
export const albumMediaListUrl = r<Id>(`${albumUrl.route}/media`)
export const messageListUrl = r(`${apiRoot}/messages`)
export const messageUrl = r<Id>(`${messageListUrl.route}/:id`)
export const reactionUrl = r<{ albumId: string; mediaId: string }>(
  `${messageListUrl.route}/:albumId/:mediaId`
)
export const mediaListUrl = r(`${apiRoot}/media`)
export const mediaTrashUrl = r(`${mediaListUrl.route}/trash`)
export const mediaRestoreUr = r(`${mediaListUrl.route}/restore`)
export const mediaUrl = r<Id>(`${mediaListUrl.route}/:id`)
export const mediaStreamUrl = r<Id & { variation: VariationName }>(`${mediaUrl.route}/:variation`)
export const shareListUrl = r(`${apiRoot}/shares`)
export const shareUrl = r<Id>(`${shareListUrl.route}/:id`)
export const userlistUrl = r(`${apiRoot}/users`)
export const userCurrentUrl = r(`${userlistUrl.route}/me`)
export const userUrl = r<Id>(`${userlistUrl.route}/:id`)
export const placeListUrl = r(`${apiRoot}/places`)
export const placeUrl = r<Id>(`${apiRoot}/places/:id`)
export enum SettingPath {
  Offsets = 'offsets',
}
export const settingListUrl = r<{ path: SettingPath }>(`${apiRoot}/setting/:path`)
