import { Form, Formik, FormikHelpers } from 'formik'
import pick from 'lodash-es/pick'
import { useToasts } from 'react-toast-notifications'
import * as api from 'src/client/api'
import { Dialog, DialogProps } from 'src/client/components/blocks/Dialog'
import { Field } from 'src/client/components/primitives'
import { accountSchema } from 'src/client/components/primitives/validators'
import { UserRole } from 'src/server/user/types'
import { User } from 'src/server/user/user.entity'
import { Box, Button } from 'theme-ui'

const initialValues = {
  name: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  role: UserRole.Guest,
}

type FormValues = typeof initialValues

interface UserFormProps {
  user: User | null
  onSuccess?(u: User): void
}

export const UserForm = ({ user, onSuccess }: UserFormProps) => {
  const { addToast } = useToasts()
  const onSubmit = async (params: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
    try {
      if (user) {
        const { data: updated } = await api.users.update({ id: user.id }, params)
        onSuccess?.(updated)
        addToast('User updated', { appearance: 'success' })
      } else {
        const { data: created } = await api.users.create(params)
        onSuccess?.(created)
        addToast('User created', { appearance: 'success' })
      }
    } catch (e) {
      setErrors(e.response.data)
    }
  }

  return (
    <Formik
      initialValues={{ ...initialValues, ...pick(user, Object.keys(initialValues)) }}
      validationSchema={accountSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Box sx={{ border: '1px solid', borderColor: 'muted', borderRadius: 4, p: 4 }}>
          <Form noValidate>
            <Field mb={3} type="email" name="email" placeholder="Email" />
            <Field mb={3} type="name" name="name" placeholder="Username" />
            <Field
              mb={3}
              type="password"
              name="password"
              placeholder="Password"
              autoComplete="new-password"
            />
            <Field
              mb={3}
              type="password"
              name="passwordConfirmation"
              placeholder="Password (Confirmation)"
              autoComplete="new-password"
            />
            <Field as="select" mb={3} name="role">
              {/* TODO Map over UserRole */}
              <option value={UserRole.Admin}>Admin</option>
              <option value={UserRole.User}>User</option>
              <option value={UserRole.Guest}>Guest</option>
              <option value={UserRole.Disabled}>Disabled</option>
            </Field>
            <Button type="submit" disabled={isSubmitting}>
              {user ? 'Update' : 'Create'}
            </Button>
          </Form>
        </Box>
      )}
    </Formik>
  )
}

export const UserDialog = ({
  isOpen,
  onClose,
  onSuccess,
  user,
  backdropCloses = true,
}: Omit<DialogProps, 'children'> & UserFormProps) => (
  <Dialog
    isOpen={isOpen}
    onClose={onClose}
    backdropCloses={backdropCloses}
    title={`${user ? 'Update' : 'Add'} User`}
  >
    <UserForm onSuccess={onSuccess} user={user} />
  </Dialog>
)
