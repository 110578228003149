import { FocusableItem, MenuDivider, MenuItem } from '@szhsin/react-menu'
import throttle from 'lodash-es/throttle'
import React, { useEffect, useState } from 'react'
import { Link, matchPath, useHistory } from 'react-router-dom'
import {
  Camera,
  Cog,
  DotsVerticalRounded,
  Edit,
  ImageAlt,
  Images,
  LogOut,
  PhotoAlbum,
  Plus,
  ShareAlt,
  Trash,
  Undo,
  Unlink,
  Upload,
} from 'src/client/assets/images'
import { useAuth } from 'src/client/components/hooks/useAuth'
import { Link as NavLink, Menu } from 'src/client/components/primitives'
import * as routes from 'src/client/routes'
import { useMediaContext } from 'src/client/scenes'
import { Box, Button, Flex, Heading, Text } from 'theme-ui'

const menuLink = (text: React.ReactNode, to: string, icon?: React.ReactNode) => (
  <FocusableItem>
    {({ ref }) => (
      <Link
        ref={ref}
        to={to}
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
        }}
      >
        {icon && (
          <Box mr={2} sx={{ lineHeight: 1 }}>
            {icon}
          </Box>
        )}
        <Text>{text}</Text>
      </Link>
    )}
  </FocusableItem>
)

interface HeaderProps {
  title?: string
}

export const Header = ({ title }: HeaderProps) => {
  const history = useHistory()
  const {
    authenticated,
    signOut,
    user,
    canSignup,
    canShare,
    canCreate,
    canDelete,
    isAlbumCreator,
    isUser,
  } = useAuth()
  const {
    loading,
    canUpload,
    album,
    selected,
    removeMedia,
    fileDialog,
    authDialog,
    addToAlbumDialog,
    shareDialog,
    editAlbumDialog,
    deleteAlbumDialog,
  } = useMediaContext()
  const hasSelected = selected.size > 0
  const deleteMedia = () => {
    removeMedia(Array.from(selected))
  }
  const restoreMedia = () => {
    removeMedia(Array.from(selected), { fromTrash: true })
  }
  const removeMediaFromAlbum = () => {
    if (album) removeMedia(Array.from(selected), { fromAlbum: album })
  }
  const isTrash = matchPath(history.location.pathname, routes.trashUrl.route)
  const mediaAccess = isUser()
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const throttledScroll = throttle(() => setScrolled(window.pageYOffset > 0), 100)
    window.addEventListener('scroll', throttledScroll, { passive: true })
    return () => window.removeEventListener('scroll', throttledScroll)
  }, [scrolled])

  if (loading) return null

  return (
    <Box
      as="header"
      sx={{
        display: 'flex',
        width: '100%',
        height: '50px',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'fixed',
        top: 0,
        background: '#fff',
        transition: 'box-shadow 300ms ease-in',
        boxShadow: `${scrolled ? '0px 2px 4px rgba(0,0,0,0.3)' : 'none'}`,
        zIndex: 1,
      }}
    >
      <Flex sx={{ flex: 1, alignItems: 'center', justifyContent: 'flex-start' }}>
        {authenticated && (
          <>
            <Menu>
              {mediaAccess &&
                menuLink('Photos', routes.indexUrl, <Camera sx={{ fill: 'secondary' }} />)}
              {menuLink('Albums', routes.albumListUrl(), <Images sx={{ fill: 'secondary' }} />)}
              {mediaAccess &&
                menuLink('Trash', routes.trashUrl(), <Trash sx={{ fill: 'secondary' }} />)}
              {menuLink('Settings', routes.settingsListUrl({}), <Cog sx={{ fill: 'secondary' }} />)}
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  signOut()
                }}
              >
                <Box mr={2}>
                  <LogOut sx={{ fill: 'secondary' }} />
                </Box>
                <Box>Logout {user?.email}</Box>
              </MenuItem>
            </Menu>
            {mediaAccess && (
              <NavLink
                variant="icon-dark"
                sx={{ display: ['none', 'inline-flex'] }}
                to={routes.indexUrl}
                title="Home"
              >
                <ImageAlt />
              </NavLink>
            )}
            <NavLink
              variant="icon-dark"
              sx={{ display: ['none', 'inline-flex'] }}
              to={routes.albumListUrl()}
              title="Albums"
            >
              <PhotoAlbum />
            </NavLink>
          </>
        )}
      </Flex>
      <Flex sx={{ flex: 1, justifyContent: 'center' }}>
        {(title ?? album) && <Heading>{title ?? album?.name}</Heading>}
      </Flex>
      {/* <div>
            <Input placeholder="Search Media" />
          </div> */}
      <Flex sx={{ flex: 1, alignItems: 'center', justifyContent: 'flex-end' }}>
        {canSignup(album) && (
          <Button variant="text" onClick={() => authDialog(true)} mr={3}>
            Create Account
          </Button>
        )}
        {hasSelected && isTrash && (
          <Button variant="icon" onClick={restoreMedia} title="Restore">
            <Undo sx={{ fill: 'secondary' }} />
          </Button>
        )}
        {hasSelected &&
          canDelete(album) &&
          (album ? (
            <Menu icon={<Trash sx={{ fill: 'secondary' }} />}>
              <MenuItem onClick={removeMediaFromAlbum}>
                <Box mr={2}>
                  <Unlink sx={{ fill: 'secondary' }} />
                </Box>
                <Box>Remove from album</Box>
              </MenuItem>
              <MenuItem onClick={deleteMedia}>
                <Box mr={2}>
                  <Trash sx={{ fill: 'secondary' }} />
                </Box>
                <Box>Move to trash</Box>
              </MenuItem>
            </Menu>
          ) : (
            <Button variant="icon" onClick={deleteMedia} title="Trash">
              <Trash sx={{ fill: 'secondary' }} />
            </Button>
          ))}
        {hasSelected && !isTrash && (
          <Button
            variant="icon"
            onClick={() => addToAlbumDialog(true, selected)}
            title="Add to Album"
          >
            <Plus sx={{ fill: 'secondary' }} />
          </Button>
        )}
        {!isTrash && canShare(album) && (
          <Button variant="icon" onClick={() => shareDialog(true)} title="Share">
            <ShareAlt sx={{ fill: 'secondary' }} />
          </Button>
        )}
        {canUpload && canCreate(album) && (
          <Button variant="icon" onClick={() => fileDialog(true)} title="Upload">
            <Upload sx={{ fill: 'secondary' }} />
          </Button>
        )}
        {album && isAlbumCreator(album) && (
          <Menu icon={<DotsVerticalRounded sx={{ fill: 'secondary' }} />}>
            <MenuItem onClick={() => editAlbumDialog(true)}>
              <Box mr={2}>
                <Edit sx={{ fill: 'secondary' }} />
              </Box>
              <Box>Edit album</Box>
            </MenuItem>
            <MenuItem onClick={() => deleteAlbumDialog(true)}>
              <Box mr={2}>
                <Trash sx={{ fill: 'secondary' }} />
              </Box>
              <Box>Delete album</Box>
            </MenuItem>
          </Menu>
        )}
      </Flex>
    </Box>
  )
}
