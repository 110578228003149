import debounce from 'lodash-es/debounce'
import throttle from 'lodash-es/throttle'
import React from 'react'
import ResizeObserver from 'resize-observer-polyfill'
import { round } from 'src/client/util'
import { computeRowLayout, findIdealNodeSearch } from './internal'
import { GalleryRow, ScrollDirection } from './internal/GalleryRow'
import { GalleryItem, GalleryItemWithIndex } from './internal/types'

const { useEffect, useState, useLayoutEffect, useRef } = React

type ImageSet<T> = GalleryItemWithIndex<T> | Array<GalleryItemWithIndex<T>>

interface GalleryProps<T> {
  photos: Array<{ title: string; items: Array<GalleryItem<T>> }>
  selected: Set<string>
  columns?: number | ((n: number) => number)
  targetRowHeight?: number | ((n: number) => number)
  limitNodeSearch?: number | ((n: number) => number)
  margin?: number
  useWindow?: boolean // resize based on window size
  onClick?: (e: React.MouseEvent<HTMLElement>, data: ImageSet<T>) => void
  onSelect?: (e: React.MouseEvent<HTMLElement>, data: ImageSet<T>, selected?: boolean) => void
}

export const Gallery = React.memo(<T extends unknown>(props: GalleryProps<T>) => {
  const { photos: groups, selected, margin = 2, useWindow } = props
  let { limitNodeSearch, targetRowHeight = 300 } = props
  const [containerWidth, setContainerWidth] = useState(0)
  const [scrollOffset, setScrollOffset] = useState(0)
  const galleryEl = useRef<HTMLDivElement>(null)
  let inRAF = false
  let scrollDirection: ScrollDirection = 'down'

  useEffect(() => {
    window.addEventListener('scroll', throttledScroll)
    throttledScroll()
    return () => {
      window.removeEventListener('scroll', throttledScroll)
    }
  }, [])

  // TODO Kinda gross
  if (useWindow) {
    useLayoutEffect(() => {
      const setWidth = debounce(() => {
        const newWidth = window.innerWidth
        if (containerWidth !== newWidth) {
          setContainerWidth(Math.floor(newWidth))
        }
      }, 50)
      window.addEventListener('resize', setWidth)
      if (containerWidth <= 0) {
        setContainerWidth(Math.floor(window.innerWidth))
      }
      return () => window.removeEventListener('resize', setWidth)
    })
  } else {
    useLayoutEffect(() => {
      let animationFrameID = -1
      const observer = new ResizeObserver((entries) => {
        // only do something if width changes
        const newWidth = entries[0].contentRect.width
        if (containerWidth !== newWidth) {
          // put in an animation frame to stop "benign errors" from
          // ResizObserver https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
          animationFrameID = window.requestAnimationFrame(() => {
            setContainerWidth(Math.floor(newWidth))
          })
        }
      })
      if (galleryEl.current) observer.observe(galleryEl.current)
      return () => {
        observer.disconnect()
        window.cancelAnimationFrame(animationFrameID)
      }
    })
  }

  const throttledScroll = throttle(() => handleScroll(), 100)

  const handleScroll = () => {
    const newYOffset = window.pageYOffset
    const prevYOffset = scrollOffset || newYOffset
    if (!inRAF) {
      inRAF = true
      window.requestAnimationFrame(() => {
        setScrollOffset(newYOffset)
        scrollDirection = scrollOffset > prevYOffset ? 'down' : 'up'
        inRAF = false
      })
    }
  }

  const onClick = (event: React.MouseEvent<HTMLElement>, data: GalleryItemWithIndex<T>) => {
    props.onClick?.(event, data)
  }

  const onSelect = (
    event: React.MouseEvent<HTMLElement>,
    data: GalleryItemWithIndex<T>,
    sel?: boolean
  ) => {
    props.onSelect?.(event, data, sel)
  }

  // no containerWidth until after first render with refs, skip calculations and render nothing
  if (!containerWidth) return <div ref={galleryEl}>&nbsp;</div>
  // subtract 1 pixel because the browser may round up a pixel
  const width = containerWidth - 1

  // allow user to calculate limitNodeSearch from containerWidth
  if (typeof limitNodeSearch === 'function') {
    limitNodeSearch = limitNodeSearch(containerWidth)
  }
  if (typeof targetRowHeight === 'function') {
    targetRowHeight = targetRowHeight(containerWidth)
  }
  // set how many neighboring nodes the graph will visit
  if (limitNodeSearch === undefined) {
    limitNodeSearch = 2
    if (containerWidth >= 450) {
      limitNodeSearch = findIdealNodeSearch({
        containerWidth,
        targetRowHeight,
      })
    }
  }

  let prevHeight = 0
  // Pictures shouldn't take up more than a ~60%/500px of the viewport on desktop
  const maxHeight =
    containerWidth >= 450 ? Math.min(500, round(window.innerHeight * 0.6)) : undefined
  const rows = groups.map((group) => {
    const { photos, containerHeight } = computeRowLayout({
      containerWidth: width,
      limitNodeSearch: limitNodeSearch as number,
      targetRowHeight: targetRowHeight as number,
      margin,
      offset: 48,
      maxHeight,
      photos: group.items,
    })
    const row = { top: prevHeight, height: containerHeight, photos, title: group.title }
    prevHeight += containerHeight
    return row
  })

  return (
    <div
      className="gallery"
      ref={galleryEl}
      style={{ position: 'relative', height: prevHeight, width: containerWidth }}
    >
      {rows.map((row, i) => {
        return (
          <GalleryRow
            {...row}
            id={i}
            scrollDirection={scrollDirection}
            scrollOffset={scrollOffset}
            key={row.title}
            onClick={onClick}
            onSelect={props.onSelect ? onSelect : undefined}
            selected={selected}
          />
        )
      })}
    </div>
  )
})
