// Using the components from primitives breaks the active class for some reason
// import { NavLink, NavLinkProps } from 'src/client/components/primitives'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { Box, BoxProps } from 'theme-ui'

export const Menu = ({ sx, children, ...rest }: BoxProps) => (
  <Box
    sx={{
      boxShadow: '0 1px 2px 0 rgb(34 36 38 / 15%)',
      display: 'flex',
      border: '1px solid rgba(34,36,38,.15)',
      borderRadius: 4,
      overflow: 'hidden',
      flexDirection: 'column',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

export const MenuItem = ({ children, ...rest }: NavLinkProps) => (
  <NavLink
    sx={{
      p: 3,
      borderBottom: '1px solid',
      borderColor: 'rgba(34,36,38,.15)',
      color: 'rgba(0, 0, 0, .95)',
      '&.active': {
        background: 'rgba(0,0,0,.06)',
      },
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'none',
        color: 'rgba(0, 0, 0, .95)',
        background: 'rgba(0,0,0,.03)',
      },
    }}
    {...rest}
  >
    {children}
  </NavLink>
)
