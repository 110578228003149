import darken from 'polished/lib/color/darken'
import { colors } from './colors'

const inputStyle = {
  borderColor: 'gray.4',
  transition: 'all 300ms ease-in',
  '&:focus': {
    backgroundColor: 'gray.1',
    border: `1px solid ${darken(0.2, colors.gray[4])}`,
    outline: 0,
  },
}

export const forms = {
  input: inputStyle,
  select: inputStyle,
}
