import React from 'react'
import { CheckCircle, PlayCircle } from 'src/client/assets/images'
import { easeOutQuint } from 'src/client/components/animation'
import { isTouch, isVideo } from 'src/client/util'
import { Box } from 'theme-ui'
import { GalleryItem } from './types'

export interface ImageProps<T = any> {
  index: number
  selected?: boolean
  photo: GalleryItem<T>
  containerHeight?: number
  onClick?: (
    e: React.MouseEvent<HTMLImageElement>,
    data: { photo: GalleryItem<T>; index: number }
  ) => void
  onSelect?: (
    e: React.MouseEvent<HTMLImageElement>,
    data: { photo: GalleryItem<T>; index: number }
  ) => void
}

const touch = isTouch()

const MessageCount = ({ count }: { count: number }) => (
  <Box
    sx={{
      opacity: 0.8,
      fontSize: '0.6875rem',
      backgroundColor: '#fff',
      letterSpacing: '0.07272727em',
      borderRadius: '3px 3px 0 3px',
      padding: '1px 3px',
      position: 'absolute',
      minWidth: '18px',
      textAlign: 'center',
      lineHeight: '1rem',
      right: '8px',
      bottom: '10px',
    }}
  >
    {count}
    <Box
      sx={{
        width: '4px',
        height: '8px',
        position: 'absolute',
        overflow: 'hidden',
        bottom: '-4px',
        right: 0,
        '&:after': {
          content: "''",
          position: 'absolute',
          width: '8px',
          height: '8px',
          background: '#fff',
          transform: 'rotate(45deg)',
        },
      }}
    />
  </Box>
)

export const Image = ({ index, onClick, onSelect, selected, photo }: ImageProps) => {
  const { sizes, srcSet, preview } = photo
  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    event.stopPropagation()
    onClick?.(event, { photo, index })
  }

  const handleSelect = (event: React.MouseEvent<HTMLImageElement>) => {
    event.stopPropagation()
    onSelect?.(event, { photo, index })
  }

  const onLoad = (e: React.SyntheticEvent) => {
    const img = e.currentTarget as HTMLImageElement
    img.style.opacity = '1.0'
  }

  const baseImageCSS = {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
  }

  return (
    <Box
      title={photo.title}
      sx={{
        display: 'block',
        overflow: 'hidden',
        position: 'absolute',
        left: 0,
        top: 0,
        background: '#ddd',
        transition: `transform 0.5s ${easeOutQuint} 0s`,
        cursor: 'pointer',
        userSelect: 'none',
        '&:after': {
          content: "''",
          height: '100%',
          width: '100%',
          position: 'absolute',
          top: 0,
          opacity: 0,
          backgroundImage:
            'linear-gradient(to bottom, rgba(0, 0, 0, 0.26), transparent 56px, transparent)',
        },
        '&:hover': {
          '&:after': {
            opacity: 1,
          },
          '.toggle': {
            opacity: 0.5,
          },
        },
        img: {
          transition: `transform 0.1s ${easeOutQuint} 0s, opacity 0.5s ${easeOutQuint} 0s`,
          transform: `${selected ? 'translateZ(0px) scale3d(0.85, 0.85, 1)' : 'none'}`,
        },
      }}
      style={{
        width: photo.width,
        height: photo.height,
        transform: `translate3d(${photo.left}px, ${photo.top}px, 0px)`,
      }}
      onClick={handleClick}
    >
      {!touch && onSelect && (
        <CheckCircle
          className="toggle"
          sx={{
            opacity: selected ? 1 : 0,
            transition: `opacity 0.25s ${easeOutQuint} 0s`,
            position: 'absolute',
            zIndex: 1,
            fill: selected ? 'primary' : 'white',
            left: '10px',
            top: '10px',
            '&:hover': {
              opacity: 0.8,
            },
          }}
          onClick={handleSelect}
        />
      )}
      {isVideo(photo.media.contentType) && (
        <PlayCircle
          sx={{
            position: 'absolute',
            zIndex: 1,
            opacity: 0.5,
            width: '50px',
            height: '50px',
            left: 'calc(50% - 25px)',
            top: 'calc(50% - 25px)',
          }}
        />
      )}
      <img
        src={preview}
        loading="lazy"
        width={photo.width}
        height={photo.height}
        sx={{
          ...baseImageCSS,
          filter: 'blur(10px)',
        }}
      />
      <img
        sizes={sizes ?? `${photo.width}px`}
        loading="lazy"
        // TODO FIX TYPE
        srcSet={srcSet as any}
        width={photo.width}
        height={photo.height}
        sx={{
          ...baseImageCSS,
          opacity: 0,
        }}
        onLoad={onLoad}
      />
      {photo.media.messageCount > 0 ? <MessageCount count={photo.media.messageCount} /> : null}
    </Box>
  )
}
