import parseISO from 'date-fns/parseISO'
import React from 'react'
import { matchPath } from 'react-router-dom'
import * as api from 'src/client/api'
import { authenticationUrl } from 'src/client/routes'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { Share } from 'src/server/share/share.entity'
import { UserRole } from 'src/server/user/types'
import { User } from 'src/server/user/user.entity'

export enum SharePermission {
  CanShare = 'canShare',
  CanEdit = 'canEdit',
  CanDelete = 'canDelete',
  CanCreate = 'canCreate',
  CanSignup = 'canSignup',
}

const { useState, useEffect, useContext, createContext } = React
const key = 'currentUser'
export const AuthContext = createContext<AuthProvider>(undefined!)
AuthContext.displayName = 'AuthContext'

// 1. Provide at the top of the tree
// <AuthProvider>
//   <App />
// </AuthProvider>
//
// 2a. Consume down the tree
// <AuthProvider.Consumer>
//   { value => <Component />}
// </AuthProvider.Consumer >
//
// 2b. or in a SFC
// const MyComponent = () => {
// const auth = useAuth()
// }
//
// 2c. or in a React Class
// class MyClass extends React.Component {}
// MyClass.contextType = AuthContext
export function AuthProvider({ children }: { children: React.ReactElement }) {
  const auth = useProvideAuth()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

// Hook for child components to get the auth object
export const useAuth = (): AuthProvider => useContext(AuthContext)

export const currentUser = () => {
  const json = localStorage.getItem(key)
  return json ? (JSON.parse(json) as UserSession) : null
}

type NullableSerializedAlbum = SerializedAlbum | undefined | null
interface UserSession {
  id: number
  email: string
  role: number
  name: string
  expiresAt: number
}

/* eslint-disable  @typescript-eslint/no-redeclare */
export interface AuthProvider {
  user: UserSession | null
  error?: string
  authenticated: boolean | null
  canSignup: (album?: NullableSerializedAlbum, share?: Share) => boolean
  canShare: (album?: NullableSerializedAlbum, share?: Share) => boolean
  canAddToAlbum: (album?: NullableSerializedAlbum) => boolean
  canDelete: (album?: NullableSerializedAlbum, share?: Share) => boolean
  canEdit: (album?: NullableSerializedAlbum, share?: Share) => boolean
  canCreate: (album?: NullableSerializedAlbum, share?: Share) => boolean
  isUser: () => boolean
  isAdmin: () => boolean
  isAlbumCreator: (album?: NullableSerializedAlbum) => boolean
  update: (session: UserSession | User) => void
  signIn: (email: string, password: string) => Promise<UserSession | null>
  signUp: (params: {
    email: string
    name: string
    password: string
    passwordConfirmation: string
    share?: string
  }) => Promise<User | null>
  signOut: () => Promise<void>
}

// Provider hook that creates auth object and handles state
function useProvideAuth(): AuthProvider {
  const [user, setUser] = useState<UserSession | null>(null)
  const [authenticated, setAuthenticated] = useState<boolean | null>(null)

  const signIn = async (email: string, password: string) => {
    try {
      const { data } = await api.auth.login({ email, password })
      onAuthenticated(data)
      return user
    } catch (e) {
      setAuthenticated(false)
      setUser(null)
      throw e
    }
  }

  const signUp = async (params: {
    email: string
    name: string
    password: string
    passwordConfirmation: string
    share?: string
  }) => {
    try {
      const { data } = await api.auth.signup(params)
      console.log(data)
      onAuthenticated(data)
      return data
    } catch (e) {
      setAuthenticated(false)
      setUser(null)
      throw e
    }
  }

  const signOut = async () => {
    await api.auth.logout()
    localStorage.removeItem(key)
    setUser(null)
    setAuthenticated(null)
    if (!matchPath(location.pathname, authenticationUrl.route)) {
      window.location.pathname = authenticationUrl()
    }
  }

  const onAuthenticated = (newUser: any) => {
    const { expiresAt, ...rest } = newUser
    const newUser1 = { ...rest, expiresAt: parseISO(expiresAt).getTime() }
    localStorage.setItem(key, JSON.stringify(newUser1))
    setUser(newUser1)
    setAuthenticated(true)
  }

  const onUnauthenticated = () => {
    localStorage.removeItem(key)
    setUser(null)
    setAuthenticated(false)
  }

  const updateToken = async (session: UserSession) => {
    if (expired(session)) {
      console.log('session expired, signing out')
      onUnauthenticated()
      return
    }
    try {
      const { data } = await api.auth.heartbeat()
      update({ ...session, expiresAt: parseISO(data.expiresAt).getTime() })
    } catch (e) {
      console.log('encountered error updating token', e.response.data)
    }
  }

  const update = (session: UserSession) => {
    const updated = { ...user, ...session }
    localStorage.setItem(key, JSON.stringify(updated))
    setUser(updated)
  }

  const expired = (session?: UserSession | null) => {
    if (!session) return true
    const now = new Date().getTime()
    return now > session.expiresAt - 60 * 1000
  }

  const canSignup = (album?: NullableSerializedAlbum, share?: Share) => {
    if (authenticated) return false
    return shareAllows(SharePermission.CanSignup, album, share)
  }

  const canShare = (album?: NullableSerializedAlbum, share?: Share) => {
    if (!isUser()) return false
    return (
      (album && (isAlbumCreator(album) || shareAllows(SharePermission.CanShare, album, share))) ||
      false
    )
  }

  // TODO This is wrong
  const canAddToAlbum = (album?: NullableSerializedAlbum) => isAlbumCreator(album)

  // Maybe consolidate this into?
  // const guestForbidden = () => isUser()
  // Guess we could just do isUser

  const canDelete = (album?: NullableSerializedAlbum, share?: Share) =>
    isAlbumCreator(album) || shareAllows(SharePermission.CanDelete, album, share)

  const canEdit = (album?: NullableSerializedAlbum, share?: Share) =>
    isAlbumCreator(album) || shareAllows(SharePermission.CanEdit, album, share)

  const canCreate = (album?: NullableSerializedAlbum, share?: Share) =>
    isAlbumCreator(album) || shareAllows(SharePermission.CanCreate, album, share)

  const shareAllows = (perm: string, album?: NullableSerializedAlbum, share?: Share) =>
    !!(share || (album as any)?.share || {})[perm]

  // If there's no album the user is looking at their own content
  const isAlbumCreator = (album?: NullableSerializedAlbum) => {
    if (album && user) {
      return typeof album.creator === 'number'
        ? user.id === album.creator
        : user.id === album.creator?.id
    }
    return !!user
  }

  const isUser = () => (user ? user.role < UserRole.Guest : false)

  const isAdmin = () => (user ? user.role === UserRole.Admin : false)

  // On mount
  useEffect(() => {
    const existingUser = currentUser()
    if (expired(existingUser)) {
      // Don't want to trigger a redirect here in case someone is viewing an unauthenticated route
      onUnauthenticated()
    } else {
      onAuthenticated(existingUser)
      updateToken(existingUser!)
    }
  }, [])

  // Every minute
  useEffect(() => {
    if (!user) return
    const interval = setInterval(() => {
      updateToken(user)
    }, 1000 * 60 * 1)
    return () => clearInterval(interval)
  }, [user])

  // Return the user object and auth methods
  return {
    user,
    update,
    authenticated,
    canSignup,
    canShare,
    canAddToAlbum,
    canDelete,
    canEdit,
    canCreate,
    isAlbumCreator,
    isUser,
    isAdmin,
    signIn,
    signUp,
    signOut,
  }
}
