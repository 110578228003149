import { PASSWORD_MAX_LENGTH, PASSWORD_MIN_LENGTH } from 'src/shared/constants'
import * as Yup from 'yup'

const invalidLength = (name: string, min = 6, max = 50) =>
  `Invalid ${name}. Must be between ${min} and ${max} characters`

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required('Valid Email Required'),
})

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(PASSWORD_MIN_LENGTH, invalidLength('Password'))
    .max(PASSWORD_MAX_LENGTH, invalidLength('Password')),
})

export const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email').required('Valid Email Required'),
  password: Yup.string()
    .min(PASSWORD_MIN_LENGTH, invalidLength('Password'))
    .max(PASSWORD_MAX_LENGTH, invalidLength('Password')),
})

export const accountSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .min(2, invalidLength('Name', 1))
    .max(50, invalidLength('Name', 1))
    .required('Name Required'),
  password: Yup.string()
    .min(PASSWORD_MIN_LENGTH, invalidLength('Password'))
    .max(PASSWORD_MAX_LENGTH, invalidLength('Password')),
  // .required('Password Required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .min(PASSWORD_MIN_LENGTH, invalidLength('passwordConfirmation'))
    .max(PASSWORD_MAX_LENGTH, invalidLength('passwordConfirmation')),
  // .required('Confirmation Required'),
})
