import { truncate } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import * as api from 'src/client/api'
import { ImageAdd, PhotoAlbum } from 'src/client/assets/images'
import { colors } from 'src/client/assets/theme'
import { Header, MainLayout } from 'src/client/components/blocks'
import { useAuth } from 'src/client/components/hooks/useAuth'
import { NavLink } from 'src/client/components/primitives'
import * as routes from 'src/client/routes'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { VariationName } from 'src/server/media/types'
import { mediaStreamUrl } from 'src/server/routes'
import { Button, Container, Flex, Text } from 'theme-ui'
import { AlbumDialog } from './AlbumForm'

export const AlbumList = () => {
  const { isUser } = useAuth()
  const [albums, setAlbums] = useState<SerializedAlbum[]>([])
  const [open, setOpen] = useState(false)
  const showDialog = () => setOpen(true)
  const hideDialog = () => setOpen(false)
  const onSuccess = (album: SerializedAlbum) => {
    setAlbums([album].concat(albums))
    hideDialog()
  }

  useEffect(() => {
    const fetch = async () => {
      try {
        const { data } = await api.albums.list()
        setAlbums(data)
      } catch (e) {
        setAlbums([])
      }
    }
    window.scrollTo(0, 0)
    fetch()
  }, [])

  return (
    <MainLayout>
      <Header title="Albums" />
      <Container variant="albumList">
        <Flex sx={{ flexWrap: 'wrap' }}>
          {isUser() && (
            <Button variant="album" mr="1rem" mb="1rem" onClick={showDialog}>
              <div>
                <ImageAdd sx={{ fill: 'primary', width: '40%', height: '40%' }} />
              </div>
            </Button>
          )}
          {albums.map((a) => (
            <NavLink
              mb="1rem"
              mr="1rem"
              sx={{ color: 'secondary', width: '200px' }}
              key={a.id}
              to={routes.albumUrl({ id: a.id })}
            >
              <Flex
                variant="album"
                sx={{ bg: 'gray.1', alignItems: 'center', justifyContent: 'center' }}
                style={{
                  // TODO Note the type is wrong, this a fully-formed URL not a cover object
                  backgroundImage: a.cover
                    ? `url(${mediaStreamUrl({
                        id: a.cover.id,
                        variation: VariationName.Album,
                      })})`
                    : 'none',
                }}
              >
                {!a.cover && (
                  <PhotoAlbum sx={{ width: '40%', height: '40%' }} fill={colors.gray[4]} />
                )}
              </Flex>
              <div>
                <Text sx={{ fontWeight: 'normal' }}>{`${truncate(a.name, { length: 50 })}${
                  a.shared ? ' · Shared' : ''
                }`}</Text>
              </div>
            </NavLink>
          ))}
          <AlbumDialog isOpen={open} onClose={hideDialog} onSuccess={onSuccess} />
        </Flex>
      </Container>
    </MainLayout>
  )
}
