import { ForgotPasswordDto } from 'src/server/auth/dto'
import { LoginUserDto } from 'src/server/auth/dto/login-user.dto'
import { ResetPasswordDto } from 'src/server/auth/dto/reset-password.dto'
import {
  forgotPasswordUrl,
  heartbeatUrl,
  loginUrl,
  logoutUrl,
  resetPasswordUrl,
  signupUrl,
} from 'src/server/routes'
import { CreateUserDto } from 'src/server/user/dto/create-user.dto'
import { User } from 'src/server/user/user.entity'
import { get, post } from './api'

export const auth = {
  signup: async (params: CreateUserDto) => await post<User>(signupUrl(), params),
  login: async (params: LoginUserDto) => await post<User>(loginUrl(), params),
  logout: async () => await post<User>(logoutUrl()),
  resetPassword: async (params: ResetPasswordDto) => await post<User>(resetPasswordUrl(), params),
  forgotPassword: async (params: ForgotPasswordDto) =>
    await post<User>(forgotPasswordUrl(), params),
  heartbeat: async () => await get<{ expiresAt: string }>(heartbeatUrl()),
}
