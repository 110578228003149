import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  NavLink as RouterNavLink,
  NavLinkProps as NavRouterLinkProps,
} from 'react-router-dom'
import {
  Link as ThemeLink,
  LinkProps as TULinkProps,
  NavLink as ThemeNavLink,
} from 'theme-ui'

export type LinkProps = RouterLinkProps & TULinkProps

export const Link = (props: LinkProps) => <ThemeLink as={RouterLink} {...props} />

export type NavLinkProps = NavRouterLinkProps & TULinkProps

export const NavLink = (props: NavLinkProps) => <ThemeNavLink as={RouterNavLink} {...props} />
