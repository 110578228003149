import { tailwind } from '@theme-ui/presets'
import merge from 'lodash-es/merge'
import { buttons } from './buttons'
import { colors } from './colors'
import { forms } from './forms'
import { links } from './links'
import { variants } from './variants'

export { colors } from './colors'

export const theme = merge(tailwind, {
  forms,
  colors,
  layout: {
    albumList: {
      px: 3,
    },
  },
  links,
  buttons,
  heading: {
    fontWeight: 500,
  },
  text: {
    small: {
      fontSize: 12,
    },
  },
  variants,

  styles: {
    ...tailwind.styles,
    progress: {
      background: colors.gray[3],
    },
  },
})

export type Theme = typeof theme

console.log(theme)
