import React from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { Header } from 'src/client/components/blocks/Header'
import { useAuth } from 'src/client/components/hooks/useAuth'
import { SettingPath, settingsListUrl } from 'src/client/routes'
import { Box, Container, Grid } from 'theme-ui'
import { AccountForm } from './internal/AccountForm'
import { Menu, MenuItem } from './internal/Menu'
import { PlaceList } from './internal/PlaceList'
import { ShareList } from './internal/ShareList'
import { UserList } from './internal/UserList'

export const SettingsListComponent = () => {
  const { isAdmin } = useAuth()

  return (
    <Container>
      <Header title="Settings" />
      <Box as="main" p={4} mt={'50px'}>
        <Grid gap={2} columns={['1fr', null, '240px 1fr']}>
          <Box pr={2}>
            <Menu>
              <MenuItem exact strict to={settingsListUrl({})}>
                Account
              </MenuItem>
              {isAdmin() && (
                <React.Fragment>
                  <MenuItem exact to={settingsListUrl({ path: SettingPath.Users })}>
                    Users
                  </MenuItem>
                  <MenuItem exact to={settingsListUrl({ path: SettingPath.Places })}>
                    Places
                  </MenuItem>
                  <MenuItem exact to={settingsListUrl({ path: SettingPath.Shares })}>
                    Shares
                  </MenuItem>
                </React.Fragment>
              )}
            </Menu>
          </Box>
          <Box>
            <Switch>
              <Route path={settingsListUrl({ path: SettingPath.Shares })} component={ShareList} />
              <Route path={settingsListUrl({ path: SettingPath.Places })} component={PlaceList} />
              <Route path={settingsListUrl({ path: SettingPath.Users })} component={UserList} />
              <Route path={settingsListUrl({})} component={AccountForm} />
            </Switch>
          </Box>
        </Grid>
      </Box>
    </Container>
  )
}

export const SettingsList = withRouter(SettingsListComponent)
