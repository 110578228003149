import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { Box, Close, Container, Flex, Heading } from 'theme-ui'

export interface DialogProps {
  target?: HTMLElement
  title?: string
  isOpen: boolean
  onClose?: () => void
  backdropCloses?: boolean
  children: React.ReactNode
}

export const Dialog = ({ backdropCloses = true, ...props }: DialogProps) => {
  const [open, setOpen] = useState(props.isOpen)
  const close = () => {
    setOpen(false)
    props.onClose?.()
  }
  const closeViaBackdrop = () => {
    if (backdropCloses) close()
  }

  useEffect(() => {
    setOpen(props.isOpen)
  }, [props.isOpen])

  return open
    ? createPortal(
        <Box>
          <Box
            onClick={closeViaBackdrop}
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              bottom: 0,
              left: 0,
              position: 'fixed',
              right: 0,
              top: 0,
              zIndex: '1200',
            }}
          />
          <Container
            sx={{
              backgroundColor: 'white',
              position: 'fixed',
              top: '50%',
              left: '50%',
              width: '50%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1201,
            }}
          >
            <Box as="header" sx={{ p: 2 }}>
              <Flex
                sx={{ flex: '1 1 auto', justifyContent: 'space-between', alignItems: 'center' }}
              >
                {props.title && <Heading as="h3">{props.title}</Heading>}
                <Close onClick={close} sx={{ width: 8, height: 8 }} />
              </Flex>
            </Box>
            {props.children}
          </Container>
        </Box>,
        props.target ?? document.querySelector('body')!
      )
    : null
}
