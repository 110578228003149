import startOfMonth from 'date-fns/startOfMonth'
import groupBy from 'lodash-es/groupBy'
import React from 'react'
import {
  Gallery,
  GalleryItem,
  GalleryItemWithIndex,
} from 'src/client/components/blocks/gallery'
import { AuthContext } from 'src/client/components/hooks/useAuth'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { SerializedMedia } from 'src/server/media/media.entity'

type MediaItem = GalleryItem<SerializedMedia>
type MediaItemWithIndex = GalleryItemWithIndex<SerializedMedia>

interface MediaGroup {
  title: string
  items: MediaItem[]
}

interface State {
  groupedMedia: MediaGroup[]
  lastSelectedIndex: number // index of the last selected media
}

interface Props {
  media: MediaItem[]
  album: SerializedAlbum | null
  selected: Set<string> // set of selected ids
  onMediaClick: (media: MediaItemWithIndex) => void
  onMediaSelect: (ids: Set<string>) => void
}

export class MediaGallery extends React.Component<Props, State> {
  static contextType = AuthContext
  declare context: React.ContextType<typeof AuthContext>

  state: State = {
    groupedMedia: [],
    lastSelectedIndex: -1,
  }

  componentDidMount() {
    this.sortMedia()
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.selected.size && this.props.selected.size === 0) {
      this.setState({ lastSelectedIndex: -1 })
    }
    if (prevProps.media.length !== this.props.media.length) {
      this.sortMedia()
    }
  }

  // So these aren't really being sorted, so they get out of sync on add
  sortMedia() {
    const { media } = this.props
    const byMonth = groupBy(media, (m) => startOfMonth(m.date))
    const groupedMedia: MediaGroup[] = Object.keys(byMonth).reduce<MediaGroup[]>(
      // eslint-disable-next-line no-sequences
      (acc, k) => (acc.push({ title: k, items: byMonth[k] }), acc),
      []
    )
    this.setState({ groupedMedia })
  }

  onMediaClick = (e: React.MouseEvent<HTMLElement>, data: MediaItemWithIndex) => {
    if (this.props.selected.size) {
      this.onMediaSelect(e, data)
      return
    }
    this.props.onMediaClick(data)
  }

  onMediaSelect = (
    e: React.MouseEvent<HTMLElement>,
    data: MediaItemWithIndex | MediaItem[],
    selected?: boolean
  ) => {
    if (Array.isArray(data)) {
      this.onMediaGroupSelect(data, selected === undefined ? true : selected)
    } else {
      this.onMediaSingleSelect(e, data)
    }
  }

  onMediaSingleSelect = (e: React.MouseEvent<HTMLElement>, data: MediaItemWithIndex) => {
    e.stopPropagation()
    const {
      index,
      photo: { key },
    } = data
    const { media, selected: currentSelection } = this.props
    const { lastSelectedIndex } = this.state
    const selected = new Set(currentSelection)
    let op: 'delete' | 'add'
    let ids: string[] = []
    if (e.nativeEvent.shiftKey && lastSelectedIndex > -1) {
      op = selected.has(media[lastSelectedIndex].key) ? 'add' : 'delete'
      const [from, to] =
        index < lastSelectedIndex ? [index, lastSelectedIndex] : [lastSelectedIndex, index]
      ids = media.slice(from, to + 1).map((m) => m.key)
    } else {
      op = selected.has(key) ? 'delete' : 'add'
      ids = [key]
    }
    ids.forEach((id) => selected[op](id))
    this.props.onMediaSelect(selected)
    this.setState({ lastSelectedIndex: index })
  }

  onMediaGroupSelect = (data: MediaItem[], add: boolean) => {
    const { selected: currentSelection } = this.props
    const selected = new Set(currentSelection)
    const op = add ? 'add' : 'delete'
    data.forEach((d) => selected[op](d.key))
    this.props.onMediaSelect(selected)
    this.setState({ lastSelectedIndex: -1 })
  }

  render() {
    const { groupedMedia } = this.state
    const { album, selected } = this.props
    const { canDelete, canAddToAlbum } = this.context

    return (
      <Gallery
        useWindow={true}
        margin={5}
        selected={selected}
        photos={groupedMedia}
        onClick={this.onMediaClick}
        onSelect={canDelete(album) || canAddToAlbum(album) ? this.onMediaSelect : undefined}
      />
    )
  }
}
