import { format } from 'date-fns'
import { SerializedMedia } from 'src/server/media/media.entity'
import { round } from './math'

export function formatLocation(media?: SerializedMedia | null) {
  const place = media?.place
  const loc = place?.location?.coordinates || media?.location?.coordinates
  if (!loc) return place?.label ? { label: place.label } : null
  const [lng, lat] = loc
  const label = place?.label ?? `${lat}, ${lng}`
  return { lng, lat, label }
}

export function formatTime(date: Date, offset?: number) {
  const fmt = "E, h:mm a 'GMT'"
  if (offset) {
    return `${format(date, fmt)}${offset < 0 ? '-' : ''}${msToTimeUnit(Math.abs(offset), false)}`
  } else {
    return format(date, `${fmt}XXX`)
  }
}

interface FormatBytesOptions {
  // Format the number as bits instead of bytes
  bits?: boolean
  // Include plus sign for positive numbers.
  signed?: boolean
}

const BYTE_UNITS = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
const BIT_UNITS = ['b', 'kbit', 'Mbit', 'Gbit', 'Tbit', 'Pbit', 'Ebit', 'Zbit', 'Ybit']

export const formatMegapixel = (width?: number, height?: number) => {
  if (!width || !height) return undefined
  return `${round((width * height) / 1024000)}MP`
}

export const formatBytes = (
  bytes?: number,
  options: FormatBytesOptions = { bits: false, signed: false }
) => {
  if (!bytes) return bytes
  if (!Number.isFinite(bytes)) {
    throw new TypeError(`Expected a finite number, got ${typeof bytes}: ${bytes}`)
  }
  const UNITS = options.bits ? BIT_UNITS : BYTE_UNITS

  if (options.signed && bytes === 0) return ' 0 ' + UNITS[0]

  const isNegative = bytes < 0
  const prefix = isNegative ? '-' : options.signed ? '+' : ''

  if (isNegative) bytes = -bytes
  if (bytes < 1) return `${prefix}${bytes} ${UNITS[0]}`

  const exponent = Math.min(Math.floor(Math.log10(bytes) / 3), UNITS.length - 1)
  bytes = Number((bytes / Math.pow(1000, exponent)).toPrecision(3))
  return `${prefix}${bytes} ${UNITS[exponent]}`
}

export const msToDurationByUnit = (ms: number = 0) => {
  let delta = parseInt((ms / 1000).toFixed(0), 10)

  const days = Math.floor(delta / 86400)
  delta -= days * 86400

  const hours = Math.floor(delta / 3600) % 24
  delta -= hours * 3600

  const minutes = Math.floor(delta / 60) % 60
  delta -= minutes * 60

  const seconds = delta % 60

  return {
    seconds,
    minutes,
    hours,
    days,
  }
}

// convers milliseconds to "HOURS:MM:SS"
export const msToTimeUnit = (ms: number, seconds = true) => {
  const dur = msToDurationByUnit(ms)
  return [dur.hours, dur.minutes, seconds ? dur.seconds : -1]
    .filter((s) => s !== -1)
    .map((d) => d.toFixed(0).padStart(2, '0'))
    .join(':')
}
