import { matchPath, Redirect } from 'react-router-dom'
import { useAuth } from 'src/client/components/hooks/useAuth'
import { useQuery } from 'src/client/components/hooks/useQuery'
import { albumListUrl, albumUrl, authenticationUrl } from 'src/client/routes'
import { MediaList } from 'src/client/scenes'

const allowed = () => !!matchPath(location.pathname, { path: albumUrl.route })

export const PrivateMediaList = () => {
  const key = useQuery().get('key')
  const { authenticated, isUser } = useAuth()
  if (!key && typeof authenticated !== 'boolean') return null // Still authenticating
  if (!key && !authenticated) return <Redirect to={{ pathname: authenticationUrl() }} />
  // Guests can only access the album list and album detail (they do not have their own media index)
  if (!key && !isUser() && !allowed()) {
    return <Redirect to={{ pathname: albumListUrl() }} />
  }
  // Album detail can be directly accessed with a share key
  return <MediaList />
}
