import React from 'react'
import { Puff } from 'src/client/assets/images'
import { Loading } from 'src/client/components/primitives'
import { Box, Container, ContainerProps } from 'theme-ui'

interface Props extends ContainerProps {
  loading?: boolean
  children: React.ReactNode
}

export const MainLayout = (props: Props) => {
  const { loading, children, ...rest } = props

  return (
    <Container as="main" {...rest}>
      <Box mt="50px">{children}</Box>
      <Loading visible={!!loading}>
        <Puff sx={{ stroke: 'gray.6' }} />
      </Loading>
    </Container>
  )
}
