import { SerializedAlbum } from 'src/server/album/album.entity'
import { ListQueryDto } from 'src/server/album/dto'
import {
  albumByKeyUrl,
  albumListUrl,
  albumMediaListUrl,
  albumUrl,
  Id,
} from 'src/server/routes'
import { del, get, post, put } from './api'

export interface SetMediaParams {
  media: string[]
}

export interface CreateAlbumParams {
  name: string
  mediaId?: string[]
  creator?: number
}

export interface UpdateAlbumParams {
  name?: string
  mediaId?: string[]
  cover?: string
}

export const albums = {
  list: async (query?: ListQueryDto) =>
    await get<SerializedAlbum[]>(albumListUrl(), { params: query }),
  get: async (params: Id & { key?: string | null }) => {
    const { id, key } = params
    return await get<SerializedAlbum>(albumUrl({ id }), key ? { params: { key } } : {})
  },
  // TODO Temporary because we don't have the id in the URL
  getByKey: async (params: { key: string }) => {
    return await get<SerializedAlbum>(albumByKeyUrl({ key: params.key }))
  },
  create: async (data: CreateAlbumParams) => await post<SerializedAlbum>(albumListUrl(), data),
  update: async (params: Id, data: UpdateAlbumParams) =>
    await put<SerializedAlbum>(albumUrl(params), data),
  delete: async (params: Id) => await del(albumUrl(params)),
  addMedia: async (params: Id, data: SetMediaParams) => await post(albumMediaListUrl(params), data),
  removeMedia: async (params: Id, data: SetMediaParams) =>
    await del(albumMediaListUrl(params), { data }),
}
