import React, { useEffect, useState } from 'react'
import { Transition } from 'react-transition-group'
import { ChevronDown, ChevronUp } from 'src/client/assets/images'
import { generatePreview } from 'src/client/util'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { JobState } from 'src/server/media/types'
import { Box, BoxProps, Button as TUButton, Flex, Text } from 'theme-ui'

const imageDimension = 96
const contentPadding = 12
const progressHeight = 4
const transitionStyles = {
  entering: { value: 0 },
  entered: { value: 0 },
  exiting: { value: 120 },
  exited: { value: 120 },
  unmounted: { value: 120 },
}

interface Job {
  id: string
  filename: string
  state: JobState
  progress: number
}

export interface NotifierProps {
  /** Current upload */
  upload: {
    /** Album being added to */
    album: SerializedAlbum | null
    /** Progress of current upload */
    progress: number
    /** State of the current upload */
    state: JobState
    /** File being uploaded */
    file?: File
  }
  /** Index of current uploaded (0-indexed) */
  current?: number
  /** Total number of files to upload */
  total?: number
  /** Cancel upload action */
  onCancel?: () => void
  /** Any current background jobs */
  jobs: Job[]
}

export const Notifier = (props: NotifierProps) => {
  const {
    current,
    upload: { file },
    jobs,
    total,
  } = props
  const [uploading, setUploading] = useState(false)
  const [preview, setPreview] = useState('')

  useEffect(() => {
    if (file) {
      generatePreview(file)
        .then((url) => {
          if (typeof url === 'string') setPreview(url)
        })
        .catch(() => console.log('Failed to render preview'))
    }
  }, [file])

  useEffect(() => {
    setUploading(!(~~current === -1 || ~~total === 0))
  }, [current, total, jobs])

  return (
    <Container>
      <Notification
        preview={preview}
        uploading={uploading}
        visible={uploading || jobs.length > 0}
        {...props}
      ></Notification>
    </Container>
  )
}

const Container = ({ children }: BoxProps) => (
  <Box
    sx={{
      minWidth: '288px',
      maxWidth: '568px',
      position: 'fixed',
      transition: 'transform 0.1s cubic-bezier(0.4, 0, 0.2, 1)',
      overflow: 'visible',
      bottom: 0,
      left: 0,
      zIndex: 500,
    }}
    style={{
      transform: 'translate3d(0px, 0px, 0px)',
    }}
  >
    {children}
  </Box>
)

interface NotificationProps extends NotifierProps {
  visible: boolean
  uploading: boolean
  preview: string
}

const Notification = ({
  visible,
  uploading,
  preview,
  current,
  total,
  upload,
  jobs,
  onCancel,
  ...rest
}: NotificationProps) => {
  return (
    <Transition in={visible} timeout={200}>
      {(state) => (
        <Box
          sx={{
            maxWidth: 'calc(100vw - 2 * 24px)',
            padding: '0 24px 24px',
            width: '100%',
            transform: 'translateZ(0)',
            transition: 'transform 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
          }}
          style={{
            transform: `translate3d(0px, ${transitionStyles[state].value}px, 0px)`,
          }}
        >
          <Box
            {...rest}
            sx={{
              backgroundColor: '#fff',
              boxShadow: '0 1px 3px 0 rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15)',
              borderRadius: '8px',
              width: '328px',
            }}
          >
            {uploading && (
              <Upload
                preview={preview}
                current={current}
                total={total}
                upload={upload}
                onCancel={onCancel}
              />
            )}
            {jobs.length > 0 && <BackgroundJobs album={upload.album} jobs={jobs} />}
          </Box>
        </Box>
      )}
    </Transition>
  )
}

const Upload = ({
  preview,
  current,
  total,
  upload: { album, progress },
  onCancel,
}: Omit<NotificationProps, 'jobs' | 'visible' | 'uploading'>) => (
  <Flex
    sx={{
      overflow: 'hidden',
      minHeight: `${imageDimension}px`,
    }}
  >
    <Box
      sx={{
        backgroundColor: '#e8eaed',
        backgroundSize: 'cover',
        borderRadius: '3px 0 0 3px',
        height: `${imageDimension}px`,
        width: `${imageDimension}px`,
      }}
      style={{
        backgroundImage: `url("${preview}")`,
      }}
    />
    <Flex
      sx={{
        flex: 1,
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <CancelButton onClick={onCancel} />
      <Box
        sx={{
          minHeight: `${imageDimension - contentPadding * 2 - progressHeight}px`,
          boxSizing: 'content-box',
          padding: `${contentPadding}px ${contentPadding * 2}px`,
        }}
        role="status"
      >
        <Status>Uploading to</Status>
        <Title>{album?.name ?? 'Photo Library'}</Title>
        <Status>
          {Number(current) + 1} of {total}
        </Status>
      </Box>
      <Progress value={progress} />
    </Flex>
  </Flex>
)

const BackgroundJobs = ({ album, jobs }: { album: SerializedAlbum | null; jobs: Job[] }) => {
  const [bgVisible, setBgVisible] = useState(false)
  return (
    <Box py={1} px={2} sx={{ flex: 1 }}>
      <Flex>
        <TUButton sx={{ lineHeight: 1 }} variant="text" onClick={() => setBgVisible(!bgVisible)}>
          {bgVisible ? <ChevronUp fill="secondary" /> : <ChevronDown fill="secondary" />}
        </TUButton>
        <Text>
          {jobs.length} files processing{album ? ` for ${album.name}` : ''}
        </Text>
      </Flex>
      <Box
        sx={{
          maxHeight: bgVisible ? `calc(100vw - ${imageDimension}px - ${contentPadding * 2}px)` : 0,
          overflow: 'hidden',
        }}
      >
        <Box pb={2} px={2}>
          {jobs.map((j) => (
            <Box key={j.id}>
              {j.filename}
              <Flex sx={{ alignItems: 'center' }}>
                <Progress value={j.progress} />
                <Text ml={2} variant="small">
                  ({j.state === JobState.Waiting ? 'Enqueued' : `${(j.progress * 100).toFixed(2)}%`}
                  )
                </Text>
              </Flex>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

const CancelButton = ({ onClick }: { onClick?: () => void }) =>
  onClick ? (
    <button
      onClick={onClick}
      style={{
        position: 'absolute',
        bottom: '6px',
        right: '8px',
        height: '36px',
        minWidth: '64px',
        borderRadius: '4px',
        padding: '0 8px',
        backgroundColor: 'transparent',
        willChange: 'transform, opacity',
        fontSize: '0.875rem',
        border: 'none',
        outline: 'none',
        overflow: 'visible',
        verticalAlign: 'middle',
        fontWeight: 500,
      }}
      aria-label="Stop uploading"
    >
      Stop
    </button>
  ) : null

const Progress = ({ value }: { value: number }) => (
  <Box
    sx={{
      flex: '1',
      backgroundColor: '#cfd8dc',
      height: `${progressHeight}px`,
      overflow: 'hidden',
      position: 'relative',
    }}
  >
    <Box
      sx={{
        backgroundColor: '#4285f4',
        transition: 'transform 0.1s',
        position: 'absolute',
        height: '100%',
        width: '100%',
        right: '100%',
      }}
      style={{ transform: `translateX(${Math.min(value * 100, 100)}%)` }}
    ></Box>
  </Box>
)

const Title = ({ children }: BoxProps) => (
  <Box
    sx={{
      color: 'rgba(0, 0, 0, 0.87)',
      marginBottom: '12px',
      maxWidth: '156px',
      overflow: 'hidden',
      paddingRight: '28px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      letterSpacing: '0.0125em',
      fontSize: '1rem',
      fontWeight: '500',
      lineHeight: '1.5rem',
    }}
  >
    {children}
  </Box>
)

const Status = ({ children }: BoxProps) => (
  <Box
    sx={{
      color: 'rgba(0, 0, 0, 0.54)',
      letterSpacing: '0.025em',
      fontSize: '0.75rem',
      fontWeight: '400',
      lineHeight: '1rem',
    }}
  >
    {children}
  </Box>
)
