import React from 'react'
import { createPortal } from 'react-dom'
import { TransitionGroup } from 'react-transition-group'

export class ModalGateway extends React.Component<{
  children?: React.ReactNode
  target: HTMLElement
}> {
  static defaultProps = {
    target: typeof window !== 'undefined' ? document.body : null,
  }

  render() {
    if (typeof window === 'undefined') return null
    const { target, children } = this.props
    return createPortal(<TransitionGroup children={children} />, target)
  }
}
