import { useEffect, useState } from 'react'
import { Redirect, useRouteMatch } from 'react-router-dom'
import * as api from 'src/client/api'
import { Puff } from 'src/client/assets/images'
import { Loading } from 'src/client/components/primitives'
import { albumUrl, DEPRECATED_SHARE_URL } from 'src/client/routes'

export const MediaRedirector = () => {
  const match = useRouteMatch<{ shareKey: string }>(DEPRECATED_SHARE_URL.route)
  const key = match?.params?.shareKey
  const [slug, setSlug] = useState<string | number>(undefined!)
  useEffect(() => {
    const fetch = async () => {
      if (!key) return
      const { data: album } = await api.albums.getByKey({ key })
      setSlug(album.id)
    }
    fetch()
  }, [key])

  if (slug) return <Redirect to={albumUrl({ id: slug }, { key })} />

  return (
    <Loading visible>
      <Puff sx={{ stroke: 'gray.6' }} />
    </Loading>
  )
}
