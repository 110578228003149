import * as React from 'react'
import { CatastrophicError } from './CatastrophicError'

// Ported from https://github.com/bvaughn/react-error-boundary

const changedArray = (a: any[] = [], b: any[] = []) =>
  a.length !== b.length || a.some((item, index) => !Object.is(item, b[index]))

export interface FallbackProps {
  error?: Error
  componentStack?: string
  resetErrorBoundary: () => void
}

export interface ErrorBoundaryProps {
  onResetKeysChange?: (prevResetKeys?: any[], resetKeys?: any[]) => void
  onReset?: () => void
  onError?: (error: Error, componentStack: string) => void
  resetKeys?: any[]
  FallbackComponent?: React.ComponentType<FallbackProps>
}

interface ErrorBoundaryState {
  error: Error | null
  info: any
}

const initialState: ErrorBoundaryState = { error: null, info: null }

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = initialState

  resetErrorBoundary = () => {
    this.props.onReset?.()
    this.setState(initialState)
  }

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.props.onError ? this.props.onError(error, info?.componentStack) : console.log(error)
    this.setState({ error, info })
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    const { error } = this.state
    const { resetKeys } = this.props
    if (error !== null && changedArray(prevProps.resetKeys, resetKeys)) {
      this.props.onResetKeysChange?.(prevProps.resetKeys, resetKeys)
      this.setState(initialState)
    }
  }

  render() {
    const { error, info } = this.state
    const { FallbackComponent } = this.props

    if (error !== null) {
      const props = {
        componentStack: info?.componentStack,
        error,
        resetErrorBoundary: this.resetErrorBoundary,
      }
      return FallbackComponent ? <FallbackComponent {...props} /> : <CatastrophicError {...props} />
    }

    return this.props.children
  }
}
