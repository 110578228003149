import { Id, r } from 'src/server/routes'

export const indexUrl = '/'
export const mediaUrl = r<{ mediaId: string }>('/media/:mediaId')
export const trashUrl = r('/trash')
export const trashMediaUrl = r<Id>(`${trashUrl.route}/media/:id`)
export const authenticationUrl = r('/login')
export const forgotPasswordUrl = r('/forgot-password')
export const resetPasswordUrl = r('/reset-password')
export const albumListUrl = r('/albums')
export const albumUrl = r<Id>(`${albumListUrl.route}/:id`)
export const albumMediaUrl = r<Id & { mediaId: string }>(`${albumUrl.route}/media/:mediaId`)
export const DEPRECATED_SHARE_URL = r<{ shareKey: string }>(`/s/:shareKey`)
export const DEPRECATED_SHARE_MEDIA_URL = r<{ shareKey: string; mediaId: string | number }>(
  `${DEPRECATED_SHARE_URL.route}/media/:mediaId`
)
export enum SettingPath {
  Places = 'places',
  Shares = 'shares',
  Users = 'users',
}
export const settingsListUrl = r<{ path?: SettingPath }>('/settings/:path?')
