const time = 'h:mm a'

export const DATE_FORMATS = {
  YMD: 'yyyy-MM-dd',
  db: 'yyyy-MM-D HH:mm:ss',
  iso8601: 'yyyy-MM-ddThh:mm:ssZ',
  iso8601WithoutZone: 'yyyy-MM-ddThh:mm:ss',
  time,
  short: 'MMM d',
  shortWithTime: `MMM d ${time}`,
  long: 'MMMM ddd D, yyyy',
  monthYearLong: 'MMMM yyyy',
  longWithTime: `ddd MMMM D, yyyy ${time}`,
}

export enum Errors {
  NO_RESPONSE = 'NO_RESPONSE',
  UNKNOWN = 'UNKNOWN_ERROR',
}

export const GLOBAL_PREFIX = 'api'

export const PASSWORD_MAX_LENGTH = 50

export const PASSWORD_MIN_LENGTH = 6

/** Acceptable Uploads */
export const ACCEPT = 'image/*,video/*'
