import { SSEProvider } from 'react-hooks-sse'
import { hot } from 'react-hot-loader'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { ToastProvider } from 'react-toast-notifications'
import { theme } from 'src/client/assets/theme'
import * as routes from 'src/client/routes'
import {
  AlbumList,
  ForgotPassword,
  Login,
  MediaProvider,
  MediaRedirector,
  PrivateMediaList,
  ResetPassword,
  SettingsList,
} from 'src/client/scenes'
import { eventsUrl } from 'src/server/routes'
import { ThemeProvider } from 'theme-ui'
import { AuthProvider } from './components/hooks/useAuth'
import { ErrorBoundary, PrivateRoute } from './components/primitives'

const AppComponent = () => {
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <AuthProvider>
          <SSEProvider endpoint={eventsUrl()}>
            <ToastProvider placement="bottom-left" autoDismiss={true}>
              <BrowserRouter>
                <MediaProvider>
                  <Switch>
                    <Route exact path={routes.authenticationUrl.route} component={Login} />
                    <Route exact path={routes.forgotPasswordUrl.route} component={ForgotPassword} />
                    <Route exact path={routes.resetPasswordUrl.route} component={ResetPassword} />
                    <PrivateRoute exact path={routes.albumListUrl.route} component={AlbumList} />
                    <Route
                      exact
                      path={[
                        routes.DEPRECATED_SHARE_MEDIA_URL.route,
                        routes.DEPRECATED_SHARE_URL.route,
                      ]}
                      component={MediaRedirector}
                    />
                    <Route
                      exact
                      path={[
                        routes.albumMediaUrl.route,
                        routes.albumUrl.route,
                        routes.mediaUrl.route,
                        routes.trashUrl.route,
                        routes.trashMediaUrl.route,
                        routes.indexUrl,
                      ]}
                      component={PrivateMediaList}
                    />
                    <PrivateRoute path={routes.settingsListUrl.route} component={SettingsList} />
                  </Switch>
                </MediaProvider>
              </BrowserRouter>
            </ToastProvider>
          </SSEProvider>
        </AuthProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export const App = process.env.NODE_ENV === 'production' ? AppComponent : hot(module)(AppComponent)
