// Convert a string like 'image/*,video/*' to a regexp like /(image\/.*)|(video\/.*)/
export const regexFromAccept = (accept: string) => {
  return new RegExp(
    accept
      .split(',')
      .map((c) => `(${c.replace('/', '\\/').replace('*', '.*')})`)
      .join('|')
  )
}

export const filterFiles = (files?: FileList | File[] | null, accept?: string) => {
  if (files?.length) {
    if (accept) {
      const accepted: File[] = []
      const r = regexFromAccept(accept)
      Array.from(files).forEach((file: File) => {
        if (r.test(file.type)) accepted.push(file)
      })
      return accepted
    } else {
      return files
    }
  }
}
