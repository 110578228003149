import React from 'react'
import { Box } from 'theme-ui'

interface LoadingProps {
  visible: boolean
  inline?: boolean
  opacity?: number
  children: React.ReactNode
}

export const Loading = ({ visible, inline: isInline, children, opacity = 0.5 }: LoadingProps) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        top: 0,
        width: '100%',
        height: '100vh',
        background: `rgba(255, 255, 255, ${opacity})`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        visibility: visible ? 'visible' : 'hidden',
        opacity: visible ? 1 : 0,
        transition: 'opacity 300ms ease',
        ...(isInline
          ? {
              position: 'absolute',
              height: '100%',
            }
          : {}),
      }}
    >
      {children}
    </Box>
  )
}
