import { Global } from '@emotion/react'
import normalize from 'polished/lib/mixins/normalize'
import React from 'react'
import ReactDOM from 'react-dom'
import { App } from './App'

const globalStyles = (theme: any) => ({
  ...normalize(),
  html: {
    fontSize: '14px',
  },
  body: {
    fontSize: '14px',
    lineHeight: '1.4285em',
    color: 'rgba(0,0,0,.87)',
    fontSmoothing: 'antialiased',
  },
  a: {
    textDecoration: 'none',
  },
  ...theme,
})

ReactDOM.render(
  <React.Fragment>
    <Global styles={globalStyles} />
    <App />
  </React.Fragment>,
  document.getElementById('root')
)
