import { Form, Formik, FormikHelpers } from 'formik'
import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import * as api from 'src/client/api'
import { Dialog, DialogProps } from 'src/client/components/blocks/Dialog'
import {
  Error,
  Field,
  List,
  ListContent,
  ListIcon,
  ListItem,
} from 'src/client/components/primitives'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { VariationName } from 'src/server/media/types'
import { mediaStreamUrl } from 'src/server/routes'
import { Box, Button, Heading, Image } from 'theme-ui'

interface AddToAlbumFormProps {
  mediaIds?: Set<string>
  onSuccess: (album: SerializedAlbum) => void
}

const initialValues = {
  name: '',
}

type FormValues = typeof initialValues

export const AddToAlbumForm = ({ onSuccess, mediaIds }: AddToAlbumFormProps) => {
  const [albums, setAlbums] = useState<SerializedAlbum[]>([])
  const { addToast } = useToasts()

  const onCreate = async ({ name }: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
    if (!mediaIds || mediaIds.size === 0) return
    setSubmitting(true)
    const { data } = await api.albums.create({ name, mediaId: Array.from(mediaIds) })
    onSuccess(data)
    addToast(`Added to ${name}`, { appearance: 'success' })
    setSubmitting(false)
  }

  const onUpdate = async (album: SerializedAlbum) => {
    if (!mediaIds || mediaIds.size === 0) return
    const { data } = await api.albums.update({ id: album.id }, { mediaId: Array.from(mediaIds) })
    onSuccess(data)
    addToast(`Added to ${album.name}`, { appearance: 'success' })
  }

  useEffect(() => {
    const fetchAlbums = async () => {
      try {
        const { data } = await api.albums.list({ canCreate: true })
        setAlbums(data)
      } catch (e) {
        setAlbums([])
      }
    }
    fetchAlbums()
  }, [])

  return (
    <Box>
      <Formik initialValues={initialValues} onSubmit={onCreate}>
        {({ isSubmitting }) => (
          <Box sx={{ border: '1px solid', borderColor: 'muted', borderRadius: 4, p: 4 }}>
            <Form>
              <Box mb={3}>
                <Field type="name" name="name" placeholder="New Album" />
                <Error name="name" />
              </Box>
              <Button type="submit" disabled={isSubmitting}>
                Create
              </Button>
            </Form>
          </Box>
        )}
      </Formik>
      <Box sx={{ border: '1px solid', borderColor: 'muted', borderRadius: 4, p: 4 }}>
        <List>
          {albums.map((album) => {
            const updateAlbum = async () => {
              onUpdate(album)
            }
            return (
              <ListItem key={album.id}>
                <ListIcon>
                  <Image
                    src={
                      album.cover
                        ? mediaStreamUrl({ id: album.cover.id, variation: VariationName.Album })
                        : undefined
                    }
                  />
                </ListIcon>
                <ListContent>
                  <Heading as="a" onClick={updateAlbum}>
                    {album.name}
                  </Heading>
                </ListContent>
              </ListItem>
            )
          })}
        </List>
      </Box>
    </Box>
  )
}

type AddToAlbumDialogProps = AddToAlbumFormProps & Omit<DialogProps, 'children'>

export const AddToAlbumDialog = ({
  isOpen,
  onClose,
  onSuccess,
  mediaIds,
  backdropCloses = true,
}: AddToAlbumDialogProps) => (
  <Dialog isOpen={isOpen} onClose={onClose} backdropCloses={backdropCloses} title="Add to">
    <AddToAlbumForm mediaIds={mediaIds} onSuccess={onSuccess} />
  </Dialog>
)
