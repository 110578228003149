import { Message } from 'src/server/message/message.entity'
import { Id, messageListUrl, messageUrl, reactionUrl } from 'src/server/routes'
import { del, get, post, put } from './api'

export interface UpdateMessageParams {
  content: string
  reaction?: number
}

export interface ReactionParams {
  mediaId: string
  albumId: string
}

export interface CreateMessageParams extends UpdateMessageParams {
  media?: string
  album: string
}

export interface ListMessageParams {
  album: string
  media?: string
  key?: string | null
}

export const messages = {
  list: async (params: ListMessageParams) => await get<Message[]>(messageListUrl(), { params }),
  reaction: async (params: ReactionParams) => await get<Message>(reactionUrl(params)),
  create: async (data: CreateMessageParams) => await post<Message>(messageListUrl(), data),
  update: async (params: Id, data: UpdateMessageParams) =>
    await put<Message>(messageUrl(params), data),
  destroy: async (params: Id) => await del(messageUrl(params)),
}
