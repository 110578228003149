
import format from 'date-fns/format'
import React from 'react'
import { CheckCircle } from 'src/client/assets/images'
import { colors } from 'src/client/assets/theme'
import { DATE_FORMATS } from 'src/shared/constants'
import { Flex, Heading } from 'theme-ui'

interface GalleryHeadingProps {
  date: string
  selected: boolean
  onClick?: (e: React.MouseEvent<HTMLElement>, selected: boolean) => void
}

const onHover = {
  h3: {
    transform: 'translateX(24px)',
  },
  div: {
    opacity: 1,
  },
}

export const GalleryHeading = ({ date, onClick, selected }: GalleryHeadingProps) => {
  const onSelect = (e: React.MouseEvent<HTMLElement>) => {
    onClick?.(e, !selected)
  }

  return (
    <Flex
      m={0}
      onClick={onSelect}
      sx={{
        position: 'absolute',
        alignItems: 'center',
        left: '0',
        lineHeight: '48px',
        height: '48px',
        cursor: 'pointer',
        '&:hover': onHover,
        ...(selected ? onHover : {}),
      }}
    >
      <Flex
        pl={3}
        sx={{
          alignItems: 'center',
          opacity: 0,
          left: 0,
          transition: 'opacity linear 0.15s',
          transitionDelay: '0.15s',
          height: '100%',
          position: 'absolute',
          lineHeight: ' 48px',
        }}
      >
        <CheckCircle fill={selected ? colors.blue[5] : colors.gray[5]} />
      </Flex>
      <Heading
        as="h3"
        px={3}
        sx={{
          textIndent: '5px',
          transition: 'transform 0.135s cubic-bezier(0, 0, 0.2, 1) 0.15s, opacity linear 0.15s',
          position: 'relative',
        }}
      >
        {format(new Date(date), DATE_FORMATS.monthYearLong)}
      </Heading>
    </Flex>
  )
}
