import React from 'react'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { UpdateMediaDto } from 'src/server/media/dto/update-media.dto'
import { SerializedMedia } from 'src/server/media/media.entity'
import { Message } from 'src/server/message/message.entity'
import { Box, Flex, Heading } from 'theme-ui'
import { MediaInfo } from './MediaInfo'
import { MediaMessages } from './MediaMessages'

export const sidebarWidth = 320

const { useRef } = React

interface SidebarProps {
  sidebarOpen: boolean
  activityOpen: boolean
  album?: SerializedAlbum
  loading?: boolean
  media: SerializedMedia | null
  style?: any // TODO Should automatically apply styles from transition group
  messages: Message[]
  loadMessages: (albumId: string, mediaId: string) => Promise<void>
  createMessage: (albumId: string, mediaId: string, content: string) => Promise<void>
  destroyMessage: (id: number, idx: number) => Promise<void>
  updateMedia: (data: UpdateMediaDto) => Promise<SerializedMedia>
}

export const Sidebar = ({
  album,
  media,
  sidebarOpen,
  activityOpen,
  updateMedia: onUpdate,
  messages,
  loading,
  loadMessages: onLoad,
  createMessage: onCreate,
  destroyMessage: onDestroy,
  style,
}: SidebarProps) => {
  const props = { album, media, onUpdate, sidebarOpen }
  const container = useRef<HTMLDivElement>(undefined!)
  const onScroll = () => {
    const { current } = container
    if (!current) return
    current.scrollTop = current.scrollHeight
  }
  return (
    // TODO Fix the inline styles
    <Box
      as="aside"
      ref={container}
      style={{
        ...style,
        width: `${sidebarWidth}px`,
        transform: `translateX(${sidebarOpen ? 0 : sidebarWidth}px)`,
        backgroundColor: '#fff',
        height: '100%',
        position: 'fixed',
        top: 0,
        right: 0,
        overflowY: 'auto',
        zIndex: 1200,
      }}
    >
      <Flex sx={{ flexDirection: 'column', height: '100%' }}>
        <Box px={3} pt={3} as="header" mb={4}>
          <Heading as="h2">{activityOpen ? 'Activity' : 'Info'}</Heading>
        </Box>
        {activityOpen && album ? (
          <MediaMessages
            loading={loading}
            messages={messages}
            onLoad={onLoad}
            onCreate={onCreate}
            onDestroy={onDestroy}
            onScroll={onScroll}
            album={album}
            media={media}
            sx={{ flex: 1 }}
          />
        ) : (
          <MediaInfo {...props} />
        )}
      </Flex>
    </Box>
  )
}
