import { MenuItem } from '@szhsin/react-menu'
import copy from 'copy-to-clipboard'
import { truncate } from 'lodash-es'
import React from 'react'
import { LinkAlt, Trash, User } from 'src/client/assets/images'
import { SharePermission } from 'src/client/components/hooks/useAuth'
import {
  ListContent,
  ListItem,
  Menu,
  MenuDivider,
} from 'src/client/components/primitives'
import { DEPRECATED_SHARE_URL } from 'src/client/routes'
import { Share } from 'src/server/share/share.entity'
import { permalink } from 'src/shared/util'
import { Box, Button, Checkbox, Label, Link } from 'theme-ui'

const permToHuman = (str: string) => str.toLowerCase().replace(/^can/, '')

export interface DropdownItemProps {
  label: string
  value: string
  checked: boolean
  onChange: (perm: string, checked: boolean) => void
}

export const menuCheckbox = ({ checked, onChange, label, value }: DropdownItemProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    onChange(e.target.value, e.target.checked)

  return (
    <MenuItem>
      <Label
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          userSelect: 'none',
        }}
      >
        <Checkbox onChange={handleChange} value={value} defaultChecked={checked} />
        {label}
      </Label>
    </MenuItem>
  )
}

interface Props {
  share: Share
  onDelete: (data: any) => void
  onChange: (perm: string, checked: boolean) => void
}

export const ShareControls: React.FunctionComponent<Props> = ({
  share,
  onDelete,
  onChange,
}: Props) => {
  const url = share.key ? permalink(location, DEPRECATED_SHARE_URL({ shareKey: share.key })) : ''
  const copyLink = () => copy(url)
  const perms = Object.keys(SharePermission)
    .filter((k) => share[SharePermission[k]])
    .map((p) => permToHuman(p))
  const iconProps = {
    sx: {
      fill: 'secondary',
    },
  }
  return (
    <ListItem>
      <Box sx={{ display: 'inline-block' }}>
        {share.participant ? <User {...iconProps} /> : <LinkAlt {...iconProps} />}
      </Box>
      <ListContent sx={{ display: 'inline-block', width: 'auto', ml: 2 }}>
        <Menu
          onClick={(e) => (e.keepOpen = true)}
          icon={share.participant ? share.participant.email : 'Public Share Link'}
        >
          {share.participant &&
            menuCheckbox({
              label: 'Can Edit',
              onChange,
              value: SharePermission.CanEdit,
              checked: !!share.canEdit,
            })}
          {share.participant &&
            menuCheckbox({
              label: 'Can Delete',
              onChange,
              value: SharePermission.CanDelete,
              checked: !!share.canDelete,
            })}
          {share.participant &&
            menuCheckbox({
              label: 'Can Share',
              onChange,
              value: SharePermission.CanShare,
              checked: !!share.canShare,
            })}
          {share.participant &&
            menuCheckbox({
              label: 'Can Upload',
              onChange,
              value: SharePermission.CanCreate,
              checked: !!share.canCreate,
            })}
          {menuCheckbox({
            label: 'Can Signup',
            onChange,
            value: SharePermission.CanSignup,
            checked: !!share.canSignup,
          })}
          <MenuDivider />
          <MenuItem onClick={onDelete}>
            <Box mr={2}>
              <Trash sx={{ fill: 'secondary' }} />
            </Box>
            <Box>Unshare</Box>
          </MenuItem>
        </Menu>
        <Box>
          {perms.length ? perms.join(', ') : '—'}
          {share.key && (
            <Box>
              <Link mr={2} href={url}>
                {truncate(url, { length: 32 })}
              </Link>
              <Button sx={{ fontSize: '0.7rem' }} p={1} onClick={copyLink}>
                Copy
              </Button>
            </Box>
          )}
        </Box>
      </ListContent>
    </ListItem>
  )
}
