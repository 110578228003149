import { Form, Formik } from 'formik'
import React from 'react'
import Select from 'react-select'
import * as api from 'src/client/api'
import { Dialog, DialogProps } from 'src/client/components/blocks/Dialog'
import { UpdateMediaDto } from 'src/server/media/dto'
import { SerializedMedia } from 'src/server/media/media.entity'
import { Place } from 'src/server/place/place.entity'
import { Box, Button } from 'theme-ui'

interface PlaceFormProps {
  media: SerializedMedia | null
  onSubmit: (data: UpdateMediaDto) => Promise<SerializedMedia>
}

interface FormValues {
  placeId?: number
}

const { useEffect, useState } = React

export const PlaceForm = ({ onSubmit: onSave, media }: PlaceFormProps) => {
  const [places, setPlaces] = useState<Place[]>([])
  const [isFetching, setIsFetching] = useState(false)
  const options = places.map((p) => ({ label: p.label, value: p.id }))
  const init = { placeId: media?.place?.id }
  const onSubmit = async ({ placeId }: FormValues) => {
    onSave({ place: placeId })
  }

  useEffect(() => {
    setIsFetching(true)
    const fetchPlaces = async () => {
      try {
        const { data } = await api.places.list()
        setPlaces(data)
      } catch (e) {
        console.log(e)
      } finally {
        setIsFetching(false)
      }
    }
    fetchPlaces()
  }, [])

  return (
    <Formik initialValues={init} onSubmit={onSubmit}>
      {({ isSubmitting, setFieldValue, values }) => (
        <Box sx={{ border: '1px solid', borderColor: 'muted', borderRadius: 4, p: 4 }}>
          <Form>
            <Box mb={3}>
              <Select
                name="placeId"
                placeholder="Select Place"
                onChange={(option) => setFieldValue('placeId', option?.value)}
                isDisabled={isSubmitting}
                isLoading={isFetching}
                value={options.find((o) => o.value === values.placeId)}
                options={options}
              />
            </Box>
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Form>
        </Box>
      )}
    </Formik>
  )
}

type PlaceDialogProps = Omit<DialogProps, 'children'> & PlaceFormProps

export const PlaceDialog = ({
  isOpen,
  onClose,
  backdropCloses = true,
  ...rest
}: PlaceDialogProps) => (
  <Dialog isOpen={isOpen} onClose={onClose} backdropCloses={backdropCloses} title="Edit location">
    <PlaceForm {...rest} />
  </Dialog>
)
