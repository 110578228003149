import parseISO from 'date-fns/parseISO'
import { match as Match, matchPath } from 'react-router-dom'
import {
  albumMediaUrl,
  mediaUrl,
  trashMediaUrl,
  trashUrl,
} from 'src/client/routes'
import { round } from 'src/client/util'
import { SerializedMedia } from 'src/server/media/media.entity'
import {
  VariationName,
  Variations,
  VariationType,
} from 'src/server/media/types'
import { mediaStreamUrl, QueryParams } from 'src/server/routes'
import { OrientationTypes } from 'src/shared/types'
import { MediaItem } from './types'

/** Generate an URL depending on whether a share key is present */
export const urlFor = (match: Match<any>, media: { id: string }) => {
  const {
    path,
    params: { id },
  } = match
  const { id: mediaId } = media
  const key = shareKey()
  const trashed = matchPath(path, trashUrl.route)
  if (trashed) {
    return trashMediaUrl({ id: mediaId })
  } else {
    return id ? albumMediaUrl({ id, mediaId }, key ? { key: key } : {}) : mediaUrl({ mediaId })
  }
}

// TODO Figure out how to match anywhere in the path without the star prefix
export const isMediaDetailUrl = (url: string) =>
  matchPath<{ mediaId: string }>(url, { path: `*${mediaUrl.route}` })

/** Pull the share key out of the current url */
export const shareKey = () => new URLSearchParams(location.search).get('key')

/** Convert SerializedMedia into a format the gallery uses */
export const prepareMedia = (data: SerializedMedia[], query?: { key: string }): MediaItem[] => {
  return data.map((m) => prepareMediaItem(m, query))
}

/** Convert a single SerializedMedia into a format the gallery uses */
export const prepareMediaItem = (media: SerializedMedia, query?: QueryParams): MediaItem => {
  const { id, filename, width, height, orientation, contentType } = media
  // Rotate thumbs based on EXIF data
  const displayWidth = orientation && orientation > OrientationTypes.BOTTOM_LEFT ? height : width
  const displayHeight = orientation && orientation > OrientationTypes.BOTTOM_LEFT ? width : height
  const ratio = displayHeight > displayWidth ? displayWidth / displayHeight : 1
  // Prepare media for gallery layout
  // All calculated properties excluding display dimensions / position can be calculated here.
  return {
    // Correct dimensions based on aspect ratio
    media: { ...media, width: displayWidth, height: displayHeight },
    key: id,
    title: filename,
    width: displayWidth,
    height: displayHeight,
    index: 0,
    date: media.takenAt ? parseISO(media.takenAt) : new Date(0),
    top: 0,
    left: 0,
    contentType,
    preview: mediaStreamUrl({ id, variation: VariationName.Preview }, query),
    // Thumbs are generated based on the longest side
    // https://stackoverflow.com/questions/35379385/is-it-compulsory-to-use-the-sizes-attribute-with-w-descriptors-in-srcset
    srcSet: Variations.filter((v) => v.type === VariationType.Thumb).map(
      (v) => `${mediaStreamUrl({ id, variation: v.name }, query)} ${round(v.width * ratio)}w`
    ),
  }
}
