import { Id, shareListUrl, shareUrl } from 'src/server/routes'
import { CreateShareDto, ListShareDto } from 'src/server/share/dto'
import { Share } from 'src/server/share/share.entity'
import { del, get, post, put } from './api'

export interface ListSharesParams extends Omit<ListShareDto, 'album'> {
  album: string
}

export interface CreateShareParams extends Omit<CreateShareDto, 'album'> {
  album: string
}

export const shares = {
  list: async (params: ListSharesParams) => await get<Share[]>(shareListUrl(), { params }),
  create: async (data: CreateShareParams) => await post<Share>(shareListUrl(), data),
  update: async (params: Id, data: CreateShareParams) => await put<Share>(shareUrl(params), data),
  destroy: async (params: Id) => await del(shareUrl(params)),
}
