
import { DeadBirb } from 'src/client/assets/images'
import { Box, Button, Flex, Heading } from 'theme-ui'
import { FallbackProps } from './ErrorBoundary'

interface CatastrophicErrorProps extends FallbackProps {
  title?: string
}

export const CatastrophicError = ({
  title = 'Something went wrong',
  error,
  componentStack,
  resetErrorBoundary,
}: CatastrophicErrorProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <DeadBirb sx={{ width: 200, height: 200 }} />
      <Heading as="h1" sx={{ textAlign: 'center' }}>
        {title}
      </Heading>
      <Flex
        sx={{
          position: 'relative',
          maxWidth: '60vw',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {error && (
          <Box
            as="pre"
            mt={4}
            sx={{
              borderLeft: '3px solid #d9534f',
              padding: '0.5rem',
              backgroundColor: 'rgba(217, 83, 79, 0.1)',
              whiteSpace: 'pre-wrap',
            }}
          >
            {error.message}
          </Box>
        )}
        {process.env.NODE_ENV !== 'production' && (
          <Box as="pre" style={{ fontSize: 12 }}>
            {componentStack}
          </Box>
        )}
        <Button style={{ marginTop: '2rem' }} onClick={resetErrorBoundary}>
          Reload
        </Button>
      </Flex>
    </Box>
  )
}
