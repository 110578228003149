import { MenuItem } from '@szhsin/react-menu'
import { format } from 'date-fns'
import { parseISO } from 'date-fns/esm/fp'
import React from 'react'
import { DotsHorizontalRounded, Puff, Trash } from 'src/client/assets/images'
import { useAuth } from 'src/client/components/hooks/useAuth'
import {
  CommendBody,
  Comment,
  CommentAuthor,
  CommentGroup,
  CommentHeader,
  CommentMetadata,
  Loading,
  Menu,
} from 'src/client/components/primitives'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { SerializedMedia } from 'src/server/media/media.entity'
import { Message } from 'src/server/message/message.entity'
import { DATE_FORMATS } from 'src/shared/constants'
import { Box, Button, Flex } from 'theme-ui'
import { useMediaContext } from '../../MediaContext'
import { MessageForm } from './MessageForm'

const { useState, useEffect } = React

const formatContent = (message: Message) => {
  const { creator, reaction, content } = message
  switch (reaction) {
    case 1:
      return `❤️ ${creator.name || creator.email} liked this`
    default:
      return content
  }
}

export interface MediaActivityProps {
  album: SerializedAlbum
  media: SerializedMedia | null
  messages: Message[]
  loading?: boolean
  onLoad: (albumId: string, mediaId: string) => Promise<void>
  onCreate: (albumId: string, mediaId: string, content: string) => Promise<void>
  onDestroy: (id: number, idx: number) => Promise<void>
  onScroll: () => void
}

const Messages = ({
  onDestroy,
  messages,
  user,
}: {
  user: { id: number } | null
  messages: Message[]
  onDestroy: MediaActivityProps['onDestroy']
}) => (
  <CommentGroup>
    {messages.map((m, i) => {
      const onClick = () => {
        onDestroy(m.id, i)
      }
      return (
        <Comment key={m.id}>
          <CommentHeader>
            <CommentAuthor>
              {m.creator.name || m.creator.email}
              <time>{format(parseISO(m.createdAt.toString()), DATE_FORMATS.shortWithTime)}</time>
            </CommentAuthor>
            <CommentMetadata>
              {/* TODO should use the canDelete function here */}
              {m.creator.id === user?.id && (
                <Menu icon={<DotsHorizontalRounded sx={{ fill: 'secondary' }} />}>
                  <MenuItem onClick={onClick}>
                    <Box mr={2}>
                      <Trash sx={{ fill: 'secondary' }} />
                    </Box>
                    <Box>Delete</Box>
                  </MenuItem>
                </Menu>
              )}
            </CommentMetadata>
          </CommentHeader>
          <CommendBody>{formatContent(m)}</CommendBody>
        </Comment>
      )
    })}
  </CommentGroup>
)

export const MediaMessages = ({
  album,
  media,
  messages,
  onScroll,
  loading,
  onLoad,
  onCreate,
  onDestroy,
}: MediaActivityProps) => {
  const { authDialog } = useMediaContext()
  const { user, authenticated, canSignup } = useAuth()
  const [messageCount, setMessageCount] = useState(0)

  useEffect(() => {
    const fetchMessages = async (mediaId: string) => {
      onLoad(album.id, mediaId)
    }
    if (media) fetchMessages(media.id)
  }, [album?.id, media?.id])

  useEffect(() => {
    if (messages.length > messageCount) onScroll()
    setMessageCount(messages.length)
  }, [messageCount, messages.length])

  return (
    <Flex
      sx={{
        height: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box px={3}>
        {!loading && messages.length === 0 ? (
          <Flex sx={{ alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            No activity yet
          </Flex>
        ) : (
          <Messages user={user} messages={messages} onDestroy={onDestroy} />
        )}
        <Loading visible={!!loading} opacity={0.7}>
          <Puff fill="rgba(0,0,0,0.1)" />
        </Loading>
      </Box>
      <Box
        px={3}
        pt={3}
        pb={1}
        sx={{
          borderTop: '1px solid',
          borderTopColor: 'gray.2',
          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white',
        }}
      >
        {media && authenticated ? (
          <MessageForm media={media} album={album} onCreate={onCreate} />
        ) : canSignup(album) ? (
          <Button variant="text" onClick={() => authDialog(true)}>
            Create Account to Comment
          </Button>
        ) : null}
      </Box>
    </Flex>
  )
}
