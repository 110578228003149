import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Dialog, DialogProps } from 'src/client/components/blocks/Dialog'
import { albumUrl } from 'src/client/routes'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { Box, Button, Flex, Text } from 'theme-ui'
import { LoginComponent } from './Login'
import { Signup } from './Signup'

interface AuthenticationDialogProps extends Omit<DialogProps, 'children'> {
  album: SerializedAlbum | null
}

export const AuthenticationDialog = ({
  album,
  isOpen,
  onClose,
  backdropCloses = true,
}: AuthenticationDialogProps) => {
  const { shareKey } = useParams<{ shareKey?: string }>()
  const history = useHistory()
  const { addToast } = useToasts()
  const [newUser, setNewUser] = useState(true)
  const toggleState = () => setNewUser(!newUser)
  const onSuccess = () => {
    // Replace share path with authenticated album path if creating an account for the first time
    if (newUser && shareKey && album) {
      history.replace(albumUrl({ id: album.id }))
    }
    addToast(newUser ? 'Account Created' : 'Signed In', { appearance: 'success' })
    onClose?.()
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      backdropCloses={backdropCloses}
      title={newUser ? 'Sign Up' : 'Sign In'}
    >
      <Box>
        {newUser ? <Signup onSuccess={onSuccess} /> : <LoginComponent onSuccess={onSuccess} />}
      </Box>
      <Flex as="footer" sx={{ alignItems: 'center', justifyContent: 'center' }}>
        <Flex p={2}>
          <Text mr={2}>{newUser ? 'Have an Account?' : 'Need an Account?'}</Text>
          <Button variant="text" onClick={toggleState}>
            {newUser ? 'Sign In' : 'Sign Up'}
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  )
}
