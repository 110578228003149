import React from 'react'
import { Send } from 'src/client/assets/images'
import { colors } from 'src/client/assets/theme'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { SerializedMedia } from 'src/server/media/media.entity'
import { Box, Button, Flex } from 'theme-ui'

const { useState, useEffect, useRef } = React

const focusStyle = {
  borderBottomColor: '#1a9af5',
}

interface Props {
  album: SerializedAlbum
  media: SerializedMedia
  onCreate: (albumId: string, mediaId: string, content: string) => Promise<void>
}

export const MessageForm = ({ onCreate, album, media }: Props) => {
  const input = useRef<HTMLDivElement>()
  const [submitting, setSubmitting] = useState(false)
  const [focused, setFocused] = useState(false)
  const [valid, setValid] = useState(false)
  const mediaId = useRef(media.id)
  const onFocus = () => {
    setFocused(true)
    if (input.current && !document.activeElement?.isSameNode(input.current)) {
      input.current.focus()
    }
  }
  const onKeyDown = (event: KeyboardEvent) => {
    if ((input.current?.innerText || '').length && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      onSubmit()
    }
  }
  const onBlur = () => setFocused(false)
  const onInput = (e: React.FormEvent<HTMLDivElement>) => {
    const txt = e.currentTarget.innerText
    if (txt.length === 0 && valid) return setValid(false)
    if (txt.length > 0 && !valid) setValid(true)
  }
  const onSubmit = async () => {
    if (!input.current || input.current.innerText.trim() === '') return

    const content = input.current.innerText
    setSubmitting(true)
    await onCreate(album.id, mediaId.current, content)
    setSubmitting(false)
    setValid(false)
    input.current.innerText = ''
  }

  useEffect(() => {
    if (input.current) input.current.innerHTML = ''
    mediaId.current = media.id
  }, [media?.id])

  useEffect(() => {
    input.current?.addEventListener('keydown', onKeyDown)
    return () => input.current?.removeEventListener('keydown', onKeyDown)
  }, [])

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          pointerEvents: 'none',
          minHeight: '22px',
          borderRadius: '2px',
          borderBottom: '1px solid',
          borderBottomColor: 'gray.5',
          ...(focused ? focusStyle : {}),
        }}
        onClick={onFocus}
      >
        <Box
          contentEditable
          suppressContentEditableWarning
          onInput={onInput}
          onFocus={onFocus}
          onBlur={onBlur}
          ref={input}
          sx={{
            display: 'inline',
            outline: 0,
            borderRadius: '2px',
            cursor: 'auto',
            pointerEvents: 'all',
            pl: '1px',
            minWidth: '100px',
            '&:empty': {
              display: 'inline-block',
            },
            '&:empty:after': {
              content: '"Type a message..."',
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
            },
          }}
        />
      </Box>
      <Flex
        pt={1}
        sx={{
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <Button
          p={0}
          bg="transparent"
          variant="primary"
          sx={{
            width: 'auto',
            lineHeight: 1,
            '&:disabled': {
              cursor: 'default',
              '&:hover': {
                bg: 'transparent',
              },
            },
            svg: {
              fill: valid ? colors.gray[7] : colors.gray[5],
              '&:hover': {
                fill: valid ? colors.white : colors.gray[5],
              },
            },
          }}
          onClick={onSubmit}
          type="submit"
          disabled={submitting || !valid}
        >
          <Send />
        </Button>
      </Flex>
    </Box>
  )
}
