import cn from 'classnames'
import { useEmblaCarousel } from 'embla-carousel/react'
import React from 'react'
import { Puff } from 'src/client/assets/images'
import { CubeGrid } from 'src/client/components/primitives'
import { isTouch, isVideo } from 'src/client/util'
import { isInteracting } from 'src/client/util/info'
import { SerializedMedia } from 'src/server/media/media.entity'
import { VariationName } from 'src/server/media/types'
import { mediaStreamUrl } from 'src/server/routes'
import { Box, Flex, Image } from 'theme-ui'
import { useQuery } from '../../hooks/useQuery'
import { Navigation } from './Navigation'

const { memo, useEffect, useImperativeHandle, forwardRef } = React

/* eslint-disable  no-bitwise */
const rotate = <T,>(arr: T[], count: number) => {
  const ret = arr.slice(0)
  const len = ret.length >>> 0
  count = count >> 0
  Array.prototype.unshift.apply(ret, Array.prototype.splice.call(ret, count % len, len))
  return ret
}

const viewWindow = (absoluteIndex: number, windowSize: number, views: any[]) => {
  windowSize = Math.min(windowSize, views.length) // inclusive of current Index
  const toFill = windowSize - 1
  const afterCount = toFill % 2 === 0 ? toFill / 2 : (toFill + 1) / 2
  const beforeCount = toFill - afterCount
  let start = absoluteIndex - beforeCount
  if (start < 0) start = views.length + start
  const windowed = []
  for (let i = 0; i < windowSize; i++) {
    windowed.push(views[(start + i) % views.length])
  }
  return windowed
}

const clamp = (n: number, total: number) => (n < 0 ? total - 1 : n >= total ? 0 : n)

const replaceMedia = (recycle: number, data: SerializedMedia) => {
  const node = document.querySelector(`.index-${recycle}`) as HTMLDivElement
  let child: any = node.childNodes[0] // HTMLImageElement | HTMLVideoElement
  let tmp: any
  // const data = views[recycleData]
  if (isVideo(data.contentType)) {
    tmp = document.createElement('video')
    Object.assign(tmp, {
      controls: true,
      preload: 'metadata',
      poster: mediaStreamUrl({ id: data.id, variation: VariationName.Full }),
    })
    node.replaceChild(tmp, child)
    child = tmp
  } else if (child.tagName !== 'IMG') {
    tmp = document.createElement('img')
    node.replaceChild(tmp, child)
    child = tmp
  }
  child.src = mediaStreamUrl({
    id: data.id,
    variation: isVideo(data.contentType) ? VariationName.FullVideo : VariationName.Full,
  })
}

const pauseVideo = (node?: HTMLElement) => {
  const child = node?.childNodes[0] as HTMLVideoElement
  if (child && child.tagName === 'VIDEO') {
    child.pause()
  }
}

interface ViewPagerProps {
  windowSize: number
  views: SerializedMedia[]
  initialIndex: number
  isFullscreen: boolean
  interactionIsIdle?: boolean
  enabledKeyboardNavigation?: boolean
  showNavigationOnTouchDevice?: boolean
  onIndexChanged?: (idx: number) => void
}

const nextKeys = ['l', 'ArrowRight', 'j', 'ArrowDown']
const prevKeys = ['h', 'ArrowLeft', 'k', 'ArrowUp']
const allKeys = nextKeys.concat(prevKeys)

export const ViewPager = memo(
  forwardRef(
    (
      {
        windowSize,
        onIndexChanged,
        views,
        initialIndex,
        enabledKeyboardNavigation = true,
        interactionIsIdle = false,
        showNavigationOnTouchDevice = false,
      }: ViewPagerProps,
      ref
    ) => {
      const viewData = viewWindow(initialIndex, Math.min(windowSize, views.length), views)
      const center = Math.floor((viewData.length - 1) / 2)
      const initialOrder = [...Array(viewData.length)].fill(true).map((_, i) => i)
      const [emblaRef, embla] = useEmblaCarousel({ loop: true, startIndex: center })
      const key = useQuery().get('key')
      const shareKey = key ? { key } : undefined
      const bckBuffer = initialOrder.slice(0, center).length
      const fwdBuffer = initialOrder.slice(center + 1).length
      let index = initialIndex
      let order = initialOrder.slice(0)

      const paginate = (last: number, cur: number) => {
        const fwd = cur - last === 1 || cur - last === -(order.length - 1)
        const inc = fwd ? 1 : -1
        const newIndex = clamp(index + inc, views.length)
        const newOrder = rotate(order, inc)
        const recycle = fwd ? newOrder[order.length - 1] : newOrder[0]
        let recycleData = newIndex + (fwd ? fwdBuffer : -bckBuffer)
        if (recycleData > views.length - 1) recycleData = recycleData - views.length
        if (recycleData < 0) recycleData = views.length + recycleData
        pauseVideo(embla?.slideNodes()[last])
        replaceMedia(recycle, views[recycleData])
        order = newOrder
        index = newIndex
        onIndexChanged?.(newIndex)
      }
      const update = () => embla && paginate(embla.previousScrollSnap(), embla.selectedScrollSnap())
      const next = () => embla?.scrollNext()
      const prev = () => embla?.scrollPrev()

      useImperativeHandle(ref, () => ({ next, embla }))

      useEffect(() => {
        embla?.on('select', update)
        if (!enabledKeyboardNavigation) return
        const onKeyUp = (event: KeyboardEvent) => {
          if (isInteracting()) return
          if (nextKeys.includes(event.key)) next()
          else if (prevKeys.includes(event.key)) prev()
        }
        const onKeyDown = (event: KeyboardEvent) => {
          if (isInteracting()) return
          if (allKeys.includes(event.key)) event.preventDefault()
        }
        document.addEventListener('keyup', onKeyUp, { passive: true })
        document.addEventListener('keydown', onKeyDown, { passive: true })
        return () => {
          embla?.off('select', update)
          document.removeEventListener('keyup', onKeyUp)
          document.removeEventListener('keydown', onKeyDown)
        }
      }, [embla, views.length])

      useEffect(() => {
        embla?.reInit({ loop: true, startIndex: center })
        const selected = embla?.selectedScrollSnap()
        if (selected !== undefined) onIndexChanged?.(selected)
      }, [views.length])

      return (
        <main
          style={{
            flex: '1 1 auto',
            position: 'relative',
          }}
        >
          <Box ref={emblaRef} sx={{ overflow: 'hidden' }}>
            <Flex>
              {views &&
                viewData.map((data: SerializedMedia, idx: number) => {
                  return (
                    <Flex
                      key={idx}
                      sx={{
                        flex: '1 0 100%',
                        position: 'relative',
                        alignItems: 'center',
                        justifyContent: 'center',
                        'img,video': {
                          height: 'auto',
                          // TODO Don't hardcore
                          maxHeight: 'calc(100vh - 140px)',
                          maxWidth: ['90%', '100%'],
                          userSelect: 'none',
                          '&:focus': {
                            outline: 'none',
                          },
                        },
                      }}
                      className={cn('backdrop', `index-${idx}`)}
                    >
                      {isVideo(data.contentType) ? (
                        <video
                          controls
                          preload="meta"
                          src={mediaStreamUrl(
                            { id: data.id, variation: VariationName.FullVideo },
                            shareKey
                          )}
                          poster={mediaStreamUrl(
                            { id: data.id, variation: VariationName.Full },
                            shareKey
                          )}
                        />
                      ) : (
                        <Image
                          src={mediaStreamUrl(
                            { id: data.id, variation: VariationName.Full },
                            shareKey
                          )}
                        />
                      )}
                      <div
                        style={{
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: -1,
                        }}
                      >
                        <Puff fill={'rgba(255,255,255,0.5)'} />
                      </div>
                    </Flex>
                  )
                })}
            </Flex>
          </Box>
          {(!isTouch() || showNavigationOnTouchDevice) && (
            <Navigation
              interactionIsIdle={interactionIsIdle}
              currentIndex={index}
              views={views}
              next={next}
              prev={prev}
            />
          )}
        </main>
      )
    }
  ),
  (prevProps, nextProps) => {
    return (
      prevProps.views.length === nextProps.views.length ||
      prevProps.initialIndex !== nextProps.initialIndex
    )
  }
)

ViewPager.displayName = 'ViewPager'
