import {
  ErrorMessage,
  Field as FormikField,
  FieldProps as FormikFieldProps,
} from 'formik'
import { forwardRef } from 'react'
import { Box, Input, InputProps, Select, Text, Textarea } from 'theme-ui'

// field, form and meta are passed automatically - unsure this is the best way to type it
// export type FieldProps = Omit<FormikFieldProps, 'field' | 'form' | 'meta'> & Omit<InputProps, 'ref'>
export type FieldProps = Omit<FormikFieldProps, 'field' | 'form' | 'meta'> &
  Omit<InputProps, 'ref'> & {
    ref?: React.Ref<HTMLInputElement>
    as?: 'input' | 'select' | 'textarea'
  }

// TODO Fix types
export const Field = forwardRef((props: FieldProps, ref) => {
  const { name, as, type, mb, mt, ml, mr, mx, my, pt, pb, pr, pl, px, py, ...rest } = props
  const sx = { mb, mt, ml, mr, mx, my, pt, pb, pr, pl, px, py }
  const innerProps = { ...rest, ref }

  return (
    <Box variant="field" sx={sx}>
      <FormikField name={name}>
        {({
          field, // { name, value, onChange, onBlur }
          meta,
        }: FormikFieldProps) => {
          switch (as) {
            case 'select':
              return (
                <Select {...innerProps} {...field}>
                  {props.children}
                </Select>
              )
            case 'textarea':
              return <Textarea {...innerProps} {...field} />
            default:
              return <Input type={type} {...innerProps} {...field} />
          }
        }}
      </FormikField>
      <ErrorMessage name={name || ''}>
        {(msg) => (
          <Text
            sx={{
              color: 'danger',
              fontSize: '12px',
            }}
          >
            {msg}
          </Text>
        )}
      </ErrorMessage>
    </Box>
  )
})
