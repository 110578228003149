import { ResizeOptions } from 'sharp'
import { ExistsOutput } from 'src/server/storage'
import { Readable } from 'stream'
import { Media } from './media.entity'

export interface RemoteFile {
  data: ExistsOutput
  stream: Readable
}

export enum MediaEventType {
  Connected = 'connected',
  Waiting = 'waiting',
  Progress = 'progress',
  Created = 'created',
}

export interface MediaEvent {
  creatorId: number
  type: MediaEventType
  data: Record<string, any>
}

export interface ProgressEvent extends MediaEvent {
  type: MediaEventType.Progress
  data: {
    media: {
      filename: string
    }
    progress: number
    jobId: string
    jobState: JobState
    error?: string
  }
}

export interface WaitingEvent extends MediaEvent {
  type: MediaEventType.Waiting
  data: {
    jobState: JobState.Waiting
    jobId: string
    media: {
      filename: string
    }
  }
}

export interface CreatedEvent extends MediaEvent {
  type: MediaEventType.Created
  data: {
    jobId: string
    jobState: JobState.Complete
    media?: Media
  }
}

export enum JobState {
  Waiting = 'waiting',
  Uploading = 'uploading',
  Storing = 'storing',
  Processing = 'processing',
  Complete = 'complete',
  Error = 'error',
  Failed = 'failed',
}

// There can be multiple variations with the same type
export enum VariationType {
  Thumb = 'thumb',
  Full = 'full',
  FullVideo = 'full.video',
  Original = 'original',
  Preview = 'preview',
  Square = 'square',
}

// Variation names are unique
export enum VariationName {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
  Full = 'full',
  FullVideo = 'full.video',
  Original = 'original',
  Preview = 'preview',
  Album = 'album',
}

export interface Variation {
  name: VariationName
  width: number
  height?: number
  contentType?: string
  video?: boolean
  type: VariationType
  options?: ResizeOptions
}

// Used to generate variations for a given upload
export const Variations: Variation[] = [
  { name: VariationName.Preview, width: 40, type: VariationType.Preview },
  // Not sure we should generate an album version for every single file
  { name: VariationName.Album, width: 240, height: 240, type: VariationType.Square },
  { name: VariationName.Small, width: 320, type: VariationType.Thumb },
  { name: VariationName.Medium, width: 480, type: VariationType.Thumb },
  { name: VariationName.Large, width: 640, type: VariationType.Thumb },
  { name: VariationName.Full, width: 1200, type: VariationType.Full },
  {
    name: VariationName.FullVideo,
    width: -1,
    contentType: 'video',
    video: true,
    type: VariationType.FullVideo,
  },
]

export const ExifProperties = [
  'Aperture',
  'CreateDate',
  'BrightnessValue',
  'DateTimeOriginal',
  'DeviceAttributes',
  'DeviceManufacturer',
  'DeviceModel',
  'ExposureMode',
  'ExposureProgram',
  'ExposureTime',
  'Flash',
  'FNumber',
  'FocalLength',
  'GPSAltitude',
  'GPSAltitudeRef',
  'GPSDateStamp',
  'GPSDateTime',
  'GPSImgDirection',
  'GPSImgDirectionRef',
  'GPSLatitude',
  'GPSLatitudeRef',
  'GPSLongitude',
  'GPSLongitudeRef',
  'GPSProcessingMethod',
  'GPSTimeStamp',
  'GPSVersionID',
  'ISO',
  'Make',
  'Model',
  'Megapixels',
  'ModifyDate',
  'Orientation',
  'ShutterSpeed',
]
