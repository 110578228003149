import { Id, userCurrentUrl, userlistUrl, userUrl } from 'src/server/routes'
import { UpdateUserDto } from 'src/server/user/dto/update-user.dto'
import { User } from 'src/server/user/user.entity'
import { get, post, put } from './api'

export const users = {
  list: async () => await get<User[]>(userlistUrl()),
  me: async (data: UpdateUserDto) => await put<User>(userCurrentUrl(), data),
  create: async (data: UpdateUserDto) => await post<User>(userlistUrl(), data),
  update: async (params: Id, data: UpdateUserDto) => await put<User>(userUrl(params), data),
}
