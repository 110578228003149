import React from 'react'
import { Box } from 'theme-ui'
import { GalleryHeading } from './GalleryHeading'
import { Image } from './Image'
import { GalleryItem, GalleryItemWithIndex } from './types'

export type ScrollDirection = 'up' | 'down'

interface GalleryRowProps<T> {
  photos: Array<GalleryItem<T>>
  scrollOffset: number
  top: number
  height: number
  id: number
  title: string
  selected: Set<string>
  primaryBuffer?: number
  secondaryBuffer?: number
  scrollDirection: ScrollDirection
  onClick?: (e: React.MouseEvent<HTMLElement>, data: GalleryItemWithIndex<T>) => void
  onSelect?: (
    e: React.MouseEvent<HTMLElement>,
    data: GalleryItemWithIndex<T> | Array<GalleryItem<T>>,
    selected?: boolean
  ) => void
}

const { useState, useEffect } = React

export const GalleryRow = React.memo(<T extends unknown>(props: GalleryRowProps<T>) => {
  const [allSelected, setAllSelected] = useState(false)
  const {
    photos,
    primaryBuffer = 1000,
    secondaryBuffer = 300,
    selected,
    scrollDirection,
    scrollOffset,
    onClick,
    onSelect,
    top,
    height,
    title,
  } = props

  const windowResult = (images: Array<GalleryItem<any>>) => {
    const bufferTop = scrollDirection === 'up' ? primaryBuffer : secondaryBuffer
    const bufferBottom = scrollDirection === 'down' ? secondaryBuffer : primaryBuffer
    const scrollerHeight = window.innerHeight
    const minTranslateYPlusHeight = scrollOffset - top - bufferTop // Remove image if the bottom is above this point
    const maxTranslateY = scrollOffset - top + scrollerHeight + bufferBottom // Remove image if the top is below this point
    return images.filter(
      (image) => !(image.top + image.height < minTranslateYPlusHeight || image.top > maxTranslateY)
    )
  }

  const onSelectAll = (e: React.MouseEvent<HTMLElement>, sel: boolean) => {
    setAllSelected(sel)
    onSelect?.(e, photos, sel)
  }

  useEffect(() => {
    if (selected.size === 0 && allSelected) setAllSelected(false)
  }, [selected.size])

  return (
    <Box style={{ position: 'absolute', top, width: '100%', left: 0, height }}>
      <GalleryHeading date={title} onClick={onSelectAll} selected={allSelected} />
      {windowResult(photos).map((thumb) => {
        const { containerHeight: _, ...photo } = thumb
        return (
          <Image
            key={thumb.key}
            {...{
              index: thumb.index,
              selected: selected.has(thumb.key),
              onClick,
              onSelect,
              photo,
            }}
          />
        )
      })}
    </Box>
  )
})
GalleryRow.displayName = 'GalleryRow'
