import { tailwind } from '@theme-ui/presets'
import { album } from './variants'

export const text = {
  backgroundColor: 'transparent',
  color: 'primary',
  cursor: 'pointer',
  fontFamily: 'body',
  p: 0,
  '&:focus': {
    outline: 0,
  },
}

export const icon = {
  alignItems: 'center',
  background: 'none',
  border: '0',
  cursor: 'pointer',
  display: 'inline-flex',
  height: '44px',
  justifyContent: 'center',
  outline: '0',
  padding: '0',
  position: 'relative',
  width: '44px',
  svg: {
    transition: 'all 300ms',
  },
}

const buttonSize = 50

export const carousel = {
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.2)',
  border: '0',
  borderRadius: '50%',
  color: 'white',
  cursor: 'pointer',
  display: 'flex',
  fontSize: 'inherit',
  height: `${buttonSize}px`,
  justifyContent: 'center',
  marginTop: `-${buttonSize / 2}px`,
  outline: '0',
  position: 'absolute',
  top: '50%',
  transition: 'background-color 200ms',
  width: `${buttonSize}px`,
  '&:hover': {
    background: 'rgba(255, 255, 255, 0.3)',
  },
  '&:active': {
    background: 'rgba(255, 255, 255, 0.2)',
  },
}

export const iconLight = {
  ...icon,
  svg: {
    transition: 'all 300ms',
    fill: 'rgba(255, 255, 255, 0.7)',
  },
  '&:hover': {
    svg: {
      fill: '#fff',
    },
  },
}

export const iconDark = {
  ...icon,
  svg: {
    transition: 'all 300ms',
    fill: 'secondary',
  },
  '&:hover': {
    svg: {
      fill: 'secondary',
    },
  },
}

export const primary = {
  ...tailwind.buttons.simple,
  transition: 'all 300ms ease-in',
  '&:disabled': {
    backgroundColor: 'light',
  },
  '&:focus': {
    outline: 0,
  },
}

export const buttons = {
  primary,
  carousel,
  text,
  icon,
  album,
  'icon-light': iconLight,
  'icon-dark': iconDark,
}
