import formatDistance from 'date-fns/formatDistance'
import parseISO from 'date-fns/parseISO'
import React from 'react'
import * as api from 'src/client/api'
import { UserSolid } from 'src/client/assets/images'
import {
  List,
  ListContent,
  ListIcon,
  ListItem,
} from 'src/client/components/primitives'
import { useArray } from 'src/client/util/useArray'
import { UserRole } from 'src/server/user/types'
import { User } from 'src/server/user/user.entity'
import { Box, Button, Flex, Heading } from 'theme-ui'
import { UserDialog } from './UserForm'

const { useEffect, useState } = React

const roleToString = (role: UserRole) => {
  switch (role) {
    case UserRole.Admin:
      return 'Admin'
    case UserRole.User:
      return 'User'
    case UserRole.Guest:
      return 'Guest'
    case UserRole.Disabled:
      return 'Disabled'
  }
}

export const UserList = () => {
  const [users, userActions] = useArray<User>([], 'id')
  const [editing, setEditing] = useState<User | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const closeDialog = () => {
    setDialogOpen(false)
    setEditing(null)
  }
  const newUser = () => setDialogOpen(true)
  const editUser = (user: User) => {
    setEditing(user)
    setDialogOpen(true)
  }
  const onSuccess = (user: User) => {
    userActions.add(user, true)
    closeDialog()
  }

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const { data } = await api.users.list()
        userActions.setValue(data)
      } catch (e) {
        console.log(e)
      }
    }
    fetchUsers()
  }, [])

  return (
    <Box>
      <Flex as="header" sx={{ justifyContent: 'space-between' }}>
        <Heading as="h2">Users</Heading>
        <Button onClick={newUser}>Add User</Button>
      </Flex>
      <List>
        {users.map((user) => {
          const edit = () => editUser(user)
          return (
            <ListItem key={user.id} onClick={edit} sx={{ cursor: 'pointer' }}>
              <ListIcon>
                <UserSolid sx={{ fill: 'secondary' }} />
              </ListIcon>
              <ListContent>
                <Heading as="a">
                  {user.email} {user.name && `(${user.name})`}
                </Heading>
                <Box>
                  {roleToString(user.role)} | Updated{' '}
                  {formatDistance(parseISO(String(user.updatedAt)), new Date())} ago
                </Box>
              </ListContent>
            </ListItem>
          )
        })}
      </List>
      <UserDialog
        isOpen={dialogOpen}
        user={editing}
        onSuccess={onSuccess}
        onClose={closeDialog}
        backdropCloses={true}
      />
    </Box>
  )
}
