import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import { useEffect, useRef } from 'react'
import { Redirect, useRouteMatch } from 'react-router-dom'
import { useAuth } from 'src/client/components/hooks/useAuth'
import { Link } from 'src/client/components/primitives'
import { Error } from 'src/client/components/primitives/Error'
import { loginSchema } from 'src/client/components/primitives/validators'
import {
  authenticationUrl,
  forgotPasswordUrl,
  indexUrl,
} from 'src/client/routes'
import { Box, Button, Flex, Input } from 'theme-ui'

const initialValues = { email: '', password: '' }

type FormValues = typeof initialValues

export const LoginComponent = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { authenticated, signIn } = useAuth()
  const isLogin = useRouteMatch(authenticationUrl.route)
  const ref = useRef<HTMLInputElement>(undefined!)
  const onSubmit = async (
    { email, password }: FormValues,
    { setErrors }: FormikHelpers<FormValues>
  ) => {
    try {
      await signIn(email, password)
      onSuccess?.()
    } catch (e) {
      setErrors({ password: e?.response?.data?.message })
    }
  }

  useEffect(() => {
    ref.current?.focus()
  }, [])

  if (authenticated) {
    return <Redirect to={{ pathname: isLogin ? indexUrl : window.location.pathname }} />
  }

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={loginSchema}
      onSubmit={onSubmit}
    >
      {({ values, handleChange, handleBlur, isSubmitting }: FormikProps<FormValues>) => (
        <Box sx={{ border: '1px solid', borderColor: 'muted', borderRadius: 4, p: 4 }}>
          <Form noValidate>
            <Input
              mb={3}
              ref={ref}
              type="email"
              name="email"
              placeholder="Email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            <Error name="email" />
            <Input
              mb={3}
              type="password"
              name="password"
              placeholder="Password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            <Error name="password" />
            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
            <Box mt={3}>
              <Link to={forgotPasswordUrl()}>Forgot Password?</Link>
            </Box>
          </Form>
        </Box>
      )}
    </Formik>
  )
}

export const Login = () => {
  return (
    <Flex
      css={{
        width: '100%',
        height: 'calc(100vh - 50px)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoginComponent />
    </Flex>
  )
}
