import formatDistance from 'date-fns/formatDistance'
import parseISO from 'date-fns/parseISO'
import React, { useEffect, useState } from 'react'
import * as api from 'src/client/api'
import { Map } from 'src/client/assets/images'
import {
  List,
  ListContent,
  ListIcon,
  ListItem,
} from 'src/client/components/primitives'
import { useArray } from 'src/client/util/useArray'
import { Place } from 'src/server/place/place.entity'
import { Box, Button, Flex, Heading } from 'theme-ui'
import { PlaceDialog } from './PlaceForm'

export const PlaceList = () => {
  const [places, placeActions] = useArray<Place>([], 'id')
  const [editing, setEditing] = useState<Place | null>(null)
  const [dialogOpen, setDialogOpen] = useState(false)
  const closeDialog = () => {
    setDialogOpen(false)
    setEditing(null)
  }
  const newPlace = () => setDialogOpen(true)
  const editPlace = (place: Place) => {
    setEditing(place)
    setDialogOpen(true)
  }
  const onSuccess = (place: Place) => {
    placeActions.add(place, true)
    closeDialog()
  }

  useEffect(() => {
    const fetchPlaces = async () => {
      try {
        const { data } = await api.places.list()
        placeActions.setValue(data)
      } catch (e) {
        console.log(e)
      }
    }
    fetchPlaces()
  }, [])

  return (
    <Box>
      <Flex as="header" sx={{ justifyContent: 'space-between' }}>
        <Heading as="h2">Places</Heading>
        <Button onClick={newPlace}>Add Place</Button>
      </Flex>
      <List>
        {places.map((p) => {
          const edit = () => editPlace(p)
          return (
            <ListItem key={p.id} onClick={edit} sx={{ cursor: 'pointer' }}>
              <ListIcon>
                <Map sx={{ fill: 'secondary' }} />
              </ListIcon>
              <ListContent>
                <Heading as="a">{p.label}</Heading>
                <Box>Updated {formatDistance(parseISO(String(p.updatedAt)), new Date())} ago</Box>
              </ListContent>
            </ListItem>
          )
        })}
      </List>
      <PlaceDialog
        isOpen={dialogOpen}
        place={editing}
        onSuccess={onSuccess}
        onClose={closeDialog}
        backdropCloses={true}
      />
    </Box>
  )
}
