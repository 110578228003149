import uniqWith from 'lodash-es/uniqWith'
import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import * as api from 'src/client/api'
import { LinkAlt } from 'src/client/assets/images'
import { Dialog, DialogProps } from 'src/client/components/blocks/Dialog'
import { List } from 'src/client/components/primitives'
import { albumUrl } from 'src/client/routes'
import { CreateShareDto } from 'src/server/share/dto'
import { Share } from 'src/server/share/share.entity'
import { User } from 'src/server/user/user.entity'
import { Box, Divider, Flex, Text } from 'theme-ui'
import { ShareControls } from './internal/ShareControls'

const animatedComponents = makeAnimated()

export const ShareForm = () => {
  const match = useRouteMatch<{ id: string }>({ path: albumUrl.route })
  const id = match?.params?.id ?? 'no-id'
  const [shares, setShares] = useState<Share[]>([])
  const [users, setUsers] = useState<User[]>([])
  const [selected, setSelected] = useState<Array<{ value: number; label: string }>>([])

  useEffect(() => {
    const fetchShares = async () => {
      try {
        const { data } = await api.shares.list({ album: id })
        setShares(data)
      } catch (e) {
        setShares([])
      }
    }
    const fetchUsers = async () => {
      try {
        const { data } = await api.users.list()
        setUsers(data)
      } catch (e) {
        setUsers([])
      }
    }

    fetchShares()
    fetchUsers()
  }, [id])

  const createShare = async (params: Omit<CreateShareDto, 'album'> = {}) => {
    try {
      const { data } = await api.shares.create({ ...params, album: id })
      setShares(uniqWith([...shares, data], (a, b) => a.id === b.id))
    } catch (e) {
      console.log(e)
    }
  }

  const updateShare = async (shareId: number, params: Omit<CreateShareDto, 'album'>) => {
    try {
      const { data } = await api.shares.update({ id: shareId }, { ...params, album: id })
      setShares([...shares.map((s) => (s.id === shareId ? data : s))])
    } catch (e) {
      console.log(e)
    }
  }

  const deleteShare = async (shareId: number) => {
    try {
      console.log('deleting', shareId)
      await api.shares.destroy({ id: shareId })
      setShares([...shares.filter((s) => s.id !== shareId)])
    } catch (e) {
      console.log(e)
    }
  }

  const onClose = async () => {
    for (const option of selected) {
      await createShare({ participant: option.value })
    }
    setSelected([])
  }

  const createLinkShare = async () => {
    createShare()
  }

  const onSelectionChanged = (data: Array<{ value: number; label: string }>) => {
    setSelected(data)
  }

  return (
    <Box sx={{ border: '1px solid', borderColor: 'muted', p: 4 }}>
      <Select
        placeholder="Share with user"
        closeMenuOnSelect={false}
        components={animatedComponents}
        isMulti
        value={selected}
        onChange={onSelectionChanged}
        onMenuClose={onClose}
        options={users.map((u) => ({ value: u.id, label: u.email }))}
      />
      <Divider mx={0} my={4} />
      <Flex
        sx={{ display: 'inline-flex', alignItems: 'center', cursor: 'pointer', mb: 4 }}
        onClick={createLinkShare}
      >
        <Flex
          sx={{
            borderRadius: '50%',
            bg: 'primary',
            width: 12,
            height: 12,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <LinkAlt />
        </Flex>
        <Text ml={2}>Create Share Link</Text>
      </Flex>
      <List relaxed>
        {shares.map((s) => {
          const onDelete = () => {
            deleteShare(s.id)
          }
          const onChange = (perm: string, checked: boolean) => {
            updateShare(s.id, { [perm]: checked })
          }
          return <ShareControls key={s.id} share={s} onDelete={onDelete} onChange={onChange} />
        })}
      </List>
    </Box>
  )
}

export const ShareDialog = ({
  isOpen,
  onClose,
  backdropCloses = true,
}: Omit<DialogProps, 'children'>) => (
  <Dialog isOpen={isOpen} onClose={onClose} backdropCloses={backdropCloses} title="Share Settings">
    <ShareForm />
  </Dialog>
)
