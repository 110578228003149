import { matchPath } from 'react-router-dom'

// Detect whether device is touch capabile
export function isTouch() {
  try {
    document.createEvent('TouchEvent')
    return true
  } catch (e) {
    return false
  }
}

/** Whether the user is in an editable content node and therefore we shouldn't capture keyboard events */
export const isInteracting = () =>
  document.activeElement?.nodeName === 'INPUT' ||
  document.activeElement?.nodeName === 'TEXTAREA' ||
  (document.activeElement as any)?.contentEditable === 'true'

export const isVideo = (contentType?: string) => contentType?.slice(0, 5) === 'video'

// Compare a path to multiple routes
export const matchesPaths = (path: string, ...routes: string[]) =>
  routes.some((r) => matchPath(path, r))

// Removes trailing slash, preserving root
// basePath('/') => '/'
// basePath('/foo/') => '/foo'
// basePath('/foo////') => '/foo'
export const basePath = (route: string | RegExp, path: string) =>
  path.replace(route, '/').replace(/([^/]+)(\/+)$/, (_, p1) => p1)
