import React from 'react'
import { CloudUpload } from 'src/client/assets/images'
import { Box, Flex, Heading } from 'theme-ui'
import { filterFiles } from './util'

export interface DropZoneProps {
  /** Content Text */
  dropZoneText?: string
  /** Accepted File Types */
  accept?: string
  /** Handle File Upload/Drop */
  onUpload?: (fs: FileList | File[]) => Promise<void> | void
}

interface DropZoneState {
  files: FileList | null
  dragging: boolean
}

let lastTarget: any

export class DropZone extends React.Component<DropZoneProps, DropZoneState> {
  state: DropZoneState = {
    files: null,
    dragging: false,
  }

  fileInput?: HTMLInputElement

  container = React.createRef<HTMLDivElement>()

  componentDidMount() {
    window.addEventListener('dragenter', this.showDropZone)
    if (this.container.current) {
      this.container.current.addEventListener('dragenter', this.allowDrag)
      this.container.current.addEventListener('dragleave', this.hideDropZone)
      this.container.current.addEventListener('dragover', this.onDragOver)
      this.container.current.addEventListener('drop', this.onDrop)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('dragenter', this.showDropZone)
    if (this.container.current) {
      this.container.current.removeEventListener('dragenter', this.allowDrag)
      this.container.current.removeEventListener('dragleave', this.hideDropZone)
      this.container.current.removeEventListener('dragover', this.onDragOver)
      this.container.current.removeEventListener('drop', this.onDrop)
    }
  }

  showDropZone = (e: Event | React.DragEvent<Window>) => {
    if (this.isFile(e)) {
      lastTarget = e.target
      this.setState({ dragging: true })
    }
  }

  hideDropZone = (e: Event | React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (e.target === lastTarget || e.target === document) {
      this.setState({ dragging: false })
    }
  }

  allowDrag = (e: React.DragEvent<HTMLDivElement> & Event) => {
    e.dataTransfer.dropEffect = 'copy'
    e.preventDefault()
  }

  onDragOver = (e: Event | React.DragEvent<Window>) => e.preventDefault()

  onDrop = (e: React.DragEvent<Window> & Event) => {
    e.preventDefault()
    this.setState({ dragging: false })
    const { files } = e.dataTransfer
    this.setState({ files }, () => this.handleFileUpload())
  }

  isFile(evt: any) {
    for (const type of evt.dataTransfer.types) {
      if (type === 'Files') return true
    }
    return false
  }

  handleFileUpload = () => {
    const accepted = filterFiles(this.state.files, this.props.accept)
    if (accepted?.length) this.props.onUpload?.(accepted)
  }

  render() {
    const { dropZoneText = 'Drop files anywhere to upload' } = this.props
    const { dragging: visible } = this.state
    const text = dropZoneText

    return (
      <Flex
        ref={this.container}
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100%',
          height: '100%',
          zIndex: 9999999999,
          transition: 'all 175ms',
          color: '#fff',
          background: 'rgba(0, 0, 0, 0.8)',
          opacity: visible ? 1 : 0,
          visibility: visible ? 'visible' : 'hidden',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            width: '100%',
            textAlign: 'center',
          }}
        >
          <Box
            sx={{
              display: 'inline-flex',
              borderRadius: '50%',
              backgroundColor: 'primary',
              width: 240,
              height: 240,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CloudUpload css={{ width: '70%', height: '70%' }} />
          </Box>
          <Heading sx={{ p: 4 }}>{text}</Heading>
        </Box>
      </Flex>
    )
  }
}
