import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import * as qs from 'query-string'
import { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import * as api from 'src/client/api'
import {
  Field,
  forgotPasswordSchema,
  Link,
} from 'src/client/components/primitives'
import { authenticationUrl } from 'src/client/routes'
import { Box, Button, Flex, Text } from 'theme-ui'

const initialValues = { email: '' }

type FormValues = typeof initialValues

export const ForgotPasswordComponent = ({ onSuccess }: { onSuccess?: () => void }) => {
  const ref = useRef<HTMLInputElement>(undefined!)
  const history = useHistory()
  const { email: query } = qs.parse(location.search)
  const onSubmit = async ({ email }: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
    try {
      await api.auth.forgotPassword({ email })
      onSuccess?.()
      history.push(authenticationUrl())
    } catch (e) {
      setErrors({ email: e?.response?.data?.message })
    }
  }

  useEffect(() => {
    ref.current?.focus()
  }, [])

  return (
    <Formik
      initialValues={{ ...initialValues, email: query }}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={forgotPasswordSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }: FormikProps<FormValues>) => (
        <Box sx={{ border: '1px solid', borderColor: 'muted', borderRadius: 4, p: 4 }}>
          <Form noValidate>
            <Text mb={3}>
              Please enter your email below, and we'll send you a link to reset your password.
            </Text>
            <Field mb={3} ref={ref} type="email" name="email" placeholder="Email" />
            <Button type="submit" disabled={isSubmitting}>
              Reset Password
            </Button>
          </Form>
          <Box mt={3}>
            <Link to={authenticationUrl()}>Return to Login</Link>
          </Box>
        </Box>
      )}
    </Formik>
  )
}

export const ForgotPassword = () => {
  return (
    <Flex
      css={{
        width: '100%',
        height: 'calc(100vh - 50px)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ForgotPasswordComponent />
    </Flex>
  )
}
