import { Box, BoxProps } from 'theme-ui'

export const CommentGroup = ({ children, sx, ...rest }: BoxProps) => (
  <Box
    sx={{
      marginTop: 0,
      margin: '1.5em 0',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

export const Comment = ({ children, sx, ...rest }: BoxProps) => (
  <Box
    sx={{
      position: 'relative',
      background: '0 0',
      margin: '.5em 0 0',
      padding: '.5em 0 0',
      border: 'none',
      borderTop: 'none',
      lineHeight: '1.2',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

export const CommentHeader = ({ children, sx, ...rest }: BoxProps) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

export const CommentAuthor = ({ children, sx, ...rest }: BoxProps) => (
  <Box
    sx={{
      fontSize: '1em',
      color: 'rgba(0,0,0,.87)',
      fontWeight: 700,
      time: {
        fontWeight: 'normal',
        marginLeft: '0.5em',
        fontSize: '0.75em',
        color: 'rgba(0,0,0,.4)',
      },
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

export const CommentMetadata = ({ children, sx, ...rest }: BoxProps) => (
  <Box
    sx={{
      display: 'inline-block',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

export const CommendBody = ({ children, sx, ...rest }: BoxProps) => (
  <Box
    sx={{
      margin: '.25em 0 .5em',
      fontSize: '1em',
      wordWrap: 'break-word',
      color: 'rgba(0,0,0,.87)',
      lineHeight: '1.3',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)
