import { pathToRegexp } from 'path-to-regexp'
import React from 'react'
import { matchPath, RouteComponentProps, withRouter } from 'react-router-dom'
import * as api from 'src/client/api'
import { Header, MainLayout, ModalGateway } from 'src/client/components/blocks'
import { NoContent } from 'src/client/components/primitives'
import {
  albumMediaUrl,
  albumUrl,
  indexUrl,
  mediaUrl,
  trashUrl,
} from 'src/client/routes'
import { MediaDetail } from 'src/client/scenes/media/MediaDetail'
import { basePath, matchesPaths } from 'src/client/util'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { SerializedMedia } from 'src/server/media/media.entity'
import { MediaGallery } from './internal/MediaGallery'
import { MediaItemWithIndex } from './internal/types'
import { isMediaDetailUrl, urlFor } from './internal/util'
import { MediaContext } from './MediaContext'

const mediaDetailRegexp = pathToRegexp(mediaUrl.route, [], { start: false })

type Props = RouteComponentProps<{ id?: string; mediaId?: string }>

interface State {
  modalOpen: boolean
  /** Index of the media that the modal should open with */
  startIndex: number
}

// MediaList is responsible for loading and maintaining the set
class MediaListComponent extends React.Component<Props, State> {
  static contextType = MediaContext
  declare context: React.ContextType<typeof MediaContext>

  state: State = {
    modalOpen: false,
    startIndex: -1,
  }

  componentDidMount() {
    this.loadMedia()
  }

  componentDidUpdate(prevProps: Props) {
    const inAlbum = matchesPaths(this.props.match.path, albumUrl.route, albumMediaUrl.route)
    const { url } = this.props.match
    const { url: prevUrl } = prevProps.match
    const galleryRootChanged =
      basePath(mediaDetailRegexp, url) !== basePath(mediaDetailRegexp, prevUrl)
    if (!inAlbum && this.context?.album) this.context?.setAlbum(null)
    if (galleryRootChanged) {
      this.loadMedia()
    } else {
      this.setDetailState()
    }
  }

  // Same layout is used when
  // 1) Viewing your own media or a single media item from there
  // 2) Viewing an album (either your own or shared with your) or a single media item from there
  // 3) Viewing an unauthenticated share
  // 4) Viewing trashed media
  async loadMedia() {
    window.scrollTo(0, 0)
    const {
      match: {
        path,
        params: { id },
      },
    } = this.props
    this.context?.setLoading(true)
    this.context?.setSelected(new Set())
    try {
      const trashed = matchPath(path, trashUrl.route)
      // Albums
      if (matchesPaths(path, albumUrl.route, albumMediaUrl.route)) {
        const qs = new URLSearchParams(location.search)
        const { data } = await api.albums.get({ id: id!, key: qs.get('key') })
        const { media, ...album } = data
        this.context?.setAlbum(album as SerializedAlbum)
        this.setMedia(media)
        // Home
      } else if (matchesPaths(path, indexUrl, mediaUrl.route) || trashed) {
        const { data } = await (trashed ? api.media.trashed() : api.media.list())
        this.setMedia(data)
      }
      this.setDetailState()
    } catch (error) {
      // Needs to be rethrown in case it happens outside render
      // https://github.com/facebook/react/issues/11334#issuecomment-423718317
      this.setState(() => {
        throw error
      })
    } finally {
      this.context?.setLoading(false)
    }
  }

  setDetailState = () => {
    const { url } = this.props.match
    const match = isMediaDetailUrl(url)
    const startIndex = match
      ? this.context?.media?.findIndex((d) => d.key === match.params.mediaId) ?? -1
      : -1
    const modalOpen = startIndex !== -1
    if (this.state.modalOpen !== modalOpen || this.state.startIndex !== startIndex) {
      this.setState({ modalOpen, startIndex })
    }
    if (!match || !modalOpen) {
      const newPath = basePath(mediaUrl.route, url)
      if (newPath !== url) this.props.history.replace(newPath)
    }
  }

  setMedia = (media: SerializedMedia | SerializedMedia[]) => {
    this.context?.setMedia(Array.isArray(media) ? media : [media])
  }

  onMediaView = (data: MediaItemWithIndex) => {
    const { history, match } = this.props
    history.push(urlFor(match, { id: data.photo.key }))
    // console.log('on media view')
    this.setState({ startIndex: data.index, modalOpen: true })
  }

  onMediaClose = () => {
    const { history, match } = this.props
    if (history.length > 2 || document.referrer.length > 0) {
      history.goBack()
    } else {
      history.push(basePath(mediaUrl.route, match.url))
    }
    // console.log('on media close')
    this.setState({ startIndex: -1, modalOpen: false })
  }

  // TODO Can be moved where relevant
  onMediaSelect = (selected: Set<string>) => {
    this.context?.setSelected(selected)
  }

  render() {
    const { startIndex, modalOpen } = this.state
    const { album, media, loading, selected } = this.context!

    return (
      <MainLayout loading={loading}>
        <Header />
        <MediaGallery
          media={media}
          selected={selected}
          onMediaSelect={this.onMediaSelect}
          onMediaClick={this.onMediaView}
          album={album}
        />
        <ModalGateway>
          {modalOpen ? (
            <MediaDetail
              album={album}
              onClose={this.onMediaClose}
              views={media}
              startIndex={startIndex}
            />
          ) : null}
        </ModalGateway>
        {!loading && media.length === 0 && <NoContent />}
      </MainLayout>
    )
  }
}

// The advantage of reusing this rather than creating explicit components for Home/Album/Trash is
// that it remains mounted essentially and you get a transition effect with the
// photos
export const MediaList = withRouter(MediaListComponent)
