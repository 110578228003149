import { Box, Flex, Heading } from 'theme-ui'

export const NoContent = () => (
  <Flex
    sx={{
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      position: 'absolute',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Box>
      <Heading>No Media</Heading>
    </Box>
  </Flex>
)
