import { MessageEvent } from '@nestjs/common'
import axios, { CancelTokenSource } from 'axios'
import { debounce } from 'lodash'
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { SSEContext } from 'react-hooks-sse'
import * as api from 'src/client/api'
import { useAuth } from 'src/client/components/hooks/useAuth'
import { usePrevious } from 'src/client/components/hooks/usePrevious'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { SerializedMedia } from 'src/server/media/media.entity'
import { JobState } from 'src/server/media/types'
import { ACCEPT } from 'src/shared/constants'
import { DropZone, DropZoneProps } from './internal/DropZone'
import { Notifier, NotifierProps } from './internal/Notifier'
import { filterFiles } from './internal/util'

interface UploaderProps {
  /** Album being uploaded to */
  album: SerializedAlbum | null
  /** Enabled? */
  canUpload?: boolean
  /** Show file upload dialog */
  fileDialogRequested?: boolean
  /** Replace Notifier Component */
  notifier?: (props: NotifierProps) => React.ReactElement
  /** Fired when an upload is complete */
  onComplete?: (media: SerializedMedia, album: SerializedAlbum | null) => void
}

type UploadDropZoneProps = { album: SerializedAlbum | null } & DropZoneProps

interface Job {
  /** Name of the file currently being uploaded */
  filename: string
  /** Progress of the current job being processed */
  progress: number
  /** State of the current job being processed */
  state: JobState
  /** Timestamp of first SSE event */
  timestamp: number
}

interface Upload {
  id?: string
  /** Name of the file currently being uploaded */
  filename: string
  /** Progress of the current job being processed */
  progress: number
  /** Something went wrong */
  error?: Error
  /** State of the current upload */
  state: JobState
}

// If background processing takes longer than 2 seconds, show UI updates
const longJob = 2000

const UploadDropZone = (props: UploadDropZoneProps) => {
  const auth = useAuth()
  const { album, ...rest } = props
  return auth.canCreate(album ?? undefined) ? <DropZone {...rest} /> : null
}

export const Uploader = ({
  album,
  canUpload,
  fileDialogRequested,
  onComplete,
  notifier: Component,
}: UploaderProps) => {
  const source = useContext(SSEContext)
  const [uploads, setUploads] = useState<Array<{ file: File; album: SerializedAlbum | null }>>([])
  const [current, setCurrent] = useState<Upload | null>(null)
  const [jobs, setJobs] = useState<{ [id: string]: Job }>({})
  const [index, setIndex] = useState(-1)
  const prevIndex = usePrevious(index)
  const now = new Date().getTime()
  // Don't immediately show long running jobs
  const longJobs = Object.keys(jobs)
    .map((k) => ({ ...jobs[k], id: k }))
    .filter((j) => now - j.timestamp > longJob)
  const [request, setRequest] = useState<CancelTokenSource | undefined>(undefined)
  const fileInput = useRef<HTMLInputElement>(null)
  const timeout = useRef<number | null>(null)
  const onCancel = () => {
    setIndex(-1)
    setUploads([])
    setCurrent(null)
    request?.cancel()
  }
  const showFileDialog = () => fileInput.current?.click()
  const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    // Filter again in case the OS doesn't support accept
    const accepted = filterFiles(e.target.files, ACCEPT)
    if (accepted?.length) onRequestUpload(accepted)
  }
  // Handle file drops
  const onRequestUpload = useCallback(
    (fileList: FileList | File[]) => {
      setUploads((fs) => [...fs, ...Array.from(fileList).map((file) => ({ file, album }))])
      // Kick off the upload if we're currently idle
      setIndex((c) => (c === -1 ? 0 : c))
    },
    [album]
  )

  // Setup SSE
  useEffect(() => {
    const updateJobs = (event: 'waiting' | 'progress' | 'complete', e: MessageEvent) => {
      const payload = typeof e.data === 'string' ? JSON.parse(e.data) : e.data
      const { progress, media, jobId: id, jobState: state } = payload
      const complete = event === 'complete' || state === JobState.Complete
      const { filename } = media || {}
      setCurrent((u) => {
        if (u && complete && payload.jobId === u.id) {
          if (timeout.current) window.clearTimeout(timeout.current)
          timeout.current = null
          return complete && payload.jobId === u.id ? { ...u, state: JobState.Complete } : u
        }
        return u
      })
      setJobs((j) => {
        const { [id]: existing, ...rest } = j
        return complete
          ? rest
          : {
              ...rest,
              [id]: existing
                ? { ...existing, progress, state }
                : { progress, filename, state, timestamp: new Date().getTime() },
            }
      })
      if (event === 'complete' && payload.media) {
        console.log('complete', event, payload.jobId, payload.jobState, payload.media.filename)
        onComplete?.(payload.media, album)
      }
    }
    const onSSEWaiting = (e: MessageEvent) => updateJobs('waiting', e)
    const onSSEProgress = debounce((e: MessageEvent) => updateJobs('progress', e), 250)
    const onSSEComplete = (e: MessageEvent) => updateJobs('complete', e)

    source?.addEventListener('connected', () => console.log('connected'))
    source?.addEventListener('waiting', onSSEWaiting)
    source?.addEventListener('progress', onSSEProgress)
    source?.addEventListener('created', onSSEComplete)
    return () => {
      source?.removeEventListener('waiting', onSSEWaiting)
      source?.removeEventListener('progress', onSSEProgress)
      source?.removeEventListener('created', onSSEComplete)
    }
  }, [source, album]) // adding onComplete causes it to be retriggered multiple times

  // Handle upload start
  useEffect(() => {
    const uploadFile = async () => {
      const upload = uploads[index]
      if (!upload) {
        setIndex(-1)
        setUploads([])
        setCurrent(null)
        return
      }
      try {
        const fd = new FormData()
        const source = axios.CancelToken.source()
        fd.append('file', upload.file)
        setCurrent(() => ({ progress: 0, state: JobState.Uploading, filename: upload.file.name }))
        setRequest(source)
        const { data } = await api.media.create(upload.album ? { albumId: upload.album.id } : {}, {
          formData: fd,
          cancelToken: source.token,
          onProgress: (e: ProgressEvent) =>
            setCurrent((u) => (u === null ? u : { ...u, progress: e.loaded / e.total })),
        })
        // console.log('upload finished', { state: JobState.Processing, id: data.id })
        setCurrent((u) => (u ? { ...u, state: JobState.Processing, id: data.id } : null))
        timeout.current = window.setTimeout(() => {
          setCurrent((u) => (u ? { ...u, state: JobState.Complete } : null))
        }, longJob)
      } catch (e) {
        setCurrent((u) => (u === null ? u : { ...u, error: e }))
        console.log(e)
      }
    }
    if (index > -1 && prevIndex !== index) uploadFile()
  }, [uploads, prevIndex, index])

  // Handle upload complete
  useEffect(() => {
    if (current?.state === JobState.Complete) {
      setIndex((c) => c + 1)
    }
  }, [current])

  useEffect(() => {
    if (fileDialogRequested) showFileDialog()
  }, [fileDialogRequested])

  const upload = uploads[index]
  const props = {
    album,
    current: index,
    upload: {
      album: upload?.album,
      file: upload?.file,
      progress: current?.progress ?? 0,
      state: current?.state ?? JobState.Uploading,
    },
    total: uploads.length,
    jobs: longJobs,
    onCancel,
  }

  return (
    <React.Fragment>
      <input
        ref={fileInput}
        onChange={onFileInputChange}
        accept={ACCEPT}
        type="file"
        multiple
        style={{ display: 'none' }}
      />
      {canUpload !== false && (
        <UploadDropZone accept={ACCEPT} album={album} onUpload={onRequestUpload} />
      )}
      {Component ? <Component {...props} /> : <Notifier {...props} />}
    </React.Fragment>
  )
}
