import CleaveJs from 'cleave.js'
import { CleaveOptions } from 'cleave.js/options'
import isEqual from 'lodash-es/isEqual'
import React from 'react'
import { Input, SxProps } from 'theme-ui'

const { useEffect, useRef, useState } = React

function usePrevious<T = any>(props: T): T {
  const ref = useRef(props)
  useEffect(() => {
    ref.current = props
  })

  return ref.current
}

export interface ChangeEvent<T> extends React.ChangeEvent<T> {
  target: { rawValue: string } & EventTarget & T
}

export type CleaveProps = {
  id?: string
  sx?: SxProps
  name?: string
  options: CleaveOptions
  inputRef?: React.Ref<HTMLInputElement>
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  value?: string
  defaultValue?: string
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'defaultValue' | 'onChange'>

export const Cleave = (props: CleaveProps) => {
  const { options, inputRef, value, type, onChange, defaultValue, ...otherProps } = props
  const [, setCleaveInstance] = useState<CleaveJs | null>(null)
  const input = useRef<HTMLInputElement>(null)
  const prevOptions = usePrevious(options)

  useEffect(() => {
    const newCleaveInstance = new CleaveJs(input?.current!, {
      ...options,
      initValue: value,
      onValueChanged: onChange,
    })
    newCleaveInstance.setRawValue(value || defaultValue || '')
    setCleaveInstance(newCleaveInstance)
  }, [!isEqual(options, prevOptions)])

  useEffect(() => {
    input.current.value = value
  }, [value])

  return <Input {...otherProps} ref={input} />
}
