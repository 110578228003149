import { Box } from 'theme-ui'

interface Props {
  currentIndex: number
  total: number
  interactionIsIdle: boolean
}

const FooterCount = (props: Props) => {
  const { currentIndex, total } = props
  const activeView = currentIndex + 1

  if (!activeView || !total) return null

  return (
    <span style={{ flexShrink: 0, marginLeft: '1em' }}>
      {activeView} of {total}
    </span>
  )
}

export const Footer = (props: Props) => {
  const { interactionIsIdle } = props
  return (
    <Box
      as="footer"
      className="backdrop"
      css={{
        alignItems: 'top',
        display: 'flex',
        flex: '0 0 auto',
        fontSize: '13px',
        justifyContent: 'space-between',
        transition: 'opacity 300ms, transform 300ms',
        background: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.33))',
        bottom: 0,
        color: 'rgba(255, 255, 255, 0.9)',
        left: 0,
        padding: '30px 20px 20px',
        position: 'absolute',
        right: 0,
        transform: `translateY(${interactionIsIdle ? 10 : 0}px)`,
        opacity: `${interactionIsIdle ? 0 : 1}`,
        zIndex: 1,
        '@media (max-width: 769px)': {
          padding: '5px 0',
        },
      }}
    >
      <FooterCount {...props} />
    </Box>
  )
}
