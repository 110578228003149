import { useEffect, useRef, useState } from 'react'
import { Textarea, TextareaProps } from 'theme-ui'

interface ResizableTextareaProps extends TextareaProps {
  minRows?: number
  maxRows?: number
}

export const ResizableTextarea = (props: ResizableTextareaProps) => {
  const { minRows = 1, maxRows = 10, ...rest } = props
  const textareaLineHeight = 24
  const [state, setState] = useState({ value: props.value || '', rows: 1 })
  const input = useRef<HTMLTextAreaElement>(null)

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight)
    const previousRows = event.target.rows

    event.target.rows = minRows // reset number of rows in textarea

    if (currentRows === previousRows) {
      event.target.rows = currentRows
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows
      event.target.scrollTop = event.target.scrollHeight
    }

    setState({
      ...state,
      value: event.target.value,
      rows: currentRows < maxRows ? currentRows : maxRows,
    })

    if (props.onChange) props.onChange(event)
  }

  useEffect(() => {
    const currentRows = ~~(input.current.scrollHeight / textareaLineHeight)
    setState({
      ...state,
      rows: currentRows < maxRows ? currentRows : maxRows,
    })
  }, [])

  return (
    <Textarea ref={input} {...rest} rows={state.rows} value={state.value} onChange={handleChange} />
  )
}
