import { Global } from '@emotion/react'
import { FormikProps } from 'formik'
import isEqual from 'lodash-es/isEqual'
import React from 'react'
import Select from 'react-select'
import { Input, InputProps } from 'theme-ui'

type AddressDropdownItemProps = Record<string, string>

interface AddressAutoCompleteProps extends Omit<InputProps, 'onSelect'> {
  id: string
  label?: string
  name?: string
  value?: string
  error?: string
  disabled?: boolean
  autoComplete?: string
  custom?: boolean
  customOptions?: AddressDropdownItemProps[]
  onBlur?: FormikProps<any>['handleBlur']
  onChange?: FormikProps<any>['handleChange']
  onSelect?: (result: google.maps.places.PlaceResult, id: string) => void
}
interface AddressAutoCompleteState {
  isFetching: boolean
  options: AddressDropdownItemProps[]
}

export class AddressAutoComplete extends React.Component<
  AddressAutoCompleteProps,
  AddressAutoCompleteState
> {
  static defaultProps = {
    id: 'autocomplete',
  }

  autocomplete: google.maps.places.Autocomplete

  autocompleteService: google.maps.places.AutocompleteService

  listener: google.maps.MapsEventListener

  input = React.createRef<HTMLInputElement>()

  state = {
    options: [],
    isFetching: false,
  }

  componentDidMount() {
    const { customOptions } = this.props
    if (customOptions?.length) {
      this.setState({ options: customOptions })
    }
    try {
      this.loadScript()
    } catch (e) {
      console.log(e)
    }
  }

  componentDidUpdate(prevProps: AddressAutoCompleteProps) {
    const { customOptions } = this.props
    if (
      !isEqual(
        prevProps.customOptions?.map((o) => o.key).sort(),
        customOptions?.map((o) => o.key).sort()
      )
    ) {
      console.log('setting custom options')
      this.setState({ options: customOptions })
    }
  }

  componentWillUnmount() {
    try {
      google.maps.event.removeListener(this.listener)
      google.maps.event.clearInstanceListeners(this.autocomplete)
      this.unloadScript()
    } catch (e) {
      console.log(e)
    }
    this.autocomplete = null
  }

  loadScript = () => {
    if (window.google?.maps?.places) return this.initAutocomplete()
    const existing = document.querySelector('#googlemaps')
    if (!existing) {
      const script = document.createElement('script')
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&libraries=places`
      script.id = 'googlemaps'
      document.body.appendChild(script)
      script.onload = () => this.initAutocomplete()
    }
  }

  unloadScript = () => {
    const googlePlacesScript = document.querySelector('#googlemaps')
    if (googlePlacesScript) {
      googlePlacesScript.remove()
    }
  }

  initAutocomplete = () => {
    const { onSelect, id, custom } = this.props
    if (custom) {
      this.autocompleteService = new google.maps.places.AutocompleteService()
    } else {
      this.autocomplete = new google.maps.places.Autocomplete(document.querySelector(`#${id}`), {
        types: ['address'],
      })
      this.listener = this.autocomplete.addListener('place_changed', () => {
        if (onSelect) onSelect(this.autocomplete.getPlace(), id)
      })
    }
  }

  serviceSearch = (input: string) => {
    const { options } = this.state

    if (input.trim() === '') {
      this.setState({
        isFetching: false,
        options: [...options.filter((o) => !o.external)],
      })
      return
    }

    this.setState({ isFetching: true })

    this.autocompleteService.getPlacePredictions(
      {
        input,
        types: ['address'],
      },
      (predictions, status) => {
        const predictionOptions: AddressDropdownItemProps[] = predictions.map((p) => ({
          key: p.id,
          text: p.description,
          external: 'true',
          value: p.id,
        }))
        const newOptions = [...options.filter((o) => !o.external), ...predictionOptions]
        this.setState({
          isFetching: false,
          options: newOptions,
        })
      }
    )
  }

  renderStandardAutocomplete = () => {
    const { onSelect, disabled = false, ...rest } = this.props
    console.log(this.props)
    return (
      <>
        <Global
          styles={{
            '.pac-container': {
              zIndex: '9999 !important',
            },
          }}
        />
        <Input {...rest} type="text" disabled={disabled} ref={this.input} />
      </>
    )
  }

  // TODO Not sure this actually works anymore
  renderCustomAutocomplete = () => {
    const { placeholder, disabled = false } = this.props
    const { isFetching, options } = this.state
    const handleSearchChange = (searchQuery: string) => this.serviceSearch(searchQuery)

    return (
      <Select
        placeholder={placeholder}
        onInputChange={handleSearchChange}
        isDisabled={disabled || isFetching}
        isLoading={isFetching}
        options={options}
      />
    )
  }

  render() {
    const { custom } = this.props
    return custom ? this.renderCustomAutocomplete() : this.renderStandardAutocomplete()
  }
}
