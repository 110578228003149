import { Form, Formik } from 'formik'
import React from 'react'
import { Dialog, DialogProps } from 'src/client/components/blocks/Dialog'
import { DateTimeInput } from 'src/client/components/primitives'
import { UpdateMediaDto } from 'src/server/media/dto'
import { SerializedMedia } from 'src/server/media/media.entity'
import { Box, Button } from 'theme-ui'

interface SharedProps {
  takenAt: Date
  takenAtOffset?: number
}

interface TimeFormProps extends SharedProps {
  onSubmit(data: UpdateMediaDto): Promise<SerializedMedia>
}

const initialValues = {
  takenAt: '',
  takenAtOffset: 0,
}

type Formvalues = typeof initialValues

const { useState } = React

export const TimeForm = ({ takenAt, takenAtOffset, onSubmit: onSave }: TimeFormProps) => {
  const [params, setParams] = useState({ takenAt: takenAt.toISOString(), takenAtOffset })
  const onSubmit = async () => onSave(params)
  const onChange = ({ date, offset }: { date: Date; offset: number }) =>
    setParams({ takenAt: date.toISOString(), takenAtOffset: offset })

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ isSubmitting }) => (
        <Box sx={{ border: '1px solid', borderColor: 'muted', borderRadius: 4, p: 4 }}>
          <Form>
            <DateTimeInput
              onChange={onChange}
              initialDate={takenAt}
              initialOffset={takenAtOffset}
            />
            <Button type="submit" disabled={isSubmitting}>
              Save
            </Button>
          </Form>
        </Box>
      )}
    </Formik>
  )
}

type TimeDialogProps = Omit<DialogProps, 'children'> & TimeFormProps

export const TimeDialog = ({
  isOpen,
  onClose,
  backdropCloses = true,
  ...rest
}: TimeDialogProps) => (
  <Dialog
    isOpen={isOpen}
    onClose={onClose}
    backdropCloses={backdropCloses}
    title="Edit date & time"
  >
    <TimeForm {...rest} />
  </Dialog>
)
