import throttle from 'lodash-es/throttle'
import React from 'react'
import Fullscreen from 'react-full-screen'
import ScrollLock from 'react-scrolllock'
import { Fade, SlideUp } from 'src/client/components/animation'
import { isTouch } from 'src/client/util'
import { SerializedMedia } from 'src/server/media/media.entity'
import { Box, Flex } from 'theme-ui'
import { ViewPager } from './ViewPager'

export const Backdrop = (props: {
  children?: React.ReactNode
  style?: object
  isFullscreen: boolean
}) => {
  const { isFullscreen, children, style } = props
  return (
    <Box
      css={{
        backdropFilter: 'blur(8px)',
        bottom: 0,
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 998,
      }}
      style={{
        ...style,
        backgroundColor: isFullscreen ? 'black' : 'rgba(0, 0, 0, 0.8)',
      }}
    >
      {children}
    </Box>
  )
}

export const Positioner = (props: {
  style: object
  children?: React.ReactNode
  sidebarOpen: boolean
  sidebarWidth: number
}) => {
  const { sidebarOpen, sidebarWidth, children, style, ...rest } = props
  return (
    <Box
      className="backdrop"
      css={{
        alignItems: 'center',
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        left: 0,
        position: 'fixed',
        right: 0,
        top: 0,
        zIndex: 999,
        transition: 'all 300ms ease-in',
        width: '100%',
      }}
      style={{
        ...style,
        width: sidebarOpen ? `calc(100% - ${sidebarWidth}px)` : '100%',
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export interface CarouselProps {
  /** Whether the carousel is visible or not */
  in?: boolean
  /** Whether we're currently in fullscreen */
  isFullscreen: boolean
  /** Enable/disable calling `onClose` when the backdrop is clicked */
  closeOnBackdropClick?: boolean
  /** Take control of the component's view index state */
  startIndex: number
  /** Duration, in milliseconds, to wait before hiding controls when the user is idle */
  hideControlsWhenIdle?: number | false
  /** Force hide or show controls on touch devices */
  showNavigationOnTouchDevice?: boolean
  /** Prevent scroll while carousel is open */
  preventScroll: boolean
  /** The items to render in the carousel */
  views: SerializedMedia[]
  /** Sidebar component */
  sidebar?: React.ComponentType
  /** Header component */
  header?: React.ComponentType<{ interactionIsIdle: boolean }>
  /** Footer component */
  footer?: React.ComponentType<{ interactionIsIdle: boolean }>
  /** Whether the sidebar is currently open */
  sidebarOpen?: boolean
  /** Width of sidebar in pixels */
  sidebarWidth?: number
  /** Called when fullscreen state is changed */
  onFullscreenChange?: (isFullscreen: boolean) => void
  /** Called when component mounts */
  onMount?: () => void
  /** Called when component unmounts */
  onUnMount?: () => void
  /** Function called to request close of the modal */
  onClose?: () => void
  /** Called when the current view changes */
  currentView?: (view: SerializedMedia, index: number) => void
}

interface CarouselState {
  currentIndex: number
  interactionIsIdle: boolean
}

export class Carousel extends React.Component<CarouselProps, CarouselState> {
  static defaultProps: Partial<CarouselProps> = {
    startIndex: 0,
    hideControlsWhenIdle: 3000,
    showNavigationOnTouchDevice: false,
    views: [],
  }

  startX = 0
  startY = 0
  pager = React.createRef<{ next: () => void }>()
  container = React.createRef<HTMLDivElement>()
  mounted = false
  timer = -1

  constructor(props: CarouselProps) {
    super(props)
    this.state = {
      currentIndex: props.startIndex,
      interactionIsIdle: isTouch(),
    }
  }

  componentDidMount() {
    const { hideControlsWhenIdle } = this.props
    const container = this.container.current

    this.mounted = true

    if (hideControlsWhenIdle && container) {
      container.addEventListener('mousedown', this.handleMouseActivity, { passive: true })
      container.addEventListener('mousemove', this.handleMouseActivity, { passive: true })
      container.addEventListener('touchstart', this.handleMouseActivity, { passive: true })
    }
    this.onIndexChanged(this.state.currentIndex)
  }

  componentDidUpdate(prevProps: CarouselProps) {
    const { startIndex } = this.props
    if (prevProps.startIndex !== startIndex && !isNaN(startIndex)) {
      this.setState({ currentIndex: startIndex }, () => {
        this.onIndexChanged(this.state.currentIndex)
      })
    }
  }

  componentWillUnmount() {
    this.mounted = false
    const container = this.container.current

    if (this.props.hideControlsWhenIdle && container) {
      container.removeEventListener('mousedown', this.handleMouseActivity)
      container.removeEventListener('mousemove', this.handleMouseActivity)
      container.removeEventListener('touchstart', this.handleMouseActivity)
      this.handleMouseActivity.cancel()
    }
  }

  onIndexChanged = (currentIndex: number) => {
    this.setState({ currentIndex })
    this.props.currentView?.(this.props.views[currentIndex], currentIndex)
  }

  // eslint-disable-next-line
  handleMouseActivity = throttle(() => {
    clearTimeout(this.timer)

    if (this.state.interactionIsIdle) {
      this.setState({ interactionIsIdle: false })
    }

    if (this.props.hideControlsWhenIdle) {
      this.timer = window.setTimeout(() => {
        if (this.mounted) this.setState({ interactionIsIdle: true })
      }, this.props.hideControlsWhenIdle)
    }
  })

  onBackdropClick = (event: MouseEvent) => {
    if (
      !(event.target as Element).classList.contains('backdrop') ||
      !this.props.closeOnBackdropClick
    ) {
      return
    }
    this.props.onClose?.()
  }

  onMouseDown = (e: MouseEvent) => {
    this.startX = e.clientX
    this.startY = e.clientY
  }

  onMouseUp = (e: MouseEvent) => {
    const endX = e.clientX
    const endY = e.clientY
    const delta = 5 // 5px delta
    if (Math.abs(this.startX - endX) < delta && Math.abs(this.startY - endY) < delta) {
      this.onBackdropClick(e)
    }
  }

  render() {
    const {
      children,
      showNavigationOnTouchDevice,
      onMount,
      header: Header,
      footer: Footer,
      sidebar: Sidebar,
      in: transitionIn,
      sidebarWidth,
      sidebarOpen,
      onUnMount,
      onFullscreenChange,
      preventScroll,
      startIndex,
      views,
    } = this.props
    const { interactionIsIdle } = this.state
    const isFullscreen = !!this.props.isFullscreen

    return (
      <Fullscreen enabled={isFullscreen} onChange={onFullscreenChange}>
        <div ref={this.container}>
          <Fade component={Backdrop} in={transitionIn} />
          <SlideUp
            component={Positioner}
            in={transitionIn}
            innerProps={{
              sidebarOpen,
              sidebarWidth: sidebarWidth || 300,
              onMouseDown: this.onMouseDown,
              onMouseUp: this.onMouseUp,
            }}
            onEntered={onMount}
            onExited={onUnMount}
          >
            <Box sx={{ width: '100%' }}>
              <Flex
                sx={{
                  backgroundColor: isFullscreen ? 'black' : null,
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <ViewPager
                  ref={this.pager}
                  initialIndex={startIndex}
                  showNavigationOnTouchDevice={showNavigationOnTouchDevice}
                  interactionIsIdle={interactionIsIdle}
                  views={views}
                  windowSize={6}
                  isFullscreen={isFullscreen}
                  onIndexChanged={this.onIndexChanged}
                />
                {Header && <Header interactionIsIdle={interactionIsIdle} />}
                {Footer && <Footer interactionIsIdle={interactionIsIdle} />}
                {children}
              </Flex>
            </Box>
            {preventScroll && <ScrollLock />}
          </SlideUp>
          {Sidebar && <Fade component={Sidebar} in={transitionIn} />}
        </div>
      </Fullscreen>
    )
  }
}

export type CarouselType = typeof Carousel
