import { Transition } from 'react-transition-group'
import { TransitionStatus } from 'react-transition-group/Transition'
import { Props } from './props'

export const Fade = ({
  component: Tag,
  onEntered,
  onExited,
  in: inProp,
  innerProps: originalProps,
  ...props
}: Props) => {
  const enter = 300
  const exit = 500
  const fadeStyle = {
    transitionProperty: 'all',
    transitionDuration: `${enter}ms`,
    opacity: 0,
  }
  const fadeTransition = {
    entering: { opacity: 0 },
    entered: { opacity: 1 },
    exiting: { opacity: 0, transitionDuration: `${exit}ms` },
  }

  return (
    <Transition
      appear
      mountOnEnter
      unmountOnExit
      onEntered={onEntered}
      onExited={onExited}
      key="fade"
      in={inProp}
      timeout={{ enter, exit }}
    >
      {(status: TransitionStatus) => {
        if (status === 'exited') return null

        const innerProps = {
          ...originalProps,
          style: {
            ...fadeStyle,
            ...fadeTransition[status],
          },
        }
        return <Tag {...innerProps} {...props} />
      }}
    </Transition>
  )
}
