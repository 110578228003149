import { Box, BoxProps } from 'theme-ui'

// Additional
export interface ListProps extends BoxProps {
  relaxed?: boolean
}

export const List = ({ sx, relaxed, children, ...rest }: ListProps) => (
  <Box
    sx={{
      '> div:first-of-type': { borderTop: 'none' },
      '> div': { py: relaxed ? 2 : 0 },
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

export const ListItem = ({ sx, children, ...rest }: BoxProps) => (
  <Box
    sx={{
      display: 'flex',
      borderTop: '1px solid',
      borderColor: 'rgba(34,36,38, 0.15)',
      alignItems: 'center',
      justifyContent: 'space-evenly',
      ...sx,
    }}
    {...rest}
  >
    {children}
  </Box>
)

export interface ListIconProps extends BoxProps {
  size?: 'small' | 'medium' | 'large'
}

export const ListIcon = ({ sx, size, children, ...rest }: ListIconProps) => {
  const width = size === 'small' ? 20 : size === 'large' ? 100 : 50
  const padding = 8
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexFlow: 'column',
        width: `${width}px`,
        height: `${width}px`,
        padding: `${padding}px`,
        'img, svg': {
          objectFit: 'contain',
          width: `${width - padding * 2}px`,
          height: `${width - padding * 2}px`,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Box>
  )
}

export const ListContent = ({ sx, children, ...rest }: BoxProps) => (
  <Box sx={{ flex: 1, ...sx }} {...rest}>
    {children}
  </Box>
)
