import { Formik, FormikHelpers, FormikProps } from 'formik'
import { useEffect, useRef } from 'react'
import { useAuth } from 'src/client/components/hooks/useAuth'
import { loginSchema } from 'src/client/components/primitives/validators'
import { Box, Button } from 'theme-ui'
import { Field } from '../../components/primitives'
import { shareKey } from '../media/internal/util'

const initialValues = { email: '', password: '', passwordConfirmation: '', name: '' }

type FormValues = typeof initialValues

export const Signup = ({ onSuccess }: { onSuccess?: () => void }) => {
  const { signUp } = useAuth()
  const ref = useRef<HTMLInputElement>(undefined!)
  const onSubmit = async (params: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
    try {
      await signUp({ ...params, share: shareKey() || '' })
      onSuccess?.()
    } catch (e) {
      const errors = e?.response?.data?.errors
      console.log(e)
      if (errors) setErrors(errors)
    }
  }

  useEffect(() => {
    ref.current?.focus()
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={loginSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting }: FormikProps<FormValues>) => (
        <Box sx={{ border: '1px solid', borderColor: 'muted', borderRadius: 4, p: 4 }}>
          <form onSubmit={handleSubmit} noValidate>
            <Field mb={3} ref={ref} name="name" placeholder="Name" />
            <Field mb={3} type="email" name="email" placeholder="Email" />
            <Field mb={3} type="password" name="password" placeholder="Password" />
            <Field
              mb={3}
              type="password"
              name="passwordConfirmation"
              placeholder="Password (Confirmation)"
            />
            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </form>
        </Box>
      )}
    </Formik>
  )
}
