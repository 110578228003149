import { Form, Formik, FormikHelpers, FormikProps } from 'formik'
import * as qs from 'query-string'
import { useEffect, useRef, useState } from 'react'
import { Redirect } from 'react-router-dom'
import * as api from 'src/client/api'
import { Field, resetPasswordSchema } from 'src/client/components/primitives'
import { authenticationUrl } from 'src/client/routes'
import { Box, Button, Flex, Text } from 'theme-ui'

const initialValues = { password: '' }

type FormValues = typeof initialValues

export const ResetPasswordComponent = ({ onSuccess }: { onSuccess?: () => void }) => {
  const [success, setSuccess] = useState(false)
  const { token } = qs.parse(location.search)
  const ref = useRef<HTMLInputElement>(undefined!)
  const onSubmit = async ({ password }: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
    try {
      await api.auth.resetPassword({ password, token: typeof token === 'string' ? token : '' })
      onSuccess?.()
      setSuccess(true)
    } catch (e) {
      setErrors({ password: e?.response?.data?.message })
    }
  }

  useEffect(() => {
    ref.current?.focus()
  }, [])

  if (success) return <Redirect to={{ pathname: authenticationUrl() }} />

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={resetPasswordSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }: FormikProps<FormValues>) => (
        <Box sx={{ border: '1px solid', borderColor: 'muted', borderRadius: 4, p: 4 }}>
          <Form noValidate>
            <Text mb={3}>Reset Password</Text>
            <Field mb={3} type="password" name="password" placeholder="Password" />
            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
          </Form>
        </Box>
      )}
    </Formik>
  )
}

export const ResetPassword = () => {
  return (
    <Flex
      css={{
        width: '100%',
        height: 'calc(100vh - 50px)',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ResetPasswordComponent />
    </Flex>
  )
}
