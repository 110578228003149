import { MenuItem } from '@szhsin/react-menu'
import React from 'react'
import { useToasts } from 'react-toast-notifications'
import {
  DotsVerticalRounded,
  Download,
  ExitFullscreen,
  Fullscreen,
  Heart,
  HeartSolid,
  Image,
  Images,
  InfoCircle,
  Message,
  Trash,
  X,
} from 'src/client/assets/images'
import { useAuth } from 'src/client/components/hooks'
import { Menu } from 'src/client/components/primitives'
import { useMediaContext } from 'src/client/scenes/media/MediaContext'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { SerializedMedia } from 'src/server/media/media.entity'
import { Box, Button } from 'theme-ui'

/* ARIA label for the fullscreen button */
const fullscreenLabel = (isFullscreen: boolean) =>
  isFullscreen ? 'Exit fullscreen (f)' : 'Enter fullscreen (f)'

interface State {
  interactionIsIdle: boolean
}

interface Props extends State {
  album: SerializedAlbum | null
  media: SerializedMedia
  allowFullscreen: boolean
  isFullscreen: boolean
  isLiked: boolean
  onClose?: (event: React.MouseEvent<HTMLElement>) => void
  onDownload?: () => void
  toggleFullscreen: () => void
  toggleSidebar: () => void
  toggleLike: () => void
  toggleActivity: () => void
}

export const Header = (props: Props) => {
  const {
    album,
    media,
    interactionIsIdle,
    allowFullscreen,
    isLiked,
    isFullscreen,
    onClose,
    onDownload,
    toggleActivity,
    toggleLike,
    toggleSidebar,
    toggleFullscreen,
  } = props
  const FsIcon = isFullscreen ? ExitFullscreen : Fullscreen
  const { authenticated, canAddToAlbum, canDelete, canEdit } = useAuth()
  const { updateAlbum, removeMedia, addToAlbumDialog } = useMediaContext()
  const toasts = useToasts()
  const onSetAlbumCover = async () => {
    try {
      if (album) await updateAlbum(album, { cover: media.id })
      toasts.addToast('Album cover set', { appearance: 'success' })
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        background: 'linear-gradient(rgba(0, 0, 0, 0.33), rgba(0, 0, 0, 0))',
        display: 'flex',
        flex: '0 0 auto',
        justifyContent: 'space-between',
        opacity: interactionIsIdle ? 0 : 1,
        padding: '10px',
        paddingBottom: '20px',
        position: 'absolute',
        transform: `translateY(${interactionIsIdle ? -10 : 0}px)`,
        transition: 'opacity 300ms, transform 300ms',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1,
      }}
      className="backdrop"
    >
      <Box>
        <Button variant="icon-light" onClick={onClose} title={'Close (esc)'}>
          <X width={'34px'} height={'34px'} />
        </Button>
      </Box>
      <Box>
        {allowFullscreen ? (
          <Button
            variant="icon-light"
            onClick={toggleFullscreen}
            title={fullscreenLabel(isFullscreen)}
          >
            <FsIcon />
          </Button>
        ) : null}
        {album && (
          <React.Fragment>
            {authenticated && (
              <Button variant="icon-light" onClick={toggleLike} title={'Like'}>
                {isLiked ? <HeartSolid /> : <Heart />}
              </Button>
            )}
            <Button variant="icon-light" onClick={toggleActivity} title={'Activity'}>
              <Message />
            </Button>
          </React.Fragment>
        )}
        <Button variant="icon-light" onClick={toggleSidebar} title={'Info'}>
          <InfoCircle />
        </Button>
        <Box style={{ display: 'inline-flex' }}>
          <Menu portal icon={<DotsVerticalRounded />}>
            <MenuItem onClick={onDownload}>
              <Box mr={2}>
                <Download sx={{ fill: 'secondary' }} />
              </Box>
              <Box>Download</Box>
              <Box>Shift + D</Box>
            </MenuItem>
            {canAddToAlbum(album) && (
              <MenuItem onClick={() => addToAlbumDialog(true, new Set([media.id]))}>
                <Box mr={2}>
                  <Image sx={{ fill: 'secondary' }} />
                </Box>
                <Box>Add to album</Box>
              </MenuItem>
            )}
            {album && canEdit(album) && (
              <MenuItem
                onClick={() => {
                  onSetAlbumCover()
                }}
              >
                <Box mr={2}>
                  <Images sx={{ fill: 'secondary' }} />
                </Box>
                <Box>Set as album cover</Box>
              </MenuItem>
            )}
            {album && canDelete(album) && (
              <MenuItem
                onClick={() => {
                  removeMedia(media.id, { fromAlbum: album })
                }}
              >
                <Box mr={2}>
                  <X sx={{ fill: 'secondary' }} />
                </Box>
                <Box>Remove from album</Box>
              </MenuItem>
            )}
            {canDelete(album) && (
              <MenuItem
                onClick={() => {
                  removeMedia(media.id)
                }}
              >
                <Box mr={2}>
                  <Trash sx={{ fill: 'secondary' }} />
                </Box>
                <Box>Trash</Box>
              </MenuItem>
            )}
          </Menu>
        </Box>
      </Box>
    </Box>
  )
}
