import * as qs from 'query-string'
import { isBlank } from 'src/shared/util'

export const staticMapUrl = (
  lat: number,
  lng: number,
  opts?: { width?: number; height?: number; zoom?: number }
) => {
  const { width = 320, height = 320, zoom = 15 } = opts || {}
  const params = {
    size: [width, height].join('x'),
    center: [lat, lng].join(','),
    zoom,
    markers: `color:red|${lat},${lng}`,
    key: process.env.GOOGLE_API_KEY,
  }
  return `https://maps.googleapis.com/maps/api/staticmap?${qs.stringify(params)}`
}

export const normalizeAddress = (result: google.maps.places.PlaceResult, id: string) => {
  if (!result?.address_components) {
    console.log('[Google Maps] Failed to return a valid response')
    return {}
  }
  const address = result.address_components.reduce((acc, val) => {
    acc[val.types[0]] = val.long_name
    return acc
  }, {} as any)
  const { lat, lng } = result?.geometry?.location || {}
  return {
    address1: [address.street_number, address.route].filter((s) => !isBlank(s)).join(' '),
    city: address.locality,
    zipcode: address.postal_code,
    state: address.administrative_area_level_1,
    country: address.country,
    latitude: lat ? lat() : '',
    longitude: lng ? lng() : '',
  }
}

export const geocode = (address: string) => {}

export const reverseGeocode = (lat: number, lng: number) => {}
