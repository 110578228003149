import React, {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { matchPath, useHistory } from 'react-router'
import * as api from 'src/client/api'
import { UpdateAlbumParams } from 'src/client/api/albums'
import { Uploader } from 'src/client/components/blocks'
import { albumUrl, indexUrl, mediaUrl, trashUrl } from 'src/client/routes'
import { AuthenticationDialog, ShareDialog } from 'src/client/scenes'
import {
  AlbumDialog,
  DeleteAlbumDialog,
} from 'src/client/scenes/albums/AlbumForm'
import {
  AddToAlbumDialog,
} from 'src/client/scenes/media/internal/AddToAlbumDialog'
import { matchesPaths } from 'src/client/util'
import { SerializedAlbum } from 'src/server/album/album.entity'
import { UpdateMediaDto } from 'src/server/media/dto/update-media.dto'
import { SerializedMedia } from 'src/server/media/media.entity'
import { MediaItem } from './internal/types'
import { prepareMedia, shareKey } from './internal/util'

export const MediaContext = createContext<MediaManager | null>(null)
MediaContext.displayName = 'MediaContext'

export const MediaConsumer = MediaContext.Consumer

// Note there's no createAlbum/deleteAlbum because those are handled in
// dialogs, the UI will just trigger their display
export interface MediaManager {
  /** Media for current context */
  media: MediaItem[]
  /** List of selected media */
  selected: Set<string>
  /** Loading Media */
  loading: boolean
  /** Whether uploading is allowed in the current context */
  canUpload: boolean
  /** Current Album */
  album: SerializedAlbum | null
  /** States of Media Related Dialogs */
  dialogs: Dialogs
  /** Replace current selection */
  setSelected: (selected: Set<string>) => void
  /** Replace media set for current context */
  setMedia: (media: SerializedMedia[]) => void
  /** Add media to the current set */
  addMedia: (media: SerializedMedia[]) => void
  /** Remove media from the current set */
  removeMedia: (
    ids: string[] | string,
    opts?: { fromAlbum: { id: string }; fromTrash?: never } | { fromAlbum?: never; fromTrash: true }
  ) => Promise<void>
  /** Update media in the current set - changing the date will reorder */
  updateMedia: (media: { id: string }, params: UpdateMediaDto) => Promise<SerializedMedia>
  /** Update album - only really used to set the album cover */
  updateAlbum: (album: { id: string }, params: UpdateAlbumParams) => Promise<SerializedAlbum>
  /** Replace album for current context */
  setAlbum: (album: SerializedAlbum | null) => void
  /** Toggle Loading Status */
  setLoading: (visible: boolean) => void
  /** Toggle auth Dialog */
  authDialog: (visible: boolean) => void
  /** Toggle File Dialog */
  fileDialog: (visible: boolean) => void
  /** Toggle AddToAlbum Dialog */
  addToAlbumDialog: (visible: boolean, ids?: Set<string>) => void
  /** Toggle Share Dialog */
  shareDialog: (visible: boolean) => void
  /** Toggle EditAlbum Dialog */
  editAlbumDialog: (visible: boolean) => void
  /** Toggle DeleteAlbum Dialog */
  deleteAlbumDialog: (visible: boolean) => void
}

type DialogType = 'addToAlbum' | 'shareAlbum' | 'editAlbum' | 'deleteAlbum' | 'file' | 'auth'

interface DialogState {
  open: boolean
  ids?: Set<string>
}
interface Dialogs {
  addToAlbum: DialogState
  shareAlbum: DialogState
  editAlbum: DialogState
  deleteAlbum: DialogState
  file: DialogState
  auth: DialogState
}
const dialogReducer = (
  state: Dialogs,
  { type, ...rest }: { type: DialogType; open: boolean; ids?: Set<string> }
) => {
  return { ...state, [type]: rest }
}

const convertMedia = (media: SerializedMedia | SerializedMedia[]) => {
  const key = shareKey()
  return sortMedia(prepareMedia(Array.isArray(media) ? media : [media], key ? { key } : undefined))
}

const sortMedia = (media: MediaItem[]) => {
  const ordered = media.sort((a, b) => b.date.getTime() - a.date.getTime())
  let index = 0 // Set the absolute index on each photo
  ordered.forEach((p) => (p.index = index++))
  return ordered
}

const canAddMedia = (media: SerializedMedia, currentAlbum: SerializedAlbum | null) => {
  if (matchPath(location.pathname, trashUrl.route)) return false
  return !currentAlbum || !!media.albums?.find((a) => a.id === currentAlbum.id)
}

const uploadablePath = () =>
  location.pathname === indexUrl || matchesPaths(location.pathname, mediaUrl.route, albumUrl.route)

export const MediaProvider = ({ children }: { children?: React.ReactNode }) => {
  const history = useHistory()
  const [selected, setSelected] = useState<Set<string>>(new Set())
  const [media, setMedia] = useState<MediaItem[]>([])
  const [album, setAlbum] = useState<SerializedAlbum | null>(null)
  const [loading, setLoading] = useState(false)
  const [canUpload, setCanUpload] = useState(uploadablePath())
  const [dialogs, dispatch] = useReducer(dialogReducer, {
    addToAlbum: { open: false },
    shareAlbum: { open: false },
    editAlbum: { open: false },
    deleteAlbum: { open: false },
    file: { open: false },
    auth: { open: false },
  })
  const authDialog = (open: boolean) => dispatch({ type: 'auth', open })
  const addToAlbumDialog = (open: boolean, ids?: Set<string>) =>
    dispatch({ type: 'addToAlbum', open, ids })
  const shareDialog = (open: boolean) => dispatch({ type: 'shareAlbum', open })
  const editAlbumDialog = (open: boolean) => dispatch({ type: 'editAlbum', open })
  const deleteAlbumDialog = (open: boolean) => dispatch({ type: 'deleteAlbum', open })
  const fileDialog = (open: boolean) => dispatch({ type: 'file', open })
  const replaceMedia = (newMedia: SerializedMedia[]) => setMedia(sortMedia(convertMedia(newMedia)))
  const addMedia: MediaManager['addMedia'] = (newMedia) =>
    setMedia((m) => sortMedia([...m, ...convertMedia(newMedia)]))
  const removeMediaById = (ids: string[]) => {
    const newSelected = new Set(selected)
    ids.forEach((id) => newSelected.delete(id))
    setSelected(newSelected)
    setMedia(sortMedia(media.filter((v) => !ids.includes(v.key))))
  }
  const removeMedia: MediaManager['removeMedia'] = async (ids, opts) => {
    ids = Array.isArray(ids) ? ids : [ids]
    try {
      if (opts?.fromAlbum) {
        await api.albums.removeMedia({ id: opts.fromAlbum.id }, { media: ids })
      } else if (opts?.fromTrash) {
        await api.media.restore({ mediaId: ids })
      } else {
        // TODO Probably should make this explicit in the calling component
        await (matchPath(history.location.pathname, trashUrl.route)
          ? api.media.delete
          : api.media.trash)({ mediaId: ids })
      }
      removeMediaById(ids)
    } catch (e) {
      console.log('something went wrong', e.message)
    }
  }
  const updateMedia: MediaManager['updateMedia'] = async (toUpdate, params) => {
    const updatedMedia = media.slice(0)
    const idx = updatedMedia.findIndex((m) => m.key === toUpdate.id)
    const { data } = await api.media.update({ id: toUpdate.id }, params)
    updatedMedia[idx] = prepareMedia([data])[0]
    setMedia(sortMedia(updatedMedia))
    return data
  }
  const updateAlbum: MediaManager['updateAlbum'] = async (toUpdate, params) => {
    const { data } = await api.albums.update({ id: toUpdate.id }, params)
    setAlbum(data)
    return data
  }
  const onMediaUploadComplete = (media: SerializedMedia, album: SerializedAlbum | null) => {
    if (media && canAddMedia(media, album)) addMedia([media])
  }

  useEffect(
    () =>
      history.listen((location) => {
        setCanUpload(uploadablePath())
      }),
    [history]
  )

  // Reset the file dialog state so it can be reopened
  useEffect(() => {
    if (dialogs.file.open) dispatch({ type: 'file', open: false })
  }, [dialogs.file.open])

  // console.log('context', album)

  return (
    <MediaContext.Provider
      value={{
        canUpload,
        dialogs,
        media,
        selected,
        album,
        loading,
        setMedia: replaceMedia,
        addMedia,
        removeMedia,
        updateMedia,
        setAlbum,
        updateAlbum,
        setSelected,
        setLoading,
        authDialog,
        fileDialog,
        addToAlbumDialog,
        shareDialog,
        editAlbumDialog,
        deleteAlbumDialog,
      }}
    >
      {children}
      <Uploader
        onComplete={onMediaUploadComplete}
        canUpload={canUpload}
        album={album}
        fileDialogRequested={dialogs.file.open}
      />
      <AuthenticationDialog
        isOpen={dialogs.auth.open}
        onClose={() => authDialog(false)}
        album={album}
      />
      <AddToAlbumDialog
        isOpen={dialogs.addToAlbum.open}
        mediaIds={dialogs.addToAlbum.ids ?? new Set()}
        onClose={() => addToAlbumDialog(false)}
        onSuccess={() => {
          setSelected(new Set())
          addToAlbumDialog(false)
        }}
      />
      <AlbumDialog
        isOpen={dialogs.editAlbum.open}
        album={album}
        onClose={() => editAlbumDialog(false)}
        onSuccess={setAlbum}
      />
      {album && (
        <DeleteAlbumDialog
          isOpen={dialogs.deleteAlbum.open}
          album={album}
          onClose={() => deleteAlbumDialog(false)}
          onSuccess={() => deleteAlbumDialog(false)}
        />
      )}
      <ShareDialog isOpen={dialogs.shareAlbum.open} onClose={() => shareDialog(false)} />
    </MediaContext.Provider>
  )
}

export const useMediaContext = () => {
  const context = useContext(MediaContext)
  if (!context) {
    throw new Error(
      'Could not find an MediaContext; You have to wrap useMediaContext() in a <MediaProvider>.'
    )
  }
  return context
}
