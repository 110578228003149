import { FastifyReply, FastifyRequest } from 'fastify'
import { ServerResponse } from 'http'
import { Http2ServerResponse } from 'http2'

export type HttpResponse = ServerResponse | Http2ServerResponse

export type Response = FastifyReply

// TODO FIX TYPE
export type Request = FastifyRequest & {
  user?: any
  session?: any
}

export type FastifySession = FastifyRequest['session'] & { user?: any }

// EXIF Orientation
export enum OrientationTypes {
  TOP_LEFT = 1, // data matches the image's intended display orientation.
  TOP_RIGHT = 2, // horizontal flip
  BOTTOM_RIGHT = 3, // 180 rotate left
  BOTTOM_LEFT = 4, // vertical flip
  LEFT_TOP = 5, // vertical flip + 90 rotate right
  RIGHT_TOP = 6, // 90 rotate right
  RIGHT_BOTTOM = 7, // horizontal flio + 90 rotate right
  LEFT_BOTTOM = 8, // 90 rotate left
}

// Extra info for encoding HashIds
export enum HashidEntityMap {
  Album = 1,
  Media = 2,
}

export interface GeographyPoint {
  type: 'Point'
  coordinates: [number, number]
}

export interface ExifGPS {
  GPSVersionID?: number[]
  GPSLatitudeRef?: string
  GPSLatitude?: number[]
  GPSLongitudeRef?: string
  GPSLongitude?: number[]
  GPSAltitudeRef?: number
  GPSAltitude?: number
  GPSTimeStamp?: number[]
  GPSDOP?: number
  GPSImgDirectionRef?: string
  GPSImgDirection?: number
  GPSProcessingMethod?: Buffer
  GPSDateStamp?: string
}

export interface MediaExif {
  Aperture: number
  BrightnessValue: number
  CreateDate: string
  DateTimeOriginal: string
  DeviceAttributes: string
  DeviceManufacturer: string
  DeviceModel: string
  ExposureMode: string
  ExposureProgram: string
  ExposureTime: string
  Flash: string
  FNumber: string
  FocalLength: string
  GPSAltitude: number
  GPSAltitudeRef: number
  GPSDateStamp: string
  GPSDateTime: string
  GPSImgDirection: number
  GPSImgDirectionRef: string
  GPSLatitude: number
  GPSLatitudeRef: string
  GPSLongitude: number
  GPSLongitudeRef: string
  GPSProcessingMethod: string
  GPSTimeStamp: string
  GPSVersionID: string
  ISO: number
  Make: string
  Model: string
  ShutterSpeed: string
  Megapixels: number
  ModifyDate: string
  Orientation: number
}
