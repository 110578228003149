// Given a file return a dataURL for a preview image
export const generatePreview = async (
  file?: File,
  maxWidth = 200
): Promise<string | ArrayBuffer | null> => {
  if (!file) return ''

  const fileReader = new FileReader()

  return await new Promise((resolve, reject) => {
    if (/image/.exec(file.type)) {
      fileReader.onload = () => resolve(fileReader.result)
      fileReader.readAsDataURL(file)
    } else {
      fileReader.onload = () => {
        const blob = new Blob([fileReader.result!], { type: file.type })
        const url = URL.createObjectURL(blob)
        const video = document.createElement('video')
        let playPromise: Promise<any> | null
        video.addEventListener('loadeddata', () => {
          const result = snapImage(video, url, maxWidth)
          playPromise
            ? playPromise.then(() => video.pause()).catch(() => video.pause())
            : video.pause()
          result ? resolve(result) : reject(new Error('Unable to generate video preview'))
          video.remove()
        })
        Object.assign(video, {
          preload: 'metadata',
          src: url,
          muted: true,
          playsInline: true,
        })
        // Otherwise the following error is immeadiately triggered on Linux machines in Chrome
        // Uncaught (in promise) DOMException: The play() request was interrupted by a call to pause(). https://goo.gl/LdLk22`
        // https://github.com/sampotts/plyr/issues/331
        setTimeout(() => {
          playPromise = video.play()
        }, 0)
      }
      fileReader.readAsArrayBuffer(file)
    }
  })
}

const snapImage = (video: HTMLVideoElement, url: string, targetWidth: number) => {
  const canvas = document.createElement('canvas')
  const ar = video.videoWidth / video.videoHeight
  canvas.width = targetWidth
  canvas.height = targetWidth / ar
  canvas.getContext('2d')!.drawImage(video, 0, 0, canvas.width, canvas.height)
  const image = canvas.toDataURL()
  URL.revokeObjectURL(url)
  if (image.length > 1000) return image
}

const scale = (video: HTMLVideoElement, maxWidth: number, maxHeight: number) => {
  const scaled = {
    ratio: video.videoWidth / video.videoHeight,
    width: video.videoWidth,
    height: video.videoHeight,
  }
  if (scaled.width > maxWidth) {
    scaled.width = maxWidth
    scaled.height = scaled.width / scaled.ratio
  }
  if (scaled.height > maxHeight) {
    scaled.height = maxHeight
    scaled.width = scaled.height / scaled.ratio
  }
  return scaled
}
