import { ChevronLeft, ChevronRight } from 'src/client/assets/images'
import { Box, Button } from 'theme-ui'

interface LabelProps {
  currentIndex: number
  views: any[]
}

/* ARIA label for the next button */
const nextLabel = ({ currentIndex, views }: LabelProps) =>
  `Show slide ${currentIndex + 2} of ${views.length}`

/* ARIA label for the previous button */
const prevLabel = ({ currentIndex, views }: LabelProps) =>
  `Show slide ${currentIndex} of ${views.length}`

interface Props {
  interactionIsIdle: boolean
}

interface NavProps extends Props {
  next: () => void
  prev: () => void
  showPrev?: boolean
  showNext?: boolean
  currentIndex: number
  views: any[]
}

export const Navigation = ({
  next,
  prev,
  interactionIsIdle,
  showPrev,
  showNext,
  currentIndex,
  views,
}: NavProps) => {
  const labelProps = { currentIndex, views }

  if (showPrev === false && showNext === false) return null

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        opacity: interactionIsIdle ? 0 : 1,
        transition: 'opacity 300ms',
      }}
    >
      {showPrev !== false && (
        <Button
          variant="carousel"
          sx={{ left: '20px' }}
          aria-label={prevLabel(labelProps)}
          onClick={prev}
          title={'Previous (left arrow)'}
        >
          <ChevronLeft size={40} />
        </Button>
      )}
      {showNext !== false && (
        <Button
          variant="carousel"
          sx={{ right: '20px' }}
          aria-label={nextLabel(labelProps)}
          onClick={next}
          title={'Next (right arrow)'}
        >
          <ChevronRight size={40} />
        </Button>
      )}
    </Box>
  )
}
